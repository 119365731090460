import { Router } from '@angular/router';
import { ModalService } from 'appy-gas-core';
var ExtendPremiumComponent = /** @class */ /*@__PURE__*/ (function () {
    function ExtendPremiumComponent(modalService, router) {
        this.modalService = modalService;
        this.router = router;
    }
    ExtendPremiumComponent.prototype.closeModal = function () {
        this.modalService.hide();
    };
    ExtendPremiumComponent.prototype.goToContact = function () {
        this.closeModal();
        this.router.navigate(['/static/contact-us/extend-premium']);
    };
    return ExtendPremiumComponent;
}());
export { ExtendPremiumComponent };
