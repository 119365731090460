import friends from 'appy-gas-core/dist/assets/svg/icons/friends.svg';
import { APP_LINKS } from '../../config';
import { CATEGORIES } from './categories';
var FooterComponent = /** @class */ /*@__PURE__*/ (function () {
    function FooterComponent() {
        this.currentYear = new Date().getFullYear();
        this.categories = CATEGORIES;
        this.links = APP_LINKS;
        this.friendsIcon = friends;
    }
    return FooterComponent;
}());
export { FooterComponent };
