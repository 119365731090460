import { __decorate, __metadata } from "tslib";
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorTypeListEnum } from 'appy-gas-core';
import { UserPointTypesEnum } from '../../shared/enums';
import { GetAllEventPoints, GetAllEventPointsFailed, GetAllEventPointsSuccess, GetAllMaintenancePoints, GetAvailabilityData, GetEventDetails, GetEventDetailsLoading, GetImpactChartData, GetImpactChartDataLoading, GetOtherEvents, GetOtherEventsLoading, GetPortfolioMaintenancePoints, GetPortfolioMaintenancePointsFailed, GetPortfolioMaintenancePointsSuccess, GetRelatedPoints, GetRelatedPointsLoading, GetSelectedNorwayEvents, GetSelectedPEGSuperPointsEvents, GetSelectedPointEvents, GetSelectedPointEventsFailed, GetSelectedPointEventsSuccess, GetSelectedRouteDetails, GetSelectedRouteDetailsFailed, GetSelectedRouteDetailsSuccess } from './availability.actions';
import { AvailabilitySelectors } from './availability.selectors';
var AvailabilityFacade = /** @class */ /*@__PURE__*/ (function () {
    function AvailabilityFacade(availabilityService, toastr, store) {
        this.availabilityService = availabilityService;
        this.toastr = toastr;
        this.store = store;
    }
    AvailabilityFacade.prototype.getAvailabilityData = function (range) {
        var _this = this;
        return this.availabilityService.loadAvailabilityData(range).pipe(catchError(function (err) {
            _this.toastr.error(err.status, err.statusText);
            return throwError(err);
        }), map(function (data) { return new GetAvailabilityData(data); }));
    };
    AvailabilityFacade.prototype.getMaintenancePoints = function (range) {
        var _this = this;
        return this.availabilityService.loadMaintenancePoints(range).pipe(catchError(function (err) {
            _this.toastr.error(err.status, err.statusText);
            return throwError(err);
        }), map(function (data) { return new GetAllMaintenancePoints(data); }));
    };
    AvailabilityFacade.prototype.getMaintenanceProPoints = function (range) {
        var _this = this;
        return this.availabilityService.loadMaintenanceProPoints(range).pipe(catchError(function (err) {
            if (err.status === ErrorTypeListEnum.FORBIDDEN) {
                return;
            }
            _this.toastr.error(err.status, err.statusText);
            return throwError(err);
        }), map(function (data) { return new GetAllMaintenancePoints(data); }));
    };
    AvailabilityFacade.prototype.getAllEventPoints = function (range) {
        var _this = this;
        this.store.dispatch(new GetAllEventPoints());
        return this.availabilityService.loadMaintenanceProAllPoints(range).pipe(catchError(function (err) {
            _this.store.dispatch(new GetAllEventPointsFailed());
            _this.toastr.error(err.status, err.statusText);
            return throwError(err);
        }), map(function (data) { return new GetAllEventPointsSuccess(data); }));
    };
    AvailabilityFacade.prototype.getMaintenancePortfolioPoints = function (range) {
        var _this = this;
        this.store.dispatch(new GetPortfolioMaintenancePoints());
        return this.availabilityService.loadMaintenancePortfolioPoints(range).pipe(catchError(function (err) {
            _this.store.dispatch(new GetPortfolioMaintenancePointsFailed());
            return throwError(err);
        }), map(function (data) { return new GetPortfolioMaintenancePointsSuccess(data); }));
    };
    AvailabilityFacade.prototype.getSelectedPointEvents = function (appyGasId, vipPointMarketAreasIds) {
        var _this = this;
        var dateRange = this.store.selectSnapshot(AvailabilitySelectors.gasDayRange);
        var pointType = this.store.selectSnapshot(AvailabilitySelectors.selectedPointType);
        this.store.dispatch(new GetSelectedPointEvents());
        if (pointType === UserPointTypesEnum.MY_PORTFOLIO) {
            return this.availabilityService.loadMaintenancePointEventsPortfolio(appyGasId, dateRange).pipe(catchError(function (err) {
                _this.store.dispatch(new GetSelectedPointEventsFailed());
                _this.toastr.error(err.status, err.statusText);
                return throwError(err);
            }), map(function (pointEvents) {
                return new GetSelectedPointEventsSuccess(pointEvents, vipPointMarketAreasIds);
            }));
        }
        else {
            return this.availabilityService.loadMaintenancePointEvents(appyGasId, dateRange).pipe(catchError(function (err) {
                if (err.status === ErrorTypeListEnum.FORBIDDEN) {
                    return;
                }
                _this.store.dispatch(new GetSelectedPointEventsFailed());
                _this.toastr.error(err.status, err.statusText);
                return throwError(err);
            }), map(function (pointEvents) {
                return new GetSelectedPointEventsSuccess(pointEvents, vipPointMarketAreasIds);
            }));
        }
    };
    AvailabilityFacade.prototype.getSelectedNorwayEvents = function (appyGasIds, vipPointMarketAreasIds) {
        var _this = this;
        var dateRange = this.store.selectSnapshot(AvailabilitySelectors.gasDayRange);
        return this.availabilityService.loadMaintenanceRouteEvents(appyGasIds, dateRange).pipe(catchError(function (err) {
            _this.toastr.error(err.status, err.statusText);
            return throwError(err);
        }), map(function (pointEvents) { return new GetSelectedNorwayEvents(pointEvents, vipPointMarketAreasIds); }));
    };
    AvailabilityFacade.prototype.getSelectedPEGSuperPointsEvents = function (appyGasIds, vipPointMarketAreasIds) {
        var _this = this;
        var dateRange = this.store.selectSnapshot(AvailabilitySelectors.gasDayRange);
        return this.availabilityService.loadMaintenanceRouteEvents(appyGasIds, dateRange).pipe(catchError(function (err) {
            _this.toastr.error(err.status, err.statusText);
            return throwError(err);
        }), map(function (pointEvents) {
            return new GetSelectedPEGSuperPointsEvents(pointEvents, vipPointMarketAreasIds);
        }));
    };
    AvailabilityFacade.prototype.getSelectedRouteDetails = function (appyGasIds) {
        var _this = this;
        var dateRange = this.store.selectSnapshot(AvailabilitySelectors.gasDayRange);
        this.store.dispatch(new GetSelectedRouteDetails());
        return this.availabilityService.loadMaintenanceRouteEvents(appyGasIds, dateRange).pipe(catchError(function (err) {
            _this.store.dispatch(new GetSelectedRouteDetailsFailed());
            _this.toastr.error(err.status, err.statusText);
            return throwError(err);
        }), map(function (pointEvents) { return new GetSelectedRouteDetailsSuccess(pointEvents); }));
    };
    AvailabilityFacade.prototype.getRelatedPoints = function (pointId, virtualPointId, range, dataSourceId) {
        var _this = this;
        this.store.dispatch(new GetRelatedPointsLoading(pointId));
        return this.availabilityService.getRelatedPoints(pointId, virtualPointId, range, dataSourceId).pipe(catchError(function (err) {
            if (err.status === ErrorTypeListEnum.FORBIDDEN) {
                return;
            }
            _this.toastr.error(err.status, err.statusText);
            return throwError(err);
        }), map(function (relatedPoints) { return new GetRelatedPoints(relatedPoints, pointId); }));
    };
    AvailabilityFacade.prototype.getEventDetails = function (pointId, virtualPointId, range, capacityType, direction, dataSourceId) {
        var _this = this;
        this.store.dispatch(new GetEventDetailsLoading(pointId));
        return this.availabilityService
            .getEventDetails(pointId, virtualPointId, range, capacityType, direction, dataSourceId)
            .pipe(catchError(function (err) {
            if (err.status === ErrorTypeListEnum.FORBIDDEN) {
                return;
            }
            _this.toastr.error(err.status, err.statusText);
            return throwError(err);
        }), map(function (eventDetails) { return new GetEventDetails(eventDetails, pointId); }));
    };
    AvailabilityFacade.prototype.getOtherEvents = function (pointId, virtualPointId, range, capacityType, direction, dataSourceId) {
        var _this = this;
        this.store.dispatch(new GetOtherEventsLoading(pointId));
        return this.availabilityService
            .getOtherEvents(pointId, virtualPointId, range, capacityType, direction, dataSourceId)
            .pipe(catchError(function (err) {
            if (err.status === ErrorTypeListEnum.FORBIDDEN) {
                return;
            }
            _this.toastr.error(err.status, err.statusText);
            return throwError(err);
        }), map(function (otherEvents) { return new GetOtherEvents(otherEvents, pointId); }));
    };
    AvailabilityFacade.prototype.getImpactChartData = function (pointId, virtualPointId, range, capacityType, direction, dataSourceId) {
        var _this = this;
        this.store.dispatch(new GetImpactChartDataLoading(pointId));
        return this.availabilityService
            .getImpactChartData(pointId, virtualPointId, range, capacityType, direction, dataSourceId)
            .pipe(catchError(function (err) {
            if (err.status === ErrorTypeListEnum.FORBIDDEN) {
                return;
            }
            _this.toastr.error(err.status, err.statusText);
            return throwError(err);
        }), map(function (impactChartData) { return new GetImpactChartData(impactChartData, pointId); }));
    };
    __decorate([
        Select(AvailabilitySelectors.allMaintenancePoints),
        __metadata("design:type", Observable)
    ], AvailabilityFacade.prototype, "maintenancePoints$", void 0);
    __decorate([
        Select(AvailabilitySelectors.allEventPoints),
        __metadata("design:type", Observable)
    ], AvailabilityFacade.prototype, "allEventPoints$", void 0);
    __decorate([
        Select(AvailabilitySelectors.filteredMaintenancePoints),
        __metadata("design:type", Observable)
    ], AvailabilityFacade.prototype, "filteredMaintenancePoints$", void 0);
    __decorate([
        Select(AvailabilitySelectors.relatedPointsLoading),
        __metadata("design:type", Observable)
    ], AvailabilityFacade.prototype, "relatedPointsLoading$", void 0);
    __decorate([
        Select(AvailabilitySelectors.relatedPoints),
        __metadata("design:type", Observable)
    ], AvailabilityFacade.prototype, "relatedPoints$", void 0);
    __decorate([
        Select(AvailabilitySelectors.relatedPointsInfo),
        __metadata("design:type", Observable)
    ], AvailabilityFacade.prototype, "relatedPointsInfo$", void 0);
    __decorate([
        Select(AvailabilitySelectors.impactChartDataLoading),
        __metadata("design:type", Observable)
    ], AvailabilityFacade.prototype, "impactChartDataLoading$", void 0);
    __decorate([
        Select(AvailabilitySelectors.impactChartData),
        __metadata("design:type", Observable)
    ], AvailabilityFacade.prototype, "impactChartData$", void 0);
    __decorate([
        Select(AvailabilitySelectors.eventDetailsLoading),
        __metadata("design:type", Observable)
    ], AvailabilityFacade.prototype, "eventDetailsLoading$", void 0);
    __decorate([
        Select(AvailabilitySelectors.eventDetails),
        __metadata("design:type", Observable)
    ], AvailabilityFacade.prototype, "eventDetails$", void 0);
    __decorate([
        Select(AvailabilitySelectors.eventDetailsUI),
        __metadata("design:type", Observable)
    ], AvailabilityFacade.prototype, "eventDetailsUI$", void 0);
    __decorate([
        Select(AvailabilitySelectors.pointInfo),
        __metadata("design:type", Observable)
    ], AvailabilityFacade.prototype, "pointInfo$", void 0);
    __decorate([
        Select(AvailabilitySelectors.otherEventsLoading),
        __metadata("design:type", Observable)
    ], AvailabilityFacade.prototype, "otherEventsLoading$", void 0);
    __decorate([
        Select(AvailabilitySelectors.otherEventsUI),
        __metadata("design:type", Observable)
    ], AvailabilityFacade.prototype, "otherEventsUI$", void 0);
    __decorate([
        Select(AvailabilitySelectors.isNorwayPointSelectedStatus),
        __metadata("design:type", Observable)
    ], AvailabilityFacade.prototype, "isNorwayPointSelected$", void 0);
    __decorate([
        Select(AvailabilitySelectors.isMergedGermanyMAState),
        __metadata("design:type", Observable)
    ], AvailabilityFacade.prototype, "isMergedGermanyMAState$", void 0);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Observable)
    ], AvailabilityFacade.prototype, "getAvailabilityData", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Observable)
    ], AvailabilityFacade.prototype, "getMaintenancePoints", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Observable)
    ], AvailabilityFacade.prototype, "getMaintenanceProPoints", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Observable)
    ], AvailabilityFacade.prototype, "getAllEventPoints", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Observable)
    ], AvailabilityFacade.prototype, "getMaintenancePortfolioPoints", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, Array]),
        __metadata("design:returntype", Observable)
    ], AvailabilityFacade.prototype, "getSelectedPointEvents", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array, Array]),
        __metadata("design:returntype", Observable)
    ], AvailabilityFacade.prototype, "getSelectedNorwayEvents", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array, Array]),
        __metadata("design:returntype", Observable)
    ], AvailabilityFacade.prototype, "getSelectedPEGSuperPointsEvents", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", Observable)
    ], AvailabilityFacade.prototype, "getSelectedRouteDetails", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, String, Object, Number]),
        __metadata("design:returntype", Observable)
    ], AvailabilityFacade.prototype, "getRelatedPoints", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, String, Object, String, String, Number]),
        __metadata("design:returntype", Observable)
    ], AvailabilityFacade.prototype, "getEventDetails", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, String, Object, String, String, Number]),
        __metadata("design:returntype", Observable)
    ], AvailabilityFacade.prototype, "getOtherEvents", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, String, Object, String, String, Number]),
        __metadata("design:returntype", Observable)
    ], AvailabilityFacade.prototype, "getImpactChartData", null);
    return AvailabilityFacade;
}());
export { AvailabilityFacade };
