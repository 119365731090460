import { __decorate, __metadata } from "tslib";
import { Action, State } from '@ngxs/store';
import { SetUserInfo, SetUserRole } from './user.actions';
var UserState = /** @class */ /*@__PURE__*/ (function () {
    function UserState() {
    }
    UserState.prototype.setUserInfo = function (_a, _b) {
        var patchState = _a.patchState;
        var user = _b.user;
        patchState({ user: user });
    };
    UserState.prototype.setUserRole = function (_a, _b) {
        var patchState = _a.patchState;
        var role = _b.role;
        patchState({ role: role });
    };
    __decorate([
        Action(SetUserInfo),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetUserInfo]),
        __metadata("design:returntype", void 0)
    ], UserState.prototype, "setUserInfo", null);
    __decorate([
        Action(SetUserRole),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetUserRole]),
        __metadata("design:returntype", void 0)
    ], UserState.prototype, "setUserRole", null);
    UserState = __decorate([
        State({
            name: 'userInfo',
            defaults: {
                user: undefined,
                role: undefined
            }
        })
    ], UserState);
    return UserState;
}());
export { UserState };
