import { APP_BASE_HREF } from '@angular/common';
import { Routes } from '@angular/router';
import { AuthenticationGuard, TechnicalMaintenanceComponent } from './core';
import { PageNotFoundComponent, Role } from 'appy-gas-core';
var ɵ0 = {
    permissions: {
        only: Role.All,
        except: [Role.Expired]
    },
    title: 'Flows Map'
}, ɵ1 = {
    permissions: {
        except: [Role.Expired]
    },
    title: 'Data Export'
}, ɵ2 = {
    permissions: {
        except: [Role.Guest, Role.Expired]
    },
    title: 'Dashboard'
}, ɵ3 = {
    permissions: {
        except: [Role.Guest, Role.Expired]
    },
    title: 'Route Calculator'
}, ɵ4 = {
    permissions: {
        except: [Role.Guest, Role.Expired]
    },
    title: 'Market Area Details'
}, ɵ5 = {
    permissions: {
        except: [Role.Guest, Role.Expired]
    },
    title: 'Availability Pro'
}, ɵ6 = {
    permissions: {
        except: Role.Guest
    },
    title: 'Profile'
};
var routes = [
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
    },
    {
        path: 'technical-maintenance',
        component: TechnicalMaintenanceComponent
    },
    {
        path: 'auth',
        loadChildren: './auth/auth.module#AuthModule'
    },
    {
        path: 'flows-map',
        loadChildren: './flows-map/flows-map.module#FlowsMapModule',
        canActivate: [AuthenticationGuard],
        data: ɵ0
    },
    {
        path: 'data-export',
        loadChildren: './data-export/data-export.module#DataExportModule',
        canActivate: [AuthenticationGuard],
        data: ɵ1
    },
    {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthenticationGuard],
        data: ɵ2
    },
    {
        path: 'route-calculator',
        loadChildren: './route-calculator/route-calculator.module#RouteCalculatorModule',
        canActivate: [AuthenticationGuard],
        data: ɵ3
    },
    {
        path: 'market-area-view',
        loadChildren: './market-area-view/market-area-view.module#MarketAreaViewModule',
        canActivate: [AuthenticationGuard],
        data: ɵ4
    },
    {
        path: 'availability-pro',
        loadChildren: './availability-pro/availability-pro.module#AvailabilityProModule',
        canActivate: [AuthenticationGuard],
        data: ɵ5
    },
    {
        path: 'profile',
        loadChildren: './profile/profile.module#ProfileModule',
        canActivate: [AuthenticationGuard],
        data: ɵ6
    },
    {
        path: 'static',
        loadChildren: './static-pages/static-pages.module#StaticPagesModule'
    },
    // Fallback when no prior route is matched
    {
        path: '**',
        pathMatch: 'full',
        component: PageNotFoundComponent
    }
];
export var routesProviders = [
    {
        provide: APP_BASE_HREF,
        useValue: '/'
    }
];
var AppRoutingModule = /** @class */ /*@__PURE__*/ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
