import { EnvironmentVariables } from 'appy-gas-core';

export const stagingVariables: EnvironmentVariables = {
  environment: 'staging',
  production: true,
  serverUrl: 'https://ag-stage-wap-api.azurewebsites.net/api',
  gaTrackingId: 'UA-114836633-2',
  userManagementReleaseDate: new Date(2018, 5, 5),
  technicalMaintenance: false
};
