import { __assign, __decorate, __metadata } from "tslib";
import { Action, State } from '@ngxs/store';
import { GetMarketArea, GetPointDetails, ResetPointDetails, SetMarketAreaDate, SetMarketAreaViewGeneralInfo } from './market-area-view.actions';
var MarketAreaViewState = /** @class */ /*@__PURE__*/ (function () {
    function MarketAreaViewState() {
    }
    MarketAreaViewState.prototype.setMarketAreaViewGeneralInfo = function (_a, _b) {
        var _c;
        var patchState = _a.patchState;
        var marketAreaViewGeneralInfo = _b.marketAreaViewGeneralInfo, marketAreaPosition = _b.marketAreaPosition;
        patchState((_c = {}, _c[marketAreaPosition] = marketAreaViewGeneralInfo, _c));
    };
    MarketAreaViewState.prototype.setMarketAreaDate = function (_a, _b) {
        var _c;
        var patchState = _a.patchState, getState = _a.getState;
        var date = _b.date, marketAreaPosition = _b.marketAreaPosition;
        var state = getState();
        patchState((_c = {}, _c[marketAreaPosition] = __assign(__assign({}, state[marketAreaPosition]), { date: date }), _c));
    };
    MarketAreaViewState.prototype.getPointDetails = function (_a, _b) {
        var _c;
        var patchState = _a.patchState, getState = _a.getState;
        var virtualPointData = _b.virtualPointData, marketAreaPosition = _b.marketAreaPosition;
        var state = getState();
        patchState((_c = {}, _c[marketAreaPosition] = __assign(__assign({}, state[marketAreaPosition]), { vip: virtualPointData }), _c));
    };
    MarketAreaViewState.prototype.resetPointDetails = function (_a, _b) {
        var _c;
        var patchState = _a.patchState, getState = _a.getState;
        var marketAreaPosition = _b.marketAreaPosition;
        var state = getState();
        patchState((_c = {}, _c[marketAreaPosition] = __assign(__assign({}, state[marketAreaPosition]), { vip: undefined }), _c));
    };
    MarketAreaViewState.prototype.getMarketArea = function (_a, _b) {
        var _c;
        var patchState = _a.patchState, getState = _a.getState;
        var marketAreaConfig = _b.marketAreaConfig, marketAreaPosition = _b.marketAreaPosition;
        var state = getState();
        patchState((_c = {},
            _c[marketAreaPosition] = __assign(__assign({}, state[marketAreaPosition]), { marketArea: marketAreaConfig.marketArea, date: marketAreaConfig.date }),
            _c));
    };
    __decorate([
        Action(SetMarketAreaViewGeneralInfo),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetMarketAreaViewGeneralInfo]),
        __metadata("design:returntype", void 0)
    ], MarketAreaViewState.prototype, "setMarketAreaViewGeneralInfo", null);
    __decorate([
        Action(SetMarketAreaDate),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetMarketAreaDate]),
        __metadata("design:returntype", void 0)
    ], MarketAreaViewState.prototype, "setMarketAreaDate", null);
    __decorate([
        Action(GetPointDetails),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetPointDetails]),
        __metadata("design:returntype", void 0)
    ], MarketAreaViewState.prototype, "getPointDetails", null);
    __decorate([
        Action(ResetPointDetails),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, ResetPointDetails]),
        __metadata("design:returntype", void 0)
    ], MarketAreaViewState.prototype, "resetPointDetails", null);
    __decorate([
        Action(GetMarketArea),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetMarketArea]),
        __metadata("design:returntype", void 0)
    ], MarketAreaViewState.prototype, "getMarketArea", null);
    MarketAreaViewState = __decorate([
        State({
            name: 'marketAreaView',
            defaults: {
                leftMarketAreaView: undefined,
                rightMarketAreaView: undefined
            }
        })
    ], MarketAreaViewState);
    return MarketAreaViewState;
}());
export { MarketAreaViewState };
