import { UserDeactivatedComponent } from './user-deactivated.component';

export const userDeactivatedModalConfig = {
  title: 'The account with the specified email address was deactivated',
  component: {
    name: UserDeactivatedComponent,
    options: {
      userId: null
    }
  },
  data: {
    width: 760
  }
};
