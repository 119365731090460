import { ModalConfig } from 'appy-gas-core';

import { SignupComponent } from './signup.component';

const signUpModalConfig: ModalConfig = {
  title: 'Sign Up',
  component: {
    name: SignupComponent,
    options: {
      isPage: false
    }
  },
  data: {
    width: 670
  }
};

export default signUpModalConfig;
