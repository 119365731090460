/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "./browser-popover.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./browser-popover.component";
import * as i4 from "../cookies-popover.service";
var styles_BrowserPopoverComponent = [i0.styles];
var RenderType_BrowserPopoverComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_BrowserPopoverComponent, data: {} });
export { RenderType_BrowserPopoverComponent as RenderType_BrowserPopoverComponent };
function View_BrowserPopoverComponent_1(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "browser-popover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["To get the most of appygas, please switch to google chrome browser"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [["class", "close-icon"]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.closeWording() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), (_l()(), i1.ɵted(-1, null, ["Don't show this again"]))], null, null);
}
export function View_BrowserPopoverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BrowserPopoverComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BrowserPopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "appy-gas-browser-popover", [], null, null, null, View_BrowserPopoverComponent_0, RenderType_BrowserPopoverComponent)), i1.ɵdid(1, 114688, null, 0, i3.BrowserPopoverComponent, [i4.CookiesPopoverService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BrowserPopoverComponentNgFactory = /*@__PURE__*/ i1.ɵccf("appy-gas-browser-popover", i3.BrowserPopoverComponent, View_BrowserPopoverComponent_Host_0, {}, {}, []);
export { BrowserPopoverComponentNgFactory as BrowserPopoverComponentNgFactory };
