import { __decorate, __metadata } from "tslib";
import { Dispatch } from "@ngxs-labs/dispatch-decorator";
import { Select } from "@ngxs/store";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ErrorTypeListEnum } from "appy-gas-core";
import { DeleteFullSpreadFilter, GetAllFilterSpreads, GetAllFilterSpreadsFailed, GetAllFilterSpreadsLoaded, GetAllFilterSpreadsLoading, GetBlockPositions, GetFullSpreadFilter, GetFullSpreadFilterFailed, GetFullSpreadFilterLoaded, GetFullSpreadFilterLoading, GetHubPrices, GetHubPricesFailed, GetHubPricesLoaded, GetHubPricesLoading, GetLastAuctionStats, GetLastAuctionStatsFailed, GetLastAuctionStatsLoaded, GetLastAuctionStatsLoading, GetLngCountries, GetLngCountriesFailed, GetLngCountriesLoaded, GetLngCountriesLoading, GetMaintenancePlan, GetMaintenancePlanFailed, GetMaintenancePlanLoaded, GetMaintenancePlanLoading, GetMaintenanceUpdate, GetMaintenanceUpdateFailed, GetMaintenanceUpdateSuccess, GetPipelineCountries, GetPipelineCountriesFailed, GetPipelineCountriesLoaded, GetPipelineCountriesLoading, GetPipelineCountriesVsLngSupply, GetPipelineCountriesVsLngSupplyFailed, GetPipelineCountriesVsLngSupplyLoaded, GetPipelineCountriesVsLngSupplyLoading, GetPreviewSpreads, GetPreviewSpreadsFailed, GetPreviewSpreadsLoaded, GetPreviewSpreadsLoading, GetSpreadFilters, GetSpreadFiltersFailed, GetSpreadFiltersLoaded, GetSpreadFiltersLoading, GetSpreadMarketAreas, GetSpreadMarketAreasFailed, GetSpreadMarketAreasLoaded, GetSpreadMarketAreasLoading, GetSpreads, GetSpreadsFailed, GetSpreadsLoaded, GetSpreadsLoading, } from "./dashboard.actions";
import { DashboardSelectors } from "./dashboard.selectors";
var DashboardFacade = /** @class */ /*@__PURE__*/ (function () {
    function DashboardFacade(dashboardService, toastr, store) {
        this.dashboardService = dashboardService;
        this.toastr = toastr;
        this.store = store;
    }
    DashboardFacade.prototype.getHubPrices = function (date, period) {
        var _this = this;
        this.store.dispatch(new GetHubPricesLoading());
        return this.dashboardService.loadHubPrices(date, period).pipe(catchError(function (err) {
            if (err.status === ErrorTypeListEnum.FORBIDDEN) {
                return;
            }
            _this.store.dispatch(new GetHubPricesFailed());
            _this.toastr.error(err.status, "Error while loading hub prices: " + err.statusText);
            return throwError(err);
        }), map(function (data) {
            _this.store.dispatch(new GetHubPricesLoaded());
            return new GetHubPrices(data);
        }));
    };
    DashboardFacade.prototype.getLngCountries = function (period) {
        var _this = this;
        this.store.dispatch(new GetLngCountriesLoading());
        return this.dashboardService.loadLngCountries(period).pipe(catchError(function (err) {
            _this.handleError(err);
            _this.store.dispatch(new GetLngCountriesFailed());
            return throwError(err);
        }), map(function (data) {
            _this.store.dispatch(new GetLngCountriesLoaded());
            return new GetLngCountries(data);
        }));
    };
    DashboardFacade.prototype.getMaintenancePlan = function () {
        var _this = this;
        this.store.dispatch(new GetMaintenancePlanLoading());
        return this.dashboardService.loadMaintenancePlans().pipe(catchError(function (err) {
            _this.handleError(err);
            _this.store.dispatch(new GetMaintenancePlanFailed());
            return throwError(err);
        }), map(function (data) {
            _this.store.dispatch(new GetMaintenancePlanLoaded());
            return new GetMaintenancePlan(data);
        }));
    };
    DashboardFacade.prototype.getSpreads = function () {
        var _this = this;
        this.store.dispatch(new GetSpreadsLoading());
        return this.dashboardService.loadSpreads().pipe(catchError(function (err) {
            _this.handleError(err);
            _this.store.dispatch(new GetSpreadsFailed());
            return throwError(err);
        }), map(function (data) {
            _this.store.dispatch(new GetSpreadsLoaded());
            return new GetSpreads(data);
        }));
    };
    DashboardFacade.prototype.getAllFilterSpreads = function (filterId) {
        var _this = this;
        this.store.dispatch(new GetAllFilterSpreadsLoading());
        return this.dashboardService.loadSpreadsByFilterId(filterId).pipe(catchError(function (err) {
            _this.handleError(err);
            _this.store.dispatch(new GetAllFilterSpreadsFailed());
            return throwError(err);
        }), map(function (data) {
            _this.store.dispatch(new GetAllFilterSpreadsLoaded());
            return new GetAllFilterSpreads(data);
        }));
    };
    DashboardFacade.prototype.getPreviewSpreads = function () {
        var _this = this;
        this.store.dispatch(new GetPreviewSpreadsLoading());
        return this.dashboardService.loadSpreads().pipe(catchError(function (err) {
            _this.handleError(err);
            _this.store.dispatch(new GetPreviewSpreadsFailed());
            return throwError(err);
        }), map(function (data) {
            _this.store.dispatch(new GetPreviewSpreadsLoaded());
            return new GetPreviewSpreads(data);
        }));
    };
    DashboardFacade.prototype.getSpreadFilters = function (userId) {
        var _this = this;
        this.store.dispatch(new GetSpreadFiltersLoading());
        return this.dashboardService.loadSpreadFilters(userId).pipe(catchError(function (err) {
            _this.handleError(err);
            _this.store.dispatch(new GetSpreadFiltersFailed());
            return throwError(err);
        }), map(function (data) {
            _this.store.dispatch(new GetSpreadFiltersLoaded());
            return new GetSpreadFilters(data);
        }));
    };
    DashboardFacade.prototype.getSpreadMarketAreas = function () {
        var _this = this;
        this.store.dispatch(new GetSpreadMarketAreasLoading());
        return this.dashboardService.loadSpreadMarketAreas().pipe(catchError(function (err) {
            _this.handleError(err);
            _this.store.dispatch(new GetSpreadMarketAreasFailed());
            return throwError(err);
        }), map(function (data) {
            _this.store.dispatch(new GetSpreadMarketAreasLoaded());
            return new GetSpreadMarketAreas(data);
        }));
    };
    DashboardFacade.prototype.getFilterForEdit = function (userId, filterId) {
        var _this = this;
        this.store.dispatch(new GetFullSpreadFilterLoading());
        return this.dashboardService.loadFullFilterInfo(userId, filterId).pipe(catchError(function (err) {
            _this.handleError(err);
            _this.store.dispatch(new GetFullSpreadFilterFailed());
            return throwError(err);
        }), map(function (data) {
            _this.store.dispatch(new GetFullSpreadFilterLoaded());
            return new GetFullSpreadFilter(data);
        }));
    };
    DashboardFacade.prototype.deleteFullSpreadFilter = function () {
        return this.store.dispatch(new DeleteFullSpreadFilter());
    };
    DashboardFacade.prototype.getMaintenanceUpdate = function () {
        var _this = this;
        this.store.dispatch(new GetMaintenanceUpdate());
        return this.dashboardService.loadMaintenanceUpdates().pipe(catchError(function (err) {
            _this.handleError(err);
            _this.store.dispatch(new GetMaintenanceUpdateFailed());
            return throwError(err);
        }), map(function (data) { return new GetMaintenanceUpdateSuccess(data); }));
    };
    DashboardFacade.prototype.getPipelineCountries = function (period) {
        var _this = this;
        this.store.dispatch(new GetPipelineCountriesLoading());
        return this.dashboardService.loadPipelineCountries(period).pipe(catchError(function (err) {
            _this.handleError(err);
            _this.store.dispatch(new GetPipelineCountriesFailed());
            return throwError(err);
        }), map(function (data) {
            _this.store.dispatch(new GetPipelineCountriesLoaded());
            return new GetPipelineCountries(data);
        }));
    };
    DashboardFacade.prototype.getPipelineCountriesVsLngSupply = function (period) {
        var _this = this;
        this.store.dispatch(new GetPipelineCountriesVsLngSupplyLoading());
        return this.dashboardService.loadPipelineCountriesVsLngSupply(period).pipe(catchError(function (err) {
            _this.handleError(err);
            _this.store.dispatch(new GetPipelineCountriesVsLngSupplyFailed());
            return throwError(err);
        }), map(function (data) {
            _this.store.dispatch(new GetPipelineCountriesVsLngSupplyLoaded());
            return new GetPipelineCountriesVsLngSupply(data);
        }));
    };
    DashboardFacade.prototype.getLastAuctionStats = function (period) {
        var _this = this;
        this.store.dispatch(new GetLastAuctionStatsLoading());
        return this.dashboardService.loadLastAuctionStats(period).pipe(catchError(function (err) {
            _this.handleError(err);
            _this.store.dispatch(new GetLastAuctionStatsFailed());
            return throwError(err);
        }), map(function (data) {
            _this.store.dispatch(new GetLastAuctionStatsLoaded());
            return new GetLastAuctionStats(data);
        }));
    };
    DashboardFacade.prototype.getBlockPositions = function (userId) {
        var _this = this;
        return this.dashboardService.loadBlockPositions(userId).pipe(catchError(function (err) {
            _this.handleError(err);
            return throwError(err);
        }), map(function (data) { return new GetBlockPositions(data); }));
    };
    DashboardFacade.prototype.handleError = function (err) {
        var _a;
        if ((err === null || err === void 0 ? void 0 : err.status) === ErrorTypeListEnum.FORBIDDEN) {
            return;
        }
        this.toastr.error((_a = err === null || err === void 0 ? void 0 : err.status) === null || _a === void 0 ? void 0 : _a.toString(), err === null || err === void 0 ? void 0 : err.statusText);
    };
    __decorate([
        Select(DashboardSelectors.filteredByMergeGermanyDateHubPrices),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "hubPrices$", void 0);
    __decorate([
        Select(DashboardSelectors.hubPricesIsLoading),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "hubPricesIsLoading$", void 0);
    __decorate([
        Select(DashboardSelectors.lngCountries),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "lngCountries$", void 0);
    __decorate([
        Select(DashboardSelectors.lngCountriesIsLoading),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "lngCountriesIsLoading$", void 0);
    __decorate([
        Select(DashboardSelectors.maintenancePlanAll),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "maintenancePlanAll$", void 0);
    __decorate([
        Select(DashboardSelectors.getMaintenancePlanAllFilters),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "getMaintenancePlanAllFilters$", void 0);
    __decorate([
        Select(DashboardSelectors.getMaintenancePlanMyPortfolioFilters),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "getMaintenancePlanMyPortfolioFilters$", void 0);
    __decorate([
        Select(DashboardSelectors.maintenancePlanIsLoading),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "maintenancePlanIsLoading$", void 0);
    __decorate([
        Select(DashboardSelectors.maintenanceUpdateAll),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "maintenanceUpdateAll$", void 0);
    __decorate([
        Select(DashboardSelectors.maintenanceUpdateLoading),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "maintenanceUpdateLoading$", void 0);
    __decorate([
        Select(DashboardSelectors.maintenanceUpdateMyPortfolio),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "maintenanceUpdateMyPortfolio$", void 0);
    __decorate([
        Select(DashboardSelectors.getMaintenanceUpdateAllFilters),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "getMaintenanceUpdateAllFilters$", void 0);
    __decorate([
        Select(DashboardSelectors.getMaintenanceUpdateMyPortfolioFilters),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "getMaintenanceUpdateMyPortfolioFilters$", void 0);
    __decorate([
        Select(DashboardSelectors.pipelineCountries),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "pipelineCountries$", void 0);
    __decorate([
        Select(DashboardSelectors.pipelineCountriesIsLoading),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "pipelineCountriesIsLoading$", void 0);
    __decorate([
        Select(DashboardSelectors.pipelineCountriesVsLng),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "pipelineCountriesVsLngSupply$", void 0);
    __decorate([
        Select(DashboardSelectors.pipelineCountriesVsLngIsLoading),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "pipelineCountriesVsLngSupplyIsLoading$", void 0);
    __decorate([
        Select(DashboardSelectors.lastAuctionStats),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "lastAuctionStats$", void 0);
    __decorate([
        Select(DashboardSelectors.lastAuctionStatsIsLoading),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "lastAuctionStatsIsLoading$", void 0);
    __decorate([
        Select(DashboardSelectors.spreads),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "spreads$", void 0);
    __decorate([
        Select(DashboardSelectors.spreadsLoading),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "spreadsLoading$", void 0);
    __decorate([
        Select(DashboardSelectors.spreadFilters),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "spreadFilters$", void 0);
    __decorate([
        Select(DashboardSelectors.spreadFiltersLoading),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "spreadFiltersLoading$", void 0);
    __decorate([
        Select(DashboardSelectors.allFilterSpreads),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "allFilterSpreads$", void 0);
    __decorate([
        Select(DashboardSelectors.allFilterSpreadsLoading),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "allFilterSpreadsLoading$", void 0);
    __decorate([
        Select(DashboardSelectors.spreadFilterForEdit),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "spreadFilterForEdit$", void 0);
    __decorate([
        Select(DashboardSelectors.spreadFilterForEditLoading),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "spreadFilterForEditLoading$", void 0);
    __decorate([
        Select(DashboardSelectors.spreadMarketAreas),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "spreadMarketAreas$", void 0);
    __decorate([
        Select(DashboardSelectors.spreadMarketAreasLoading),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "spreadMarketAreasLoading$", void 0);
    __decorate([
        Select(DashboardSelectors.blockPositions),
        __metadata("design:type", Observable)
    ], DashboardFacade.prototype, "blockPositions$", void 0);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, Number]),
        __metadata("design:returntype", Observable)
    ], DashboardFacade.prototype, "getHubPrices", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Observable)
    ], DashboardFacade.prototype, "getLngCountries", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], DashboardFacade.prototype, "getMaintenancePlan", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], DashboardFacade.prototype, "getSpreads", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Observable)
    ], DashboardFacade.prototype, "getAllFilterSpreads", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], DashboardFacade.prototype, "getPreviewSpreads", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Observable)
    ], DashboardFacade.prototype, "getSpreadFilters", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], DashboardFacade.prototype, "getSpreadMarketAreas", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, Number]),
        __metadata("design:returntype", Observable)
    ], DashboardFacade.prototype, "getFilterForEdit", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], DashboardFacade.prototype, "deleteFullSpreadFilter", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], DashboardFacade.prototype, "getMaintenanceUpdate", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Observable)
    ], DashboardFacade.prototype, "getPipelineCountries", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Observable)
    ], DashboardFacade.prototype, "getPipelineCountriesVsLngSupply", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Observable)
    ], DashboardFacade.prototype, "getLastAuctionStats", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Observable)
    ], DashboardFacade.prototype, "getBlockPositions", null);
    return DashboardFacade;
}());
export { DashboardFacade };
