import { BehaviorSubject } from 'rxjs';
var RoleNavigationHandlerService = /** @class */ /*@__PURE__*/ (function () {
    function RoleNavigationHandlerService() {
        this.currentUrl = new BehaviorSubject(null);
        this.onPut = this.currentUrl.asObservable();
    }
    RoleNavigationHandlerService.prototype.put = function (url) {
        this.currentUrl.next(url);
    };
    return RoleNavigationHandlerService;
}());
export { RoleNavigationHandlerService };
