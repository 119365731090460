export interface Category {
  name: string;
  pages: Page[];
}

interface Page {
  name: string;
  order: number;
  link: string;
  target?: string;
}

export const CATEGORIES: Category[] = [
  {
    name: 'Contact',
    pages: [
      {
        name: 'Contact us',
        order: 1,
        link: 'https://www.appygas.com/cotact-us',
        target: '_blank'
      }
    ]
  },
  {
    name: 'About us',
    pages: [
      {
        name: 'Company',
        order: 1,
        link: 'https://www.appygas.com/company',
        target: '_blank'
      },
      {
        name: 'Roadmap',
        order: 2,
        link: 'https://www.appygas.com/roadmap',
        target: '_blank'
      }
    ]
  },
  {
    name: 'Media',
    pages: [
      {
        name: 'News',
        order: 1,
        link: 'https://www.appygas.com/blog',
        target: '_blank'
      },
      {
        name: 'Video',
        order: 2,
        link: 'https://www.appygas.com/video-tutorials',
        target: '_blank'
      }
    ]
  },
  {
    name: 'User Info',
    pages: [
      {
        name: 'Demo',
        order: 1,
        link: 'https://www.youtube.com/watch?v=7Sssui8Qz1w',
        target: '_blank'
      },
      {
        name: 'Free trial',
        order: 2,
        link: '/auth/signup'
      },
      {
        name: 'Pricing',
        order: 3,
        link: 'https://www.appygas.com/pricing',
        target: '_blank'
      },
      {
        name: 'FAQ',
        order: 4,
        link: 'https://www.appygas.com/faq',
        target: '_blank'
      }
    ]
  },
  {
    name: 'Legal',
    pages: [
      {
        name: 'Disclaimer',
        order: 1,
        link: 'https://www.appygas.com/disclaimer',
        target: '_blank'
      },
      {
        name: 'Imprint',
        order: 2,
        link: 'https://www.appygas.com/imprint',
        target: '_blank'
      },
      {
        name: 'Privacy Policy',
        order: 3,
        link: 'https://www.appygas.com/privacy-policy',
        target: '_blank'
      },
      {
        name: 'Terms of Service',
        order: 4,
        link: 'https://www.appygas.com/terms-of-services',
        target: '_blank'
      }
    ]
  }
];
