/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "./trial-expired.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/appy-gas-core/dist/components/button/button.component.ngfactory";
import * as i3 from "appy-gas-core/dist/components/button/button.component";
import * as i4 from "./trial-expired.component";
import * as i5 from "appy-gas-core/dist/components/modal/modal.service";
import * as i6 from "@angular/router";
var styles_TrialExpiredComponent = [i0.styles];
var RenderType_TrialExpiredComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_TrialExpiredComponent, data: {} });
export { RenderType_TrialExpiredComponent as RenderType_TrialExpiredComponent };
export function View_TrialExpiredComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "trial-expired"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "trial-expired__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your free trial has expired"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "trial-expired__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "trial-expired__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Contact appygas team if you want to become a premium user and keep accessing all the functionalities, continue as a free user or deactivate your account "])), (_l()(), i1.ɵeld(6, 0, null, null, 12, "div", [["class", "trial-expired__controls-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "button", [["appyGasButton", ""]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.upgradePremium() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(8, 638976, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["upgrade to exclusive"])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "button", [["appyGasButton", ""]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.continueAsFree() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(12, 638976, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i1.ɵeld(13, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["continue as a free user"])), (_l()(), i1.ɵeld(15, 0, null, null, 3, "button", [["appyGasButton", ""]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.deactivate() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(16, 638976, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i1.ɵeld(17, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["deactivate my account"]))], function (_ck, _v) { var currVal_0 = "primary"; _ck(_v, 8, 0, currVal_0); var currVal_1 = "secondary"; _ck(_v, 12, 0, currVal_1); var currVal_2 = "secondary"; _ck(_v, 16, 0, currVal_2); }, null);
}
export function View_TrialExpiredComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "appy-gas-trial-expired", [], null, null, null, View_TrialExpiredComponent_0, RenderType_TrialExpiredComponent)), i1.ɵdid(1, 49152, null, 0, i4.TrialExpiredComponent, [i5.ModalService, i6.Router], null, null)], null, null); }
var TrialExpiredComponentNgFactory = /*@__PURE__*/ i1.ɵccf("appy-gas-trial-expired", i4.TrialExpiredComponent, View_TrialExpiredComponent_Host_0, {}, {}, []);
export { TrialExpiredComponentNgFactory as TrialExpiredComponentNgFactory };
