export var EventClassNamesEnum = /*@__PURE__*/ (function (EventClassNamesEnum) {
    EventClassNamesEnum["ONE_DAY"] = "one-day-event";
    EventClassNamesEnum["FIRST_DAY"] = "first-day-event";
    EventClassNamesEnum["LAST_DAY"] = "last-day-event";
    EventClassNamesEnum["CURRENT_EVENT"] = "current-event";
    EventClassNamesEnum["OTHER_EVENT"] = "other-event";
    EventClassNamesEnum["FIRST_ROW"] = "row-1";
    EventClassNamesEnum["SECOND_ROW"] = "row-2";
    EventClassNamesEnum["THIRD_ROW"] = "row-3";
    EventClassNamesEnum["FOURTH_ROW"] = "row-4";
    EventClassNamesEnum["HIDDEN_ROW"] = "row-hidden";
    return EventClassNamesEnum;
})({});
