import { Store } from '@ngxs/store';
import { interval } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ChangeDetectorRef, Injector } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivationStart, NavigationEnd, Router } from '@angular/router';
import { Logger, ModalService } from 'appy-gas-core';
import * as META_TAGS from '../../config/meta-tags';
import { AuthModalHolderService } from './auth';
import { pushAlertInit, RouteChangeHandlerService, userSnapGlobalInit } from './core';
import { RoleNavigationHandlerService } from './core/role-navigation-handler/role-navigation-handler.service';
import { UserSelectors } from './store';
var log = new Logger('App');
var AppComponent = /** @class */ /*@__PURE__*/ (function () {
    function AppComponent(routeChangeHandlerService, roleNavigationHandler, router, activatedRoute, authModalHolder, injector, modalService, titleService, metaService, changeDetector, store, 
    // tslint:disable-next-line:typedef
    envVariables) {
        var _this = this;
        this.routeChangeHandlerService = routeChangeHandlerService;
        this.roleNavigationHandler = roleNavigationHandler;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.authModalHolder = authModalHolder;
        this.injector = injector;
        this.modalService = modalService;
        this.titleService = titleService;
        this.metaService = metaService;
        this.changeDetector = changeDetector;
        this.store = store;
        this.envVariables = envVariables;
        this.currentTime = new Date().getTime();
        this.endTimeDisplayBanner = new Date('2021-11-01').getTime();
        this.is404Page = false;
        this.user$ = this.store.select(UserSelectors.getUser);
        this.isValidDate$ = interval(1000).pipe(map(function () { return _this.currentTime < _this.endTimeDisplayBanner; }));
    }
    AppComponent.prototype.ngOnInit = function () {
        if (IS_PRODUCTION) {
            // Setup logger
            Logger.enableProductionMode();
        }
        log.debug('init');
        this.routeChangeHandlerService.scrollToTopOnRouteChange();
        // switch between components in same modal to prevent circular dependency
        this.handleSubscriptions();
        this.updateMetaData();
        userSnapGlobalInit();
        pushAlertInit();
    };
    AppComponent.prototype.ngAfterViewChecked = function () {
        this.changeDetector.detectChanges();
    };
    AppComponent.prototype.getChild = function (activatedRoute) {
        return activatedRoute.firstChild ? this.getChild(activatedRoute.firstChild) : activatedRoute;
    };
    AppComponent.prototype.handleSubscriptions = function () {
        var _this = this;
        this.authModalHolder.onPut.pipe(filter(function (context) { return !!context; })).subscribe(function () {
            _this.router.navigate(['/auth/login']);
        });
        this.roleNavigationHandler.onPut.pipe(filter(function (url) { return !!url; })).subscribe(function (url) {
            _this.router.navigate([url]);
        });
        this.router.events
            .pipe(filter(function (event) { return event instanceof ActivationStart; }))
            .subscribe(function (_a) {
            var snapshot = _a.snapshot;
            _this.is404Page = snapshot.routeConfig.path === '**';
        });
    };
    AppComponent.prototype.updateMetaData = function () {
        var _this = this;
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function () {
            var rt = _this.getChild(_this.activatedRoute);
            rt.data.subscribe(function (data) {
                _this.titleService.setTitle(data.title + " | " + META_TAGS.title);
                _this.metaService.updateTag({ name: 'title', content: data.title + " | " + META_TAGS.title });
            });
        });
        this.metaService.updateTag({ name: 'description', content: META_TAGS.description });
    };
    return AppComponent;
}());
export { AppComponent };
