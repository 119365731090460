import { __decorate, __metadata } from "tslib";
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetMarketArea, GetPointDetails } from './market-area-view.actions';
var MarketAreaViewFacadeService = /** @class */ /*@__PURE__*/ (function () {
    function MarketAreaViewFacadeService(marketAreaViewService, toastr) {
        this.marketAreaViewService = marketAreaViewService;
        this.toastr = toastr;
    }
    MarketAreaViewFacadeService.prototype.getPointDetails = function (params, marketAreaPosition) {
        var _this = this;
        return this.marketAreaViewService.getPointDetails(params).pipe(catchError(function (err) {
            _this.toastr.error(err.status, err.statusText);
            return throwError(err);
        }), map(function (data) { return new GetPointDetails(data, marketAreaPosition); }));
    };
    MarketAreaViewFacadeService.prototype.getMarketArea = function (areaId, gasDayRange, marketAreaPosition) {
        var _this = this;
        return this.marketAreaViewService.getMarketArea(areaId, gasDayRange).pipe(catchError(function (err) {
            _this.toastr.error(err.status, err.statusText);
            return throwError(err);
        }), map(function (marketAreaConfig) { return new GetMarketArea(marketAreaConfig, marketAreaPosition); }));
    };
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, String]),
        __metadata("design:returntype", Observable)
    ], MarketAreaViewFacadeService.prototype, "getPointDetails", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, Object, String]),
        __metadata("design:returntype", Observable)
    ], MarketAreaViewFacadeService.prototype, "getMarketArea", null);
    return MarketAreaViewFacadeService;
}());
export { MarketAreaViewFacadeService };
