import { norwayName } from '../../../../shared';

export const NorwayConfig = {
  [norwayName]: {
    name: norwayName,
    circlePosition: {},
    scalingPosition: {},
    marketAreasIds: [],
    discPosition: {
      x: 365,
      y: 20
    }
  }
};
