import { __assign, __decorate, __metadata, __spreadArrays } from "tslib";
import { Action, State } from '@ngxs/store';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { MarketAreaNameConfig } from 'appy-gas-core';
import { duplicatedPortfolioPointMAExceptions } from '../../shared';
import * as actions from './profile.actions';
var ɵ0 = undefined, ɵ1 = undefined, ɵ2 = undefined, ɵ3 = undefined, ɵ4 = undefined, ɵ5 = undefined;
var ProfileState = /** @class */ /*@__PURE__*/ (function () {
    function ProfileState() {
    }
    ProfileState_1 = ProfileState;
    ProfileState.distinctDuplicatedMAPoints = function (pointId) {
        return duplicatedPortfolioPointMAExceptions[pointId] || '';
    };
    ProfileState.prototype.getApiUsersList = function (ctx) {
        var state = ctx.getState();
        ctx.setState(__assign(__assign({}, state), { apiUsersList: __assign(__assign({}, state.apiUsersList), { loading: true }) }));
    };
    ProfileState.prototype.getApiUsersListSuccess = function (ctx, _a) {
        var apiUsersList = _a.apiUsersList;
        var state = ctx.getState();
        ctx.setState(__assign(__assign({}, state), { apiUsersList: { data: apiUsersList, loading: false, loaded: true } }));
    };
    ProfileState.prototype.getApiUsersListFail = function (ctx) {
        var state = ctx.getState();
        ctx.setState(__assign(__assign({}, state), { apiUsersList: __assign(__assign({}, state.apiUsersList), { loading: false, loaded: false }) }));
    };
    ProfileState.prototype.getPortfolioInformation = function (ctx) {
        var state = ctx.getState();
        ctx.setState(__assign(__assign({}, state), { portfolioInformation: __assign(__assign({}, state.portfolioInformation), { loading: true }) }));
    };
    ProfileState.prototype.getPortfolioInformationSuccess = function (ctx, _a) {
        var portfolioInformation = _a.portfolioInformation;
        var mapWithFullPointName = portfolioInformation.points.map(function (point) {
            return __assign(__assign({}, point), { pointNameWithOperatorIndex: point.name + " - " + point.operatorId, marketArea: "" + MarketAreaNameConfig[point.marketAreaId] + ProfileState_1.distinctDuplicatedMAPoints(point.id) });
        });
        var groupByPointNameWithOperatorIndex = groupBy(mapWithFullPointName, 'pointNameWithOperatorIndex');
        var duplicatedPoints = compact(flatten(map(groupByPointNameWithOperatorIndex, function (points) {
            if (points.length > 1) {
                return __spreadArrays(points);
            }
        })));
        portfolioInformation.points = portfolioInformation.points.map(function (point) {
            var findDuplicate = duplicatedPoints.find(function (duplicatedPoint) { return point.id === duplicatedPoint.id; });
            return findDuplicate ? findDuplicate : point;
        });
        var state = ctx.getState();
        ctx.setState(__assign(__assign({}, state), { portfolioInformation: { data: portfolioInformation, loading: false, loaded: true } }));
    };
    ProfileState.prototype.getPortfolioInformationFail = function (ctx) {
        var state = ctx.getState();
        ctx.setState(__assign(__assign({}, state), { portfolioInformation: __assign(__assign({}, state.portfolioInformation), { loading: false, loaded: false }) }));
    };
    ProfileState.prototype.getUserProfileInformation = function (_a, _b) {
        var patchState = _a.patchState;
        var user = _b.user;
        patchState({ userProfile: user });
    };
    ProfileState.prototype.getUserPortfolioPoints = function (ctx) {
        var state = ctx.getState();
        ctx.setState(__assign(__assign({}, state), { savedPointsIds: __assign(__assign({}, state.savedPointsIds), { loading: true }) }));
    };
    ProfileState.prototype.getUserPortfolioPointsSuccess = function (ctx, _a) {
        var savedPointsIds = _a.savedPointsIds;
        var state = ctx.getState();
        ctx.setState(__assign(__assign({}, state), { savedPointsIds: { data: savedPointsIds, loading: false, loaded: true } }));
    };
    ProfileState.prototype.getUserPortfolioPointsFail = function (ctx) {
        var state = ctx.getState();
        ctx.setState(__assign(__assign({}, state), { savedPointsIds: __assign(__assign({}, state.savedPointsIds), { loading: false, loaded: false }) }));
    };
    ProfileState.prototype.saveUserPortfolioPoints = function (ctx, _a) {
        var savedPointsIds = _a.savedPointsIds;
        var state = ctx.getState();
        ctx.setState(__assign(__assign({}, state), { savedPointsIds: __assign(__assign({}, state.savedPointsIds), { data: savedPointsIds }) }));
    };
    ProfileState.prototype.saveApiUser = function (ctx) {
        var state = ctx.getState();
        ctx.setState(__assign(__assign({}, state), { apiUsersList: __assign(__assign({}, state.apiUsersList), { loading: true }) }));
    };
    ProfileState.prototype.saveApiUserSuccess = function (ctx, _a) {
        var apiUser = _a.apiUser;
        var state = ctx.getState();
        ctx.setState(__assign(__assign({}, state), { apiUsersList: { data: __spreadArrays([apiUser], state.apiUsersList.data), loading: false, loaded: true } }));
    };
    ProfileState.prototype.saveApiUserFail = function (ctx) {
        var state = ctx.getState();
        ctx.setState(__assign(__assign({}, state), { apiUsersList: __assign(__assign({}, state.apiUsersList), { loading: false, loaded: false }) }));
    };
    ProfileState.prototype.getWalletInformation = function (ctx) {
        var state = ctx.getState();
        ctx.setState(__assign(__assign({}, state), { appiesCount: __assign(__assign({}, state.appiesCount), { loading: true }), appiesTransactionHistory: __assign(__assign({}, state.appiesTransactionHistory), { loading: true }) }));
    };
    ProfileState.prototype.getWalletInformationSuccess = function (ctx, _a) {
        var appiesCount = _a.appiesCount, appiesTransactionHistory = _a.appiesTransactionHistory;
        var state = ctx.getState();
        ctx.setState(__assign(__assign({}, state), { appiesCount: { data: appiesCount, loading: false, loaded: true }, appiesTransactionHistory: { data: appiesTransactionHistory, loading: false, loaded: true } }));
    };
    ProfileState.prototype.getWalletInformationFail = function (ctx) {
        var state = ctx.getState();
        ctx.setState(__assign(__assign({}, state), { appiesCount: __assign(__assign({}, state.appiesCount), { loading: false, loaded: false }), appiesTransactionHistory: __assign(__assign({}, state.appiesTransactionHistory), { loading: false, loaded: false }) }));
    };
    ProfileState.prototype.updateUserProfileNewsLetter = function (ctx, _a) {
        var newsLetter = _a.newsLetter;
        var state = ctx.getState();
        ctx.setState(__assign(__assign({}, state), { userProfile: __assign(__assign({}, state.userProfile), { newsLetter: newsLetter }) }));
    };
    ProfileState.prototype.setWalletBalanceStatus = function (_a, _b) {
        var patchState = _a.patchState;
        var balanceStatus = _b.balanceStatus;
        patchState({ balanceStatus: balanceStatus });
    };
    ProfileState.prototype.getUserBadges = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({ userBadges: __assign(__assign({}, state.userBadges), { loading: true }) });
    };
    ProfileState.prototype.getUserBadgesSuccess = function (_a, _b) {
        var patchState = _a.patchState;
        var userBadges = _b.userBadges;
        patchState({ userBadges: { data: userBadges, loaded: true, loading: false } });
    };
    ProfileState.prototype.getUserBadgesFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            userBadges: __assign(__assign({}, state.userBadges), { loaded: false, loading: false })
        });
    };
    ProfileState.prototype.getAllBadges = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({ allBadges: __assign(__assign({}, state.allBadges), { loading: true }) });
    };
    ProfileState.prototype.getAllBadgesSuccess = function (_a, _b) {
        var patchState = _a.patchState;
        var allBadges = _b.allBadges;
        patchState({ allBadges: { data: allBadges, loaded: true, loading: false } });
    };
    ProfileState.prototype.getAllBadgesFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            allBadges: __assign(__assign({}, state.allBadges), { loaded: false, loading: false })
        });
    };
    var ProfileState_1;
    __decorate([
        Action(actions.GetApiUsersList),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getApiUsersList", null);
    __decorate([
        Action(actions.GetApiUsersListSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, actions.GetApiUsersListSuccess]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getApiUsersListSuccess", null);
    __decorate([
        Action(actions.GetApiUsersListFail),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getApiUsersListFail", null);
    __decorate([
        Action(actions.GetPortfolioInformation),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getPortfolioInformation", null);
    __decorate([
        Action(actions.GetPortfolioInformationSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, actions.GetPortfolioInformationSuccess]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getPortfolioInformationSuccess", null);
    __decorate([
        Action(actions.GetPortfolioInformationFail),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getPortfolioInformationFail", null);
    __decorate([
        Action(actions.GetUserProfile),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, actions.GetUserProfile]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getUserProfileInformation", null);
    __decorate([
        Action(actions.GetUserPortfolioPoints),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getUserPortfolioPoints", null);
    __decorate([
        Action(actions.GetUserPortfolioPointsSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, actions.GetUserPortfolioPointsSuccess]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getUserPortfolioPointsSuccess", null);
    __decorate([
        Action(actions.GetUserPortfolioPointsFail),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getUserPortfolioPointsFail", null);
    __decorate([
        Action(actions.SaveUserPortfolioPoints),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, actions.SaveUserPortfolioPoints]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "saveUserPortfolioPoints", null);
    __decorate([
        Action(actions.SaveApiUser),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "saveApiUser", null);
    __decorate([
        Action(actions.SaveApiUserSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, actions.SaveApiUserSuccess]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "saveApiUserSuccess", null);
    __decorate([
        Action(actions.SaveApiUserFail),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "saveApiUserFail", null);
    __decorate([
        Action(actions.GetWalletInformation),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getWalletInformation", null);
    __decorate([
        Action(actions.GetWalletInformationSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, actions.GetWalletInformationSuccess]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getWalletInformationSuccess", null);
    __decorate([
        Action(actions.GetWalletInformationFail),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getWalletInformationFail", null);
    __decorate([
        Action(actions.UpdateUserProfileNewsLetter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, actions.UpdateUserProfileNewsLetter]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "updateUserProfileNewsLetter", null);
    __decorate([
        Action(actions.SetWalletBalanceStatus),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, actions.SetWalletBalanceStatus]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "setWalletBalanceStatus", null);
    __decorate([
        Action(actions.GetUserBadges),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getUserBadges", null);
    __decorate([
        Action(actions.GetUserBadgesSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, actions.GetUserBadgesSuccess]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getUserBadgesSuccess", null);
    __decorate([
        Action(actions.GetUserBadgesFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getUserBadgesFailed", null);
    __decorate([
        Action(actions.GetAllBadges),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getAllBadges", null);
    __decorate([
        Action(actions.GetAllBadgesSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, actions.GetAllBadgesSuccess]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getAllBadgesSuccess", null);
    __decorate([
        Action(actions.GetAllBadgesFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ProfileState.prototype, "getAllBadgesFailed", null);
    ProfileState = ProfileState_1 = __decorate([
        State({
            name: 'profile',
            defaults: {
                apiUsersForm: {
                    model: undefined
                },
                userProfile: undefined,
                apiUsersList: {
                    data: ɵ0,
                    loading: false,
                    loaded: false
                },
                portfolioInformation: undefined,
                savedPointsIds: {
                    data: ɵ1,
                    loading: false,
                    loaded: false
                },
                appiesCount: {
                    data: ɵ2,
                    loading: false,
                    loaded: false
                },
                appiesTransactionHistory: {
                    data: ɵ3,
                    loading: false,
                    loaded: false
                },
                balanceStatus: undefined,
                userBadges: {
                    data: ɵ4,
                    loading: false,
                    loaded: false
                },
                allBadges: {
                    data: ɵ5,
                    loading: false,
                    loaded: false
                }
            }
        })
    ], ProfileState);
    return ProfileState;
}());
export { ProfileState };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
