import { UpdateProfileComponent } from './update-profile.component';

export const updateProfileModalConfig = {
  title: 'Please update your profile information to be able to sign in',
  component: {
    name: UpdateProfileComponent,
    options: {}
  },
  data: {
    width: 670
  }
};
