import { MaintenanceFilterTypeEnum } from '../enums/maintenance-filter-type.enum';

export interface MaintenanceFilterSync {
  [key: number]: {
    fieldName: string;
    property: string;
    syncProperty: string;
  };
}

export const maintenanceFilterSyncConfig: MaintenanceFilterSync = {
  [MaintenanceFilterTypeEnum.IP]: { fieldName: 'tso', property: 'value', syncProperty: 'tsoId' },
  [MaintenanceFilterTypeEnum.TSO]: { fieldName: 'ip', property: 'tsoId', syncProperty: 'value' }
};
