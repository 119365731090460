import { __assign } from "tslib";
import { Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { ModalService, Role, TokenType } from 'appy-gas-core';
import { AuthModalHolderService, AuthModalType } from '../../auth';
import { exclusiveAccessExpiredConfig } from '../../shared/components/user-transition-modals/exclusive-access-expired';
import { extendPremiumModalConfig } from '../extend-premium';
import { RoleNavigationHandlerService } from '../role-navigation-handler/role-navigation-handler.service';
import { RouterStateService } from '../router/router-state.service';
import { upgradePremiumModalConfig } from '../upgrade-premium';
import { AuthenticationService } from './authentication.service';
var AuthenticationGuard = /** @class */ /*@__PURE__*/ (function () {
    function AuthenticationGuard(router, authenticationService, modalService, injector, routerState, modalHolder, roleNavigationHandler) {
        this.router = router;
        this.authenticationService = authenticationService;
        this.modalService = modalService;
        this.injector = injector;
        this.routerState = routerState;
        this.modalHolder = modalHolder;
        this.roleNavigationHandler = roleNavigationHandler;
    }
    AuthenticationGuard.getPermissions = function (route, state) {
        var purePermissions = !!route && route.data ? route.data['permissions'] : {};
        var permissions = __assign({}, purePermissions);
        if (!!permissions.except && !Array.isArray(permissions.except)) {
            permissions.except = [permissions.except];
        }
        if (!!permissions.only && !Array.isArray(permissions.only)) {
            permissions.only = [permissions.only];
        }
        return permissions;
    };
    AuthenticationGuard.isPathAllowedRole = function (permissions, role) {
        return ((!!permissions.only && permissions.only.includes(role)) ||
            (!!permissions.except && !permissions.except.includes(role)));
    };
    AuthenticationGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        var authenticated = this.authenticationService.getToken(TokenType.ACCESS);
        var permissions = AuthenticationGuard.getPermissions(route, state);
        return this.hasPermissions(permissions, route, state).then(function (authorized) {
            if (!authenticated && !authorized) {
                var modalConfig = {
                    component: {
                        options: {
                            redirectUrl: state.url
                        }
                    },
                    data: {}
                };
                // if it's direct link (no previous url)
                if (!_this.routerState.getPreviousUrl()) {
                    modalConfig.data.closable = false;
                }
                _this.modalHolder.put(AuthModalType.LOGIN, modalConfig);
                return false;
            }
            else if (authenticated && !authorized) {
                if (AuthenticationGuard.isPathAllowedRole(permissions, Role.PremiumPlus)) {
                    switch (_this.authenticationService.roleSource.value) {
                        case Role.Free:
                        case Role.Trial:
                            _this.modalService.show(_this.injector, upgradePremiumModalConfig);
                            return;
                        case Role.Premium:
                            _this.modalService.show(_this.injector, extendPremiumModalConfig);
                            return;
                        case Role.Expired:
                            _this.roleNavigationHandler.put('/profile');
                            _this.modalService.show(_this.injector, exclusiveAccessExpiredConfig);
                    }
                }
                else {
                    _this.modalService.show(_this.injector, upgradePremiumModalConfig);
                }
                return false;
            }
            else if ((authenticated || !authenticated) && authorized) {
                return true;
            }
        });
    };
    AuthenticationGuard.prototype.canActivateChild = function (childRoute, state) {
        var permissions = AuthenticationGuard.getPermissions(childRoute, state);
        return this.hasPermissions(permissions, childRoute, state);
    };
    AuthenticationGuard.prototype.hasPermissions = function (permissions, route, state) {
        var _this = this;
        if (!!permissions.except && Array.isArray(permissions.except)) {
            return this.authenticationService.allowRoles(permissions.except).then(function (allow) {
                if (allow) {
                    return false;
                }
                else {
                    if (permissions.only) {
                        return _this.checkOnlyPermissions(permissions, route, state);
                    }
                    return true;
                }
            });
        }
        if (permissions.only && Array.isArray(permissions.only)) {
            return this.checkOnlyPermissions(permissions, route, state);
        }
        return Promise.resolve(false);
    };
    AuthenticationGuard.prototype.checkOnlyPermissions = function (purePermissions, route, state) {
        var permissions = __assign({}, purePermissions);
        return this.authenticationService.allowRoles(permissions.only);
    };
    return AuthenticationGuard;
}());
export { AuthenticationGuard };
