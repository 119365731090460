import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import startsWith from 'lodash/startsWith';
import { Subject } from 'rxjs';
import { EventEmitter, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import filterIcon from 'appy-gas-core/dist/assets/svg/icons/filter.svg';
import { PointFilterTypesEnum } from '../../../availability-pro/availability-pro-map/map-events-table/enums/point-filter-types.enum';
import { maintenanceFilterSyncConfig } from '../../constants/maintenance-filter-sync.config';
import { MaintenanceFilterTypeEnum } from '../../enums';
var SearchPointsDropdownComponent = /** @class */ /*@__PURE__*/ (function () {
    function SearchPointsDropdownComponent(fb) {
        this.fb = fb;
        this.updateSelectedPointEventsFilter = new EventEmitter();
        this.searchFilterForm = this.buildFilterFormGroup();
        this.isSelectAll = false;
        this.isOpen = false;
        this.pointTypes = PointFilterTypesEnum;
        this.filterTabs = [
            {
                label: 'Points',
                type: PointFilterTypesEnum.POINTS
            },
            {
                label: 'Operators',
                type: PointFilterTypesEnum.OPERATORS
            }
        ];
        this.filterIcon = filterIcon;
        this.componentDestroyed$ = new Subject();
    }
    Object.defineProperty(SearchPointsDropdownComponent.prototype, "allSelected", {
        get: function () {
            return this.activeTabType === PointFilterTypesEnum.POINTS
                ? this.filteredIPBySearch.every(function (item) { return item.selected; })
                : this.filteredTSOBySearch.every(function (item) { return item.selected; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SearchPointsDropdownComponent.prototype, "filteredIPBySearch", {
        get: function () {
            var _this = this;
            return this.IPFilters.filter(function (filterItem) {
                return startsWith(filterItem.pointName.toUpperCase(), _this.searchFilterForm.get('searchFilter').value.toUpperCase());
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SearchPointsDropdownComponent.prototype, "filteredTSOBySearch", {
        get: function () {
            var _this = this;
            return this.TSOFilters.filter(function (filterItem) {
                return startsWith(filterItem.name.toUpperCase(), _this.searchFilterForm.get('searchFilter').value.toUpperCase());
            });
        },
        enumerable: false,
        configurable: true
    });
    SearchPointsDropdownComponent.prototype.ngOnInit = function () {
        this.activeTabType = PointFilterTypesEnum.POINTS;
    };
    SearchPointsDropdownComponent.prototype.ngOnChanges = function (changes) {
        if ((changes === null || changes === void 0 ? void 0 : changes.TSOIPFilters) && !isEqual(changes.TSOIPFilters.currentValue, changes.TSOIPFilters.previousValue)) {
            this.clonedTSOIPFilters = cloneDeep(changes.TSOIPFilters.currentValue);
            this.syncWithWidgetFilter(this.snapshotFilters);
            this.activeTabType = PointFilterTypesEnum.POINTS;
            this.IPFilters = this.clonedTSOIPFilters.ip;
            this.TSOFilters = this.clonedTSOIPFilters.tso;
            this.searchFilterForm.get('searchFilter').setValue('');
        }
    };
    SearchPointsDropdownComponent.prototype.ngOnDestroy = function () {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    };
    SearchPointsDropdownComponent.prototype.onSearchDropdownOpen = function (isOpen) {
        this.isOpen = isOpen;
    };
    SearchPointsDropdownComponent.prototype.onTabChange = function (pointTabType) {
        this.activeTabType = pointTabType;
    };
    SearchPointsDropdownComponent.prototype.toggleSelectAll = function () {
        if (this.allSelected) {
            this.filteredIPBySearch.forEach(function (item) { return (item.selected = false); });
            this.filteredTSOBySearch.forEach(function (item) { return (item.selected = false); });
        }
        else {
            this.filteredIPBySearch.forEach(function (item) { return (item.selected = true); });
            this.filteredTSOBySearch.forEach(function (item) { return (item.selected = true); });
        }
        this.toggleFilters();
    };
    SearchPointsDropdownComponent.prototype.onItemClick = function (filterItem) {
        filterItem.selected = !filterItem.selected;
        var selectedIPItems = this.clonedTSOIPFilters.ip.filter(function (item) { return item.selected; });
        var selectedTSOItems = this.clonedTSOIPFilters.tso.filter(function (item) { return item.selected; });
        this.activeTabType === PointFilterTypesEnum.POINTS
            ? this.syncFilters(selectedIPItems, MaintenanceFilterTypeEnum.IP)
            : this.syncFilters(selectedTSOItems, MaintenanceFilterTypeEnum.TSO);
        this.toggleFilters();
    };
    SearchPointsDropdownComponent.prototype.buildFilterFormGroup = function () {
        return this.fb.group({
            searchFilter: ''
        });
    };
    SearchPointsDropdownComponent.prototype.syncFilters = function (selectedItems, prop) {
        var filterConfig = maintenanceFilterSyncConfig[prop];
        var syncItems = Array.from(new Set(selectedItems.map(function (selectedItem) { return selectedItem[filterConfig.syncProperty]; })));
        this.clonedTSOIPFilters[filterConfig.fieldName].forEach(function (filterItem) {
            filterItem.selected =
                filterConfig.property === 'value'
                    ? (filterItem.selected = flatten(syncItems).some(function (syncItem) { return filterItem[filterConfig.property] === syncItem; }))
                    : (filterItem.selected = syncItems.some(function (syncItem) { return filterItem[filterConfig.property].includes(syncItem); }));
        });
    };
    SearchPointsDropdownComponent.prototype.toggleFilters = function () {
        var ip = this.clonedTSOIPFilters.ip;
        var tso = this.clonedTSOIPFilters.tso;
        var eventsWidgetFilter = {
            ip: ip,
            tso: tso,
            searchQuery: this.searchFilterForm.get('searchFilter').value
        };
        this.updateSelectedPointEventsFilter.emit(eventsWidgetFilter);
        this.selectedPointsCount = this.clonedTSOIPFilters.ip.filter(function (point) { return point.selected; }).length;
    };
    SearchPointsDropdownComponent.prototype.syncWithWidgetFilter = function (widgetFilter) {
        var _this = this;
        if (!widgetFilter) {
            return;
        }
        this.clonedTSOIPFilters.ip.forEach(function (ipItem) {
            var findSameFilter = widgetFilter.ip.find(function (cl) { return cl.value === ipItem.value; });
            ipItem.selected = findSameFilter ? findSameFilter.selected : false;
        });
        this.clonedTSOIPFilters.tso.forEach(function (tsoItem) {
            var findSameFilter = widgetFilter.tso.find(function (cl) { return cl.value === tsoItem.value; });
            tsoItem.selected = findSameFilter ? findSameFilter.selected : false;
        });
        setTimeout(function () { return _this.toggleFilters(); }); // TODO: fix it
    };
    return SearchPointsDropdownComponent;
}());
export { SearchPointsDropdownComponent };
