/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "./update-profile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/appy-gas-core/dist/components/button/button.component.ngfactory";
import * as i3 from "appy-gas-core/dist/components/button/button.component";
import * as i4 from "./update-profile.component";
import * as i5 from "../../../core/http-auth/authentication.service";
import * as i6 from "appy-gas-core/dist/components/modal/modal.service";
import * as i7 from "@angular/router";
var styles_UpdateProfileComponent = [i0.styles];
var RenderType_UpdateProfileComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_UpdateProfileComponent, data: {} });
export { RenderType_UpdateProfileComponent as RenderType_UpdateProfileComponent };
export function View_UpdateProfileComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "update-profile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "update-profile__header-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [["class", "update-profile__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please update your profile information to be able to continue using appygas"])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "update-profile__controls-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["appyGasButton", ""]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.goToProfile() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(6, 638976, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i1.ɵted(-1, 0, ["GO TO MY PROFILE PAGE"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["appyGasButton", ""]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.goToHomePage() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(9, 638976, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i1.ɵted(-1, 0, ["LOG OUT"]))], function (_ck, _v) { var currVal_0 = "secondary"; _ck(_v, 6, 0, currVal_0); var currVal_1 = "primary"; _ck(_v, 9, 0, currVal_1); }, null);
}
export function View_UpdateProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "appy-gas-update-profile", [], null, null, null, View_UpdateProfileComponent_0, RenderType_UpdateProfileComponent)), i1.ɵdid(1, 49152, null, 0, i4.UpdateProfileComponent, [i5.AuthenticationService, i6.ModalService, i7.Router], null, null)], null, null); }
var UpdateProfileComponentNgFactory = /*@__PURE__*/ i1.ɵccf("appy-gas-update-profile", i4.UpdateProfileComponent, View_UpdateProfileComponent_Host_0, {}, {}, []);
export { UpdateProfileComponentNgFactory as UpdateProfileComponentNgFactory };
