/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "./cookies-popover.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/appy-gas-core/dist/components/svg-icon/svg-icon.component.ngfactory";
import * as i3 from "appy-gas-core/dist/components/svg-icon/svg-icon.component";
import * as i4 from "@angular/common";
import * as i5 from "./cookies-popover.component";
import * as i6 from "./cookies-popover.service";
var styles_CookiesPopoverComponent = [i0.styles];
var RenderType_CookiesPopoverComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_CookiesPopoverComponent, data: { "animation": [{ type: 7, name: "slideInOut", definitions: [{ type: 0, name: "invisible", styles: { type: 6, styles: { transform: "translate(0, 75px)" }, offset: null }, options: undefined }, { type: 0, name: "visible", styles: { type: 6, styles: { transform: "translate(0, 0)" }, offset: null }, options: undefined }, { type: 1, expr: "invisible <=> visible", animation: { type: 4, styles: null, timings: "500ms ease-in-out" }, options: null }], options: {} }] } });
export { RenderType_CookiesPopoverComponent as RenderType_CookiesPopoverComponent };
function View_CookiesPopoverComponent_1(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "cookies-popover"]], [[24, "@slideInOut", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "cookies-popover__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "appy-gas-svg-icon", [["class", "cookies-popover__cookie-icon"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(3, 4833280, null, 0, i3.SvgIconComponent, [i1.ElementRef], { glyph: [0, "glyph"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [["class", "ml-5 cookies-popover__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" This Website is using cookies processing technically anonymised data. Data subjects are not identifiable. "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", "cookies-popover__link"], ["href", "https://www.appygas.com/privacy-policy"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Click here to view our Privacy Policy. "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "cookies-popover__button ml-5"]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.acceptCookies() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Got it"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "appy-gas-svg-icon", [["class", "cookies-popover__close-icon"]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.acceptCookies() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(12, 4833280, null, 0, i3.SvgIconComponent, [i1.ElementRef], { glyph: [0, "glyph"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.icons.cookies; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.icons.close; _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state; _ck(_v, 0, 0, currVal_0); });
}
export function View_CookiesPopoverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CookiesPopoverComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CookiesPopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "appy-gas-cookies-popover", [], null, null, null, View_CookiesPopoverComponent_0, RenderType_CookiesPopoverComponent)), i1.ɵdid(1, 114688, null, 0, i5.CookiesPopoverComponent, [i6.CookiesPopoverService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CookiesPopoverComponentNgFactory = /*@__PURE__*/ i1.ɵccf("appy-gas-cookies-popover", i5.CookiesPopoverComponent, View_CookiesPopoverComponent_Host_0, {}, {}, []);
export { CookiesPopoverComponentNgFactory as CookiesPopoverComponentNgFactory };
