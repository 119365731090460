import { __decorate, __metadata } from "tslib";
import { Action, State } from '@ngxs/store';
import { GetEePoints, GetOperators } from './common-entities.actions';
var CommonEntitiesState = /** @class */ /*@__PURE__*/ (function () {
    function CommonEntitiesState() {
    }
    CommonEntitiesState.prototype.getEeVirtualPoints = function (_a, _b) {
        var patchState = _a.patchState;
        var eePoints = _b.eePoints;
        patchState({ eePoints: eePoints });
    };
    CommonEntitiesState.prototype.getOperators = function (_a, _b) {
        var patchState = _a.patchState;
        var operators = _b.operators;
        patchState({ operators: operators });
    };
    __decorate([
        Action(GetEePoints),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetEePoints]),
        __metadata("design:returntype", void 0)
    ], CommonEntitiesState.prototype, "getEeVirtualPoints", null);
    __decorate([
        Action(GetOperators),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetOperators]),
        __metadata("design:returntype", void 0)
    ], CommonEntitiesState.prototype, "getOperators", null);
    CommonEntitiesState = __decorate([
        State({
            name: 'commonEntities',
            defaults: {
                eePoints: [],
                operators: []
            }
        })
    ], CommonEntitiesState);
    return CommonEntitiesState;
}());
export { CommonEntitiesState };
