var _a;
import { __assign, __decorate, __metadata, __spreadArrays } from "tslib";
import { Action, State } from '@ngxs/store';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import { ImpactRadioTypes } from '../../availability-pro/availability-pro-map/availability-pro-map-filter/interfaces';
import { WidgetTypesEnum } from '../../availability-pro/availability-pro-map/interfaces';
import { norwayDataSourceId } from '../../shared';
import { MAEventsTabDirection, UserPointTypesEnum } from '../../shared/enums';
import { ChangeAvailabilityPointTypes, GetAllEventPoints, GetAllEventPointsFailed, GetAllEventPointsSuccess, GetAllMaintenancePoints, GetAvailabilityData, GetEventDetails, GetEventDetailsLoading, GetImpactChartData, GetImpactChartDataLoading, GetOtherEvents, GetOtherEventsLoading, GetPortfolioMaintenancePoints, GetPortfolioMaintenancePointsFailed, GetPortfolioMaintenancePointsSuccess, GetRelatedPoints, GetRelatedPointsLoading, GetSelectedNorwayEvents, GetSelectedPEGSuperPointsEvents, GetSelectedPointEvents, GetSelectedPointEventsFailed, GetSelectedPointEventsSuccess, GetSelectedRouteDetails, GetSelectedRouteDetailsFailed, GetSelectedRouteDetailsSuccess, ResetPortfolioMaintenancePoints, ResetSelectedPointEvents, ResetSelectedRouteDetails, ResetSelectedRoutePoints, SetNavigatedFromExternalPageStatus, SetNavigatedFromGeneralInfoPageStatus, SetNorwayPointSelectedStatus, SetSelectedMapPointId, TogglePointInRoute, UpdateActiveMATabType, UpdateGasDayRange, UpdateMaintenanceFilters, UpdateSelectedPointEventsFilter, UpdateWidgetsVisibilityState } from './availability.actions';
var ɵ0 = [], ɵ1 = [], ɵ2 = undefined, ɵ3 = undefined;
var AvailabilityState = /** @class */ /*@__PURE__*/ (function () {
    function AvailabilityState() {
    }
    AvailabilityState.prototype.getAllMaintenancePoints = function (_a, _b) {
        var patchState = _a.patchState;
        var allMaintenancePoints = _b.allMaintenancePoints;
        patchState({ allMaintenancePoints: allMaintenancePoints });
    };
    AvailabilityState.prototype.getEventPoints = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({ allEventPoints: __assign(__assign({}, state.allEventPoints), { loading: true }) });
    };
    AvailabilityState.prototype.getEventPointsSuccess = function (_a, _b) {
        var patchState = _a.patchState;
        var allEventPoints = _b.allEventPoints;
        patchState({ allEventPoints: { data: allEventPoints, loaded: true, loading: false } });
    };
    AvailabilityState.prototype.getEventPointsFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({ allEventPoints: __assign(__assign({}, state.allEventPoints), { loaded: false, loading: false }) });
    };
    AvailabilityState.prototype.getPortfolioMaintenancePoints = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({ portfolioMaintenancePoints: __assign(__assign({}, state.portfolioMaintenancePoints), { loading: true }) });
    };
    AvailabilityState.prototype.resetPortfolioMaintenancePoints = function (_a) {
        var patchState = _a.patchState;
        patchState({ portfolioMaintenancePoints: { data: [], loaded: false, loading: false } });
    };
    AvailabilityState.prototype.getPortfolioMaintenancePointsSuccess = function (_a, _b) {
        var patchState = _a.patchState;
        var portfolioMaintenancePoints = _b.portfolioMaintenancePoints;
        patchState({
            portfolioMaintenancePoints: { data: portfolioMaintenancePoints, loaded: true, loading: false }
        });
    };
    AvailabilityState.prototype.getPortfolioMaintenancePointsFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            portfolioMaintenancePoints: __assign(__assign({}, state.portfolioMaintenancePoints), { loaded: false, loading: false })
        });
    };
    AvailabilityState.prototype.getAvailabilityData = function (_a, _b) {
        var patchState = _a.patchState;
        var availabilityData = _b.availabilityData;
        patchState({
            allMaintenancePoints: availabilityData[0]
        });
    };
    AvailabilityState.prototype.getSelectedPointEvents = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            selectedPointEvents: __assign(__assign({}, state.selectedPointEvents), { loading: true })
        });
    };
    AvailabilityState.prototype.resetSelectedPointEvents = function (_a) {
        var patchState = _a.patchState;
        patchState({
            selectedPointEvents: { data: undefined, loaded: false, loading: false }
        });
    };
    AvailabilityState.prototype.getSelectedPointEventsSuccess = function (_a, _b) {
        var patchState = _a.patchState;
        var selectedPointEvents = _b.selectedPointEvents, vipPointMarketAreasIds = _b.vipPointMarketAreasIds;
        var events = (selectedPointEvents === null || selectedPointEvents === void 0 ? void 0 : selectedPointEvents.events.length) ? selectedPointEvents.events : [];
        var eventsDetailsWithRelatedMAIds = map(events, function (eventsDetails) { return (__assign(__assign({}, eventsDetails), { marketAreasIds: vipPointMarketAreasIds, virtualPointId: selectedPointEvents.virtualPointId })); });
        patchState({ selectedPointEvents: { data: eventsDetailsWithRelatedMAIds, loaded: true, loading: false } });
    };
    AvailabilityState.prototype.getSelectedPointEventsFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            selectedPointEvents: __assign(__assign({}, state.selectedPointEvents), { loaded: false, loading: false })
        });
    };
    AvailabilityState.prototype.updateSelectedPointEventsFilter = function (_a, _b) {
        var patchState = _a.patchState;
        var eventsWidgetFilter = _b.eventsWidgetFilter;
        patchState({ eventsWidgetFilter: eventsWidgetFilter });
    };
    AvailabilityState.prototype.getSelectedNorway = function (_a, _b) {
        var patchState = _a.patchState;
        var selectedPointEvents = _b.selectedPointEvents, vipPointMarketAreasIds = _b.vipPointMarketAreasIds;
        var mappedNorwayEvents = flatten(selectedPointEvents.map(function (pointEvent) {
            return pointEvent.events
                .map(function (event) { return (__assign(__assign({}, event), { marketAreasIds: vipPointMarketAreasIds, virtualPointId: pointEvent.virtualPointId })); })
                .filter(function (norwayEvent) { return norwayEvent.dataSourceId === norwayDataSourceId; });
        }));
        patchState({ selectedPointEvents: { data: mappedNorwayEvents, loaded: true, loading: false } });
    };
    AvailabilityState.prototype.getSelectedPEGSuperPointsEvents = function (_a, _b) {
        var patchState = _a.patchState;
        var selectedPointEvents = _b.selectedPointEvents, vipPointMarketAreasIds = _b.vipPointMarketAreasIds;
        var mappedNorwayEvents = flatten(selectedPointEvents.map(function (pointEvent) {
            return pointEvent.events.map(function (event) { return (__assign(__assign({}, event), { marketAreasIds: vipPointMarketAreasIds, virtualPointId: pointEvent.virtualPointId })); });
        }));
        patchState({ selectedPointEvents: { data: mappedNorwayEvents, loaded: true, loading: false } });
    };
    AvailabilityState.prototype.getSelectedRoute = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            selectedRouteEvents: __assign(__assign({}, state.selectedRouteEvents), { loading: true })
        });
    };
    AvailabilityState.prototype.resetSelectedRoute = function (_a) {
        var patchState = _a.patchState;
        patchState({
            selectedRouteEvents: { data: [], loaded: false, loading: false }
        });
    };
    AvailabilityState.prototype.getSelectedRouteSuccess = function (_a, _b) {
        var patchState = _a.patchState;
        var selectedRouteEvents = _b.selectedRouteEvents;
        var mappedRouteEvents = flatten(selectedRouteEvents.map(function (routeEvent) {
            var eventsWithMaintenance = routeEvent.events.map(function (event) { return (__assign(__assign({}, event), { virtualPointId: routeEvent.virtualPointId })); });
            var eventsWithOutMaintenance = routeEvent.withoutMaintenances.map(function (event) { return (__assign(__assign({}, event), { virtualPointId: routeEvent.virtualPointId })); });
            return __spreadArrays(eventsWithMaintenance, eventsWithOutMaintenance);
        }));
        patchState({ selectedRouteEvents: { data: mappedRouteEvents, loaded: true, loading: false } });
    };
    AvailabilityState.prototype.getSelectedRouteFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            selectedRouteEvents: __assign(__assign({}, state.selectedRouteEvents), { loaded: false, loading: false })
        });
    };
    AvailabilityState.prototype.getRelatedPointsLoading = function (ctx, _a) {
        var _b;
        var pointId = _a.pointId;
        var state = ctx.getState();
        var updatedPoints = __assign(__assign({}, state.generalInfo.relatedPoints), (_b = {}, _b[pointId] = { data: undefined, loaded: false, loading: true }, _b));
        ctx.patchState({
            generalInfo: __assign(__assign({}, state.generalInfo), { relatedPoints: updatedPoints })
        });
    };
    AvailabilityState.prototype.getRelatedPoints = function (ctx, _a) {
        var _b;
        var relatedPoints = _a.relatedPoints, pointId = _a.pointId;
        var state = ctx.getState();
        var updatedPoints = __assign(__assign({}, state.generalInfo.relatedPoints), (_b = {}, _b[pointId] = { data: relatedPoints.pointMaintenances, loaded: true, loading: false }, _b));
        ctx.patchState({
            generalInfo: __assign(__assign({}, state.generalInfo), { relatedPoints: updatedPoints })
        });
    };
    AvailabilityState.prototype.getEventDetailsLoading = function (ctx, _a) {
        var _b;
        var pointId = _a.pointId;
        var state = ctx.getState();
        var updatedData = __assign(__assign({}, state.generalInfo.eventDetails), (_b = {}, _b[pointId] = { data: undefined, loaded: false, loading: true }, _b));
        ctx.patchState({
            generalInfo: __assign(__assign({}, state.generalInfo), { eventDetails: updatedData })
        });
    };
    AvailabilityState.prototype.getEventDetails = function (ctx, _a) {
        var _b;
        var eventDetails = _a.eventDetails, pointId = _a.pointId;
        var state = ctx.getState();
        var updatedPoints = __assign(__assign({}, state.generalInfo.eventDetails), (_b = {}, _b[pointId] = { data: eventDetails, loaded: true, loading: false }, _b));
        ctx.patchState({
            generalInfo: __assign(__assign({}, state.generalInfo), { eventDetails: updatedPoints })
        });
    };
    AvailabilityState.prototype.getOtherEventsLoading = function (ctx, _a) {
        var _b;
        var pointId = _a.pointId;
        var state = ctx.getState();
        var updatedData = __assign(__assign({}, state.generalInfo.otherEvents), (_b = {}, _b[pointId] = { data: undefined, loaded: false, loading: true }, _b));
        ctx.patchState({
            generalInfo: __assign(__assign({}, state.generalInfo), { otherEvents: updatedData })
        });
    };
    AvailabilityState.prototype.getOtherEvents = function (ctx, _a) {
        var _b;
        var otherEvents = _a.otherEvents, pointId = _a.pointId;
        var state = ctx.getState();
        var updatedEvents = __assign(__assign({}, state.generalInfo.otherEvents), (_b = {}, _b[pointId] = { data: otherEvents, loaded: true, loading: false }, _b));
        ctx.patchState({
            generalInfo: __assign(__assign({}, state.generalInfo), { otherEvents: updatedEvents })
        });
    };
    AvailabilityState.prototype.getImpactChartDataLoading = function (ctx, _a) {
        var _b;
        var pointId = _a.pointId;
        var state = ctx.getState();
        var updatedData = __assign(__assign({}, state.generalInfo.impactChartData), (_b = {}, _b[pointId] = { data: undefined, loaded: false, loading: true }, _b));
        ctx.patchState({
            generalInfo: __assign(__assign({}, state.generalInfo), { impactChartData: updatedData })
        });
    };
    AvailabilityState.prototype.getImpactChartData = function (ctx, _a) {
        var _b;
        var impactChartData = _a.impactChartData, pointId = _a.pointId;
        var state = ctx.getState();
        var updatedData = __assign(__assign({}, state.generalInfo.impactChartData), (_b = {}, _b[pointId] = { data: impactChartData, loaded: true, loading: false }, _b));
        ctx.patchState({
            generalInfo: __assign(__assign({}, state.generalInfo), { impactChartData: updatedData })
        });
    };
    AvailabilityState.prototype.addPointToRoute = function (ctx, _a) {
        var virtualPoint = _a.virtualPoint;
        var state = ctx.getState();
        var pointExist = state.selectedRoutePoints.some(function (routePoint) { return routePoint.appyGasId === virtualPoint.appyGasId; });
        if (!pointExist) {
            ctx.patchState({
                selectedRoutePoints: __spreadArrays(state.selectedRoutePoints, [virtualPoint])
            });
        }
        else {
            ctx.patchState({
                selectedRoutePoints: state.selectedRoutePoints.filter(function (routePoint) { return routePoint.appyGasId !== virtualPoint.appyGasId; })
            });
        }
    };
    AvailabilityState.prototype.resetRoutePoints = function (_a) {
        var patchState = _a.patchState;
        patchState({
            selectedRoutePoints: []
        });
    };
    AvailabilityState.prototype.updateMaintenanceFilters = function (_a, _b) {
        var patchState = _a.patchState;
        var maintenanceFilters = _b.maintenanceFilters;
        patchState({
            maintenanceFilters: maintenanceFilters
        });
    };
    AvailabilityState.prototype.changePointTypes = function (_a, _b) {
        var patchState = _a.patchState;
        var pointsSwitcher = _b.pointsSwitcher;
        patchState({
            pointsSwitcher: pointsSwitcher
        });
    };
    AvailabilityState.prototype.updateGasDayRange = function (_a, _b) {
        var patchState = _a.patchState;
        var gasDayRange = _b.gasDayRange;
        patchState({
            gasDayRange: gasDayRange
        });
    };
    AvailabilityState.prototype.updateWidgetsVisibilityState = function (ctx, _a) {
        var selectedWidget = _a.selectedWidget;
        var state = ctx.getState();
        ctx.patchState({
            widgetsVisibilityState: mapValues(state.widgetsVisibilityState, function (widget) {
                var hidden = widget.id === selectedWidget.id ? selectedWidget.hidden : true;
                return __assign(__assign({}, widget), { hidden: hidden });
            })
        });
    };
    AvailabilityState.prototype.getActiveMATabType = function (_a, _b) {
        var patchState = _a.patchState;
        var activeMATabType = _b.activeMATabType;
        patchState({
            activeMATabType: activeMATabType
        });
    };
    AvailabilityState.prototype.SetSelectedMapPointId = function (_a, _b) {
        var patchState = _a.patchState;
        var selectedMapPointId = _b.selectedMapPointId;
        patchState({ selectedMapPointId: selectedMapPointId });
    };
    AvailabilityState.prototype.setNavigatedFromExternalPageStatus = function (_a, _b) {
        var patchState = _a.patchState;
        var isNavigatedFromExternalPage = _b.isNavigatedFromExternalPage;
        patchState({ isNavigatedFromExternalPage: isNavigatedFromExternalPage });
    };
    AvailabilityState.prototype.setNavigatedFromGeneralInfoPageStatus = function (_a, _b) {
        var patchState = _a.patchState;
        var isNavigatedFromGeneralInfoPage = _b.isNavigatedFromGeneralInfoPage;
        patchState({ isNavigatedFromGeneralInfoPage: isNavigatedFromGeneralInfoPage });
    };
    AvailabilityState.prototype.setNorwayPointSelectedStatus = function (_a, _b) {
        var patchState = _a.patchState;
        var isNorwayPointSelected = _b.isNorwayPointSelected;
        patchState({ isNorwayPointSelected: isNorwayPointSelected });
    };
    __decorate([
        Action(GetAllMaintenancePoints),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetAllMaintenancePoints]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getAllMaintenancePoints", null);
    __decorate([
        Action(GetAllEventPoints),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getEventPoints", null);
    __decorate([
        Action(GetAllEventPointsSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetAllEventPointsSuccess]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getEventPointsSuccess", null);
    __decorate([
        Action(GetAllEventPointsFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getEventPointsFailed", null);
    __decorate([
        Action(GetPortfolioMaintenancePoints),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getPortfolioMaintenancePoints", null);
    __decorate([
        Action(ResetPortfolioMaintenancePoints),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "resetPortfolioMaintenancePoints", null);
    __decorate([
        Action(GetPortfolioMaintenancePointsSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetPortfolioMaintenancePointsSuccess]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getPortfolioMaintenancePointsSuccess", null);
    __decorate([
        Action(GetPortfolioMaintenancePointsFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getPortfolioMaintenancePointsFailed", null);
    __decorate([
        Action(GetAvailabilityData),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetAvailabilityData]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getAvailabilityData", null);
    __decorate([
        Action(GetSelectedPointEvents),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getSelectedPointEvents", null);
    __decorate([
        Action(ResetSelectedPointEvents),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "resetSelectedPointEvents", null);
    __decorate([
        Action(GetSelectedPointEventsSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetSelectedPointEventsSuccess]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getSelectedPointEventsSuccess", null);
    __decorate([
        Action(GetSelectedPointEventsFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getSelectedPointEventsFailed", null);
    __decorate([
        Action(UpdateSelectedPointEventsFilter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, UpdateSelectedPointEventsFilter]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "updateSelectedPointEventsFilter", null);
    __decorate([
        Action(GetSelectedNorwayEvents),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetSelectedNorwayEvents]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getSelectedNorway", null);
    __decorate([
        Action(GetSelectedPEGSuperPointsEvents),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetSelectedPEGSuperPointsEvents]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getSelectedPEGSuperPointsEvents", null);
    __decorate([
        Action(GetSelectedRouteDetails),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getSelectedRoute", null);
    __decorate([
        Action(ResetSelectedRouteDetails),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "resetSelectedRoute", null);
    __decorate([
        Action(GetSelectedRouteDetailsSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetSelectedRouteDetailsSuccess]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getSelectedRouteSuccess", null);
    __decorate([
        Action(GetSelectedRouteDetailsFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getSelectedRouteFailed", null);
    __decorate([
        Action(GetRelatedPointsLoading),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetRelatedPointsLoading]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getRelatedPointsLoading", null);
    __decorate([
        Action(GetRelatedPoints),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetRelatedPoints]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getRelatedPoints", null);
    __decorate([
        Action(GetEventDetailsLoading),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetEventDetailsLoading]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getEventDetailsLoading", null);
    __decorate([
        Action(GetEventDetails),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetEventDetails]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getEventDetails", null);
    __decorate([
        Action(GetOtherEventsLoading),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetOtherEventsLoading]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getOtherEventsLoading", null);
    __decorate([
        Action(GetOtherEvents),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetOtherEvents]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getOtherEvents", null);
    __decorate([
        Action(GetImpactChartDataLoading),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetImpactChartDataLoading]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getImpactChartDataLoading", null);
    __decorate([
        Action(GetImpactChartData),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetImpactChartData]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getImpactChartData", null);
    __decorate([
        Action(TogglePointInRoute),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, TogglePointInRoute]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "addPointToRoute", null);
    __decorate([
        Action(ResetSelectedRoutePoints),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "resetRoutePoints", null);
    __decorate([
        Action(UpdateMaintenanceFilters),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, UpdateMaintenanceFilters]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "updateMaintenanceFilters", null);
    __decorate([
        Action(ChangeAvailabilityPointTypes),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, ChangeAvailabilityPointTypes]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "changePointTypes", null);
    __decorate([
        Action(UpdateGasDayRange),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, UpdateGasDayRange]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "updateGasDayRange", null);
    __decorate([
        Action(UpdateWidgetsVisibilityState),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, UpdateWidgetsVisibilityState]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "updateWidgetsVisibilityState", null);
    __decorate([
        Action(UpdateActiveMATabType),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, UpdateActiveMATabType]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "getActiveMATabType", null);
    __decorate([
        Action(SetSelectedMapPointId),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetSelectedMapPointId]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "SetSelectedMapPointId", null);
    __decorate([
        Action(SetNavigatedFromExternalPageStatus),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetNavigatedFromExternalPageStatus]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "setNavigatedFromExternalPageStatus", null);
    __decorate([
        Action(SetNavigatedFromGeneralInfoPageStatus),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetNavigatedFromGeneralInfoPageStatus]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "setNavigatedFromGeneralInfoPageStatus", null);
    __decorate([
        Action(SetNorwayPointSelectedStatus),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetNorwayPointSelectedStatus]),
        __metadata("design:returntype", void 0)
    ], AvailabilityState.prototype, "setNorwayPointSelectedStatus", null);
    AvailabilityState = __decorate([
        State({
            name: 'availability',
            defaults: {
                allMaintenancePoints: [],
                allEventPoints: {
                    data: ɵ0,
                    loading: false,
                    loaded: false
                },
                portfolioMaintenancePoints: {
                    data: ɵ1,
                    loading: false,
                    loaded: false
                },
                selectedPointEvents: {
                    data: ɵ2,
                    loading: false,
                    loaded: false
                },
                selectedMapPointId: undefined,
                eventsWidgetFilter: undefined,
                pointsSwitcher: UserPointTypesEnum.ALL_POINTS,
                gasDayRange: {
                    timeFrom: undefined,
                    timeTo: undefined
                },
                selectedRouteEvents: {
                    data: ɵ3,
                    loading: false,
                    loaded: false
                },
                selectedRoutePoints: [],
                maintenanceFilters: {
                    sso: true,
                    tso: true,
                    firm: true,
                    interruptible: false,
                    impact: ImpactRadioTypes.ALL
                },
                generalInfo: {
                    relatedPoints: undefined,
                    eventDetails: undefined,
                    otherEvents: undefined,
                    impactChartData: undefined
                },
                widgetsVisibilityState: (_a = {},
                    _a[WidgetTypesEnum.GAS_PERIOD_CALENDAR] = { id: WidgetTypesEnum.GAS_PERIOD_CALENDAR, hidden: true },
                    _a[WidgetTypesEnum.EVENTS] = { id: WidgetTypesEnum.EVENTS, hidden: true },
                    _a[WidgetTypesEnum.PICK_A_ROUTE] = { id: WidgetTypesEnum.PICK_A_ROUTE, hidden: true },
                    _a[WidgetTypesEnum.FILTERS] = { id: WidgetTypesEnum.FILTERS, hidden: true },
                    _a[WidgetTypesEnum.LEGEND] = { id: WidgetTypesEnum.LEGEND, hidden: false },
                    _a),
                activeMATabType: MAEventsTabDirection.ENTRY_DIRECTION,
                isNavigatedFromExternalPage: false,
                isNavigatedFromGeneralInfoPage: false,
                isNorwayPointSelected: false
            }
        })
    ], AvailabilityState);
    return AvailabilityState;
}());
export { AvailabilityState };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
