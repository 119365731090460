import { BehaviorSubject } from 'rxjs';
var AuthModalHolderService = /** @class */ /*@__PURE__*/ (function () {
    function AuthModalHolderService() {
        this.currentModalConfig = new BehaviorSubject(null);
        this.onPut = this.currentModalConfig.asObservable();
    }
    AuthModalHolderService.prototype.put = function (modalType, config) {
        if (config === void 0) {
            config = {};
        }
        this.currentModalConfig.next({ type: modalType, config: config });
    };
    return AuthModalHolderService;
}());
export { AuthModalHolderService };
