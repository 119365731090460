import { __decorate, __metadata } from "tslib";
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetEePoints, GetOperators } from './common-entities.actions';
import { CommonEntitiesSelectors } from './common-entities.selectors';
var CommonEntitiesFacade = /** @class */ /*@__PURE__*/ (function () {
    function CommonEntitiesFacade(commonEntitiesService) {
        this.commonEntitiesService = commonEntitiesService;
    }
    CommonEntitiesFacade.prototype.getEeVirtualPoints = function (dateTime) {
        return this.commonEntitiesService.getVirtualPointsActive(dateTime).pipe(map(function (data) { return new GetEePoints(data); }));
    };
    CommonEntitiesFacade.prototype.getOperators = function () {
        return this.commonEntitiesService.loadOperators().pipe(map(function (operators) { return new GetOperators(operators); }));
    };
    __decorate([
        Select(CommonEntitiesSelectors.operators),
        __metadata("design:type", Observable)
    ], CommonEntitiesFacade.prototype, "operators$", void 0);
    __decorate([
        Select(CommonEntitiesSelectors.eePoints),
        __metadata("design:type", Observable)
    ], CommonEntitiesFacade.prototype, "eePoints$", void 0);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Observable)
    ], CommonEntitiesFacade.prototype, "getEeVirtualPoints", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], CommonEntitiesFacade.prototype, "getOperators", null);
    return CommonEntitiesFacade;
}());
export { CommonEntitiesFacade };
