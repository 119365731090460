/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "./user-deactivated.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/appy-gas-core/dist/components/button/button.component.ngfactory";
import * as i3 from "appy-gas-core/dist/components/button/button.component";
import * as i4 from "./user-deactivated.component";
import * as i5 from "appy-gas-core/dist/components/modal/modal.service";
import * as i6 from "@angular/router";
var styles_UserDeactivatedComponent = [i0.styles];
var RenderType_UserDeactivatedComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_UserDeactivatedComponent, data: {} });
export { RenderType_UserDeactivatedComponent as RenderType_UserDeactivatedComponent };
export function View_UserDeactivatedComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "user-deactivated"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "user-deactivated__header-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "user-deactivated__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The account with the specified email address was deactivated"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "user-deactivated__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" There is no possibility to sign up repeatedly with the same email address after account deactivation. Please contact appygas team for more information. "])), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "user-deactivated__controls-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["appyGasButton", ""]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.contact() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(8, 638976, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i1.ɵted(-1, 0, [" Contact appygas "])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["appyGasButton", ""]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.goToHome() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(11, 638976, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i1.ɵted(-1, 0, [" Go to homepage "]))], function (_ck, _v) { var currVal_0 = "primary"; _ck(_v, 8, 0, currVal_0); var currVal_1 = "secondary"; _ck(_v, 11, 0, currVal_1); }, null);
}
export function View_UserDeactivatedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "appy-gas-user-deactivated", [], null, null, null, View_UserDeactivatedComponent_0, RenderType_UserDeactivatedComponent)), i1.ɵdid(1, 49152, null, 0, i4.UserDeactivatedComponent, [i5.ModalService, i6.Router], null, null)], null, null); }
var UserDeactivatedComponentNgFactory = /*@__PURE__*/ i1.ɵccf("appy-gas-user-deactivated", i4.UserDeactivatedComponent, View_UserDeactivatedComponent_Host_0, {}, {}, []);
export { UserDeactivatedComponentNgFactory as UserDeactivatedComponentNgFactory };
