/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "../user-transition-modals.scss.shim.ngstyle";
import * as i1 from "./access-expired.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/forms";
import * as i4 from "../../../../../../node_modules/appy-gas-core/dist/components/checkbox/checkbox.component.ngfactory";
import * as i5 from "appy-gas-core/dist/components/checkbox/checkbox.component";
import * as i6 from "../../../../../../node_modules/appy-gas-core/dist/components/button/button.component.ngfactory";
import * as i7 from "appy-gas-core/dist/components/button/button.component";
import * as i8 from "./access-expired.component";
import * as i9 from "appy-gas-core/dist/components/modal/modal.service";
import * as i10 from "@angular/router";
var styles_AccessExpiredComponent = [i0.styles, i1.styles];
var RenderType_AccessExpiredComponent = /*@__PURE__*/ i2.ɵcrt({ encapsulation: 0, styles: styles_AccessExpiredComponent, data: {} });
export { RenderType_AccessExpiredComponent as RenderType_AccessExpiredComponent };
export function View_AccessExpiredComponent_0(_l) {
    return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 25, "div", [["class", "transition-modal"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "div", [["class", "transition-modal__header"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Your access has expired"])), (_l()(), i2.ɵeld(3, 0, null, null, 22, "div", [["class", "transition-modal__wrapper"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 15, "div", [["class", "transition-modal__description-block"]], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 13, "div", [["class", "transition-modal__description"]], null, null, null, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" We hope you enjoyed my.appygas. To continue using my.appygas, either contact our sales team or insert a promo code. You can still access your profile page at any time and modify your personal settings. "])), (_l()(), i2.ɵeld(8, 0, null, null, 10, "form", [["class", "transition-modal__checkbox-form"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) {
            var ad = true;
            if (("submit" === en)) {
                var pd_0 = (i2.ɵnov(_v, 10).onSubmit($event) !== false);
                ad = (pd_0 && ad);
            }
            if (("reset" === en)) {
                var pd_1 = (i2.ɵnov(_v, 10).onReset() !== false);
                ad = (pd_1 && ad);
            }
            return ad;
        }, null, null)), i2.ɵdid(9, 16384, null, 0, i3.ɵangular_packages_forms_forms_ba, [], null, null), i2.ɵdid(10, 671744, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i2.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i2.ɵdid(12, 16384, null, 0, i3.NgControlStatusGroup, [[6, i3.ControlContainer]], null, null), (_l()(), i2.ɵeld(13, 0, null, null, 5, "appy-gas-checkbox", [["formControlName", "signUpNewsLetter"], ["label", "Keep me informed about all product developments, commercial offers and news of appygas"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], null, null, i4.View_CheckboxComponent_0, i4.RenderType_CheckboxComponent)), i2.ɵdid(14, 49152, null, 0, i5.CheckboxComponent, [i2.ChangeDetectorRef], { binary: [0, "binary"], label: [1, "label"] }, null), i2.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.CheckboxComponent]), i2.ɵdid(16, 671744, null, 0, i3.FormControlName, [[3, i3.ControlContainer], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_r]], { name: [0, "name"] }, null), i2.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlName]), i2.ɵdid(18, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i2.ɵeld(19, 0, null, null, 0, "img", [["class", "transition-modal__description-img"], ["src", "/assets/images/portfolio/user-img.png"]], null, null, null, null, null)), (_l()(), i2.ɵeld(20, 0, null, null, 2, "button", [["appyGasButton", ""], ["buttonType", "secondary"], ["class", "transition-modal__btn-block--cancel-btn"]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.goToMyProfile() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i6.View_ButtonComponent_0, i6.RenderType_ButtonComponent)), i2.ɵdid(21, 638976, null, 0, i7.ButtonComponent, [i2.ElementRef, i2.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i2.ɵted(-1, 0, [" go to my profile "])), (_l()(), i2.ɵeld(23, 0, null, null, 2, "button", [["appyGasButton", ""], ["buttonType", "secondary"], ["class", "ml-3"]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.goToContactUsPage() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i6.View_ButtonComponent_0, i6.RenderType_ButtonComponent)), i2.ɵdid(24, 638976, null, 0, i7.ButtonComponent, [i2.ElementRef, i2.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i2.ɵted(-1, 0, [" CONTACT US "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.newsLetterForm; _ck(_v, 10, 0, currVal_7); var currVal_15 = true; var currVal_16 = "Keep me informed about all product developments, commercial offers and news of appygas"; _ck(_v, 14, 0, currVal_15, currVal_16); var currVal_17 = "signUpNewsLetter"; _ck(_v, 16, 0, currVal_17); var currVal_18 = "secondary"; _ck(_v, 21, 0, currVal_18); var currVal_19 = "secondary"; _ck(_v, 24, 0, currVal_19); }, function (_ck, _v) { var currVal_0 = i2.ɵnov(_v, 12).is("untouched"); var currVal_1 = i2.ɵnov(_v, 12).is("touched"); var currVal_2 = i2.ɵnov(_v, 12).is("pristine"); var currVal_3 = i2.ɵnov(_v, 12).is("dirty"); var currVal_4 = i2.ɵnov(_v, 12).is("valid"); var currVal_5 = i2.ɵnov(_v, 12).is("invalid"); var currVal_6 = i2.ɵnov(_v, 12).is("pending"); _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i2.ɵnov(_v, 18).is("untouched"); var currVal_9 = i2.ɵnov(_v, 18).is("touched"); var currVal_10 = i2.ɵnov(_v, 18).is("pristine"); var currVal_11 = i2.ɵnov(_v, 18).is("dirty"); var currVal_12 = i2.ɵnov(_v, 18).is("valid"); var currVal_13 = i2.ɵnov(_v, 18).is("invalid"); var currVal_14 = i2.ɵnov(_v, 18).is("pending"); _ck(_v, 13, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); });
}
export function View_AccessExpiredComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "appy-gas-access-expired", [], null, null, null, View_AccessExpiredComponent_0, RenderType_AccessExpiredComponent)), i2.ɵdid(1, 245760, null, 0, i8.AccessExpiredComponent, [i9.ModalService, i3.FormBuilder, i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccessExpiredComponentNgFactory = /*@__PURE__*/ i2.ɵccf("appy-gas-access-expired", i8.AccessExpiredComponent, View_AccessExpiredComponent_Host_0, {}, {}, []);
export { AccessExpiredComponentNgFactory as AccessExpiredComponentNgFactory };
