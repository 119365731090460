/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "./promotion-banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./promotion-banner.component";
var styles_PromotionBannerComponent = [i0.styles];
var RenderType_PromotionBannerComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_PromotionBannerComponent, data: {} });
export { RenderType_PromotionBannerComponent as RenderType_PromotionBannerComponent };
export function View_PromotionBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "promotion-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "promotion-banner__info-icon"], ["height", "26px"], ["viewBox", "1 1 14 14"], ["width", "26px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:path", [["d", "M8,2 C4.6859378,2 2,4.6859378 2,8 C2,11.3140628 4.6859378,14 8,14 C11.3140628,14 14,11.3140628 14,8 C14,4.6859378 11.3140628,2 8,2 Z M8.6,11 L7.4,11 L7.4,7.4 L8.6,7.4 L8.6,11 Z M8.6,6.2 L7.4,6.2 L7.4,5 L8.6,5 L8.6,6.2 Z"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["New release deal: Switch to Exclusive for 7 days and get "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "promotion-banner__highlight-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["7 extra days for free"]))], null, null); }
export function View_PromotionBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "appy-gas-promotion-banner", [], null, null, null, View_PromotionBannerComponent_0, RenderType_PromotionBannerComponent)), i1.ɵdid(1, 49152, null, 0, i2.PromotionBannerComponent, [], null, null)], null, null); }
var PromotionBannerComponentNgFactory = /*@__PURE__*/ i1.ɵccf("appy-gas-promotion-banner", i2.PromotionBannerComponent, View_PromotionBannerComponent_Host_0, {}, {}, []);
export { PromotionBannerComponentNgFactory as PromotionBannerComponentNgFactory };
