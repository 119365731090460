/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "./navigate-with-save.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/appy-gas-core/dist/components/button/button.component.ngfactory";
import * as i3 from "appy-gas-core/dist/components/button/button.component";
import * as i4 from "./navigate-with-save.component";
import * as i5 from "appy-gas-core/dist/components/modal/modal.service";
var styles_NavigateWithSaveComponent = [i0.styles];
var RenderType_NavigateWithSaveComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_NavigateWithSaveComponent, data: {} });
export { RenderType_NavigateWithSaveComponent as RenderType_NavigateWithSaveComponent };
export function View_NavigateWithSaveComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "profile-warning"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h3", [["class", "profile-warning__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There are unsaved changes."])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Do you want to save or discard changes?"])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "profile-warning__controls-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["appyGasButton", ""], ["buttonType", "primary"]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.saveChanges() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(7, 638976, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i1.ɵted(-1, 0, ["SAVE"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["appyGasButton", ""], ["buttonType", "secondary"]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.exitWithoutSaving() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(10, 638976, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i1.ɵted(-1, 0, ["EXIT WITHOUT SAVING"]))], function (_ck, _v) { var currVal_0 = "primary"; _ck(_v, 7, 0, currVal_0); var currVal_1 = "secondary"; _ck(_v, 10, 0, currVal_1); }, null);
}
export function View_NavigateWithSaveComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "appy-gas-navigate-with-save", [], null, null, null, View_NavigateWithSaveComponent_0, RenderType_NavigateWithSaveComponent)), i1.ɵdid(1, 49152, null, 0, i4.NavigateWithSaveComponent, [i5.ModalService], null, null)], null, null); }
var NavigateWithSaveComponentNgFactory = /*@__PURE__*/ i1.ɵccf("appy-gas-navigate-with-save", i4.NavigateWithSaveComponent, View_NavigateWithSaveComponent_Host_0, {}, {}, []);
export { NavigateWithSaveComponentNgFactory as NavigateWithSaveComponentNgFactory };
