import { EnvironmentVariables } from 'appy-gas-core';

export const developmentVariables: EnvironmentVariables = {
  environment: 'development',
  serverUrl: 'https://ag-dev-wap-api.azurewebsites.net/api',
  production: false,
  gaTrackingId: 'UA-114902604-1',
  userManagementReleaseDate: new Date(2018, 5, 5),
  technicalMaintenance: false
};
