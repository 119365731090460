import { RestrictedMarketAreaClassicComponent } from './restricted-market-area-classic.component';

export const restrictedMarketAreaClassicConfig = {
  title: '',
  component: {
    name: RestrictedMarketAreaClassicComponent,
    options: {}
  },
  data: {
    width: 700
  }
};
