import { tap } from 'rxjs/operators';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { UserFacadeService } from '../../store';
var CurrentUserResolverService = /** @class */ /*@__PURE__*/ (function () {
    function CurrentUserResolverService(authService, userFacadeService) {
        this.authService = authService;
        this.userFacadeService = userFacadeService;
    }
    CurrentUserResolverService.prototype.resolve = function (route) {
        var _this = this;
        return this.authService.getUser().pipe(tap(function (user) { return _this.userFacadeService.setUserInfo(user); }));
    };
    return CurrentUserResolverService;
}());
export { CurrentUserResolverService };
