import { NavigationEnd, Router } from '@angular/router';
var RouterStateService = /** @class */ /*@__PURE__*/ (function () {
    function RouterStateService(router) {
        var _this = this;
        this.router = router;
        this.previousUrl = undefined;
        this.currentUrl = undefined;
        this.currentUrl = this.router.url;
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                _this.previousUrl = _this.currentUrl;
                _this.currentUrl = event.url;
            }
        });
    }
    RouterStateService.prototype.getPreviousUrl = function () {
        return this.previousUrl;
    };
    return RouterStateService;
}());
export { RouterStateService };
