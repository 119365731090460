import { __assign } from "tslib";
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import { DirectionIdTypeEnum, IpPhysicalPointVariablesEnum, LNGPhysicalPointVariablesEnum, StoragePhysicalPointVariablesEnum } from '../enums';
import * as i0 from "@angular/core";
var FlowsMapHelperService = /** @class */ /*@__PURE__*/ (function () {
    function FlowsMapHelperService() {
    }
    FlowsMapHelperService.getStorageAggregatedFields = function (storagePoint) {
        var maxInjectionRate = storagePoint.values.find(function (value) { return value.eePointVariableId === StoragePhysicalPointVariablesEnum.TECHNICAL_ENTRY; });
        var injectionNom = storagePoint.values.find(function (value) { return value.eePointVariableId === StoragePhysicalPointVariablesEnum.CLEAN_NOM_ENTRY; });
        var maxWithdrawalRate = storagePoint.values.find(function (value) { return value.eePointVariableId === StoragePhysicalPointVariablesEnum.TECHNICAL_EXIT; });
        var withdrawalNom = storagePoint.values.find(function (value) { return value.eePointVariableId === StoragePhysicalPointVariablesEnum.CLEAN_NOM_EXIT; });
        var gasInStorage = storagePoint.values.find(function (value) { return value.eePointVariableId === StoragePhysicalPointVariablesEnum.VOLUME_STORED; });
        var workingGasVolume = storagePoint.values.find(function (value) { return value.eePointVariableId === StoragePhysicalPointVariablesEnum.VOLUME_MAX; });
        return __assign(__assign({}, storagePoint), { fillingLevel: FlowsMapHelperService.getFillingCalculations(gasInStorage, workingGasVolume), usageRateEntry: FlowsMapHelperService.getFillingCalculations(injectionNom, maxInjectionRate), usageRateExit: FlowsMapHelperService.getFillingCalculations(withdrawalNom, maxWithdrawalRate) });
    };
    FlowsMapHelperService.getLNGAggregatedFields = function (LNGPoint) {
        var sendOutCapacity = LNGPoint.values.find(function (value) { return value.eePointVariableId === LNGPhysicalPointVariablesEnum.TECHNICAL_ENTRY; });
        var storageCapacity = LNGPoint.values.find(function (value) { return value.eePointVariableId === LNGPhysicalPointVariablesEnum.VOLUME_MAX; });
        var nominationEntry = LNGPoint.values.find(function (value) { return value.eePointVariableId === LNGPhysicalPointVariablesEnum.CLEAN_NOM_ENTRY; });
        var volumeStored = LNGPoint.values.find(function (value) { return value.eePointVariableId === LNGPhysicalPointVariablesEnum.VOLUME_STORED; });
        return __assign(__assign({}, LNGPoint), { sendOutRate: FlowsMapHelperService.getFillingCalculations(nominationEntry, sendOutCapacity), fillingLevel: FlowsMapHelperService.getFillingCalculations(volumeStored, storageCapacity) });
    };
    FlowsMapHelperService.getIPAggregatedFields = function (IPPoint) {
        var physicalFlow = IPPoint.values.find(function (value) { return value.eePointVariableId === IpPhysicalPointVariablesEnum.PHYSICAL_FLOW; });
        var nominationEntry = IPPoint.values.find(function (value) { return value.eePointVariableId === IpPhysicalPointVariablesEnum.CLEAN_NOM_ENTRY; });
        var nominationExit = IPPoint.values.find(function (value) { return value.eePointVariableId === IpPhysicalPointVariablesEnum.CLEAN_NOM_EXIT; });
        var tacEntry = IPPoint.values.find(function (value) { return value.eePointVariableId === IpPhysicalPointVariablesEnum.TECHNICAL_ENTRY; });
        var tacExit = IPPoint.values.find(function (value) { return value.eePointVariableId === IpPhysicalPointVariablesEnum.TECHNICAL_EXIT; });
        var bookedFirmEntry = IPPoint.values.find(function (value) { return value.eePointVariableId === IpPhysicalPointVariablesEnum.BOOKED_ALL_FIRM_CAPACITY_ENTRY; });
        var bookedFirmExit = IPPoint.values.find(function (value) { return value.eePointVariableId === IpPhysicalPointVariablesEnum.BOOKED_ALL_FIRM_CAPACITY_EXIT; });
        var getUsageRateBookedFirm = function (numValEntry, numValExit) {
            if (!physicalFlow) {
                return null;
            }
            if (physicalFlow.value > 0) {
                return FlowsMapHelperService.getFillingCalculations(numValEntry, tacEntry);
            }
            else {
                return FlowsMapHelperService.getFillingCalculations(numValExit, tacExit);
            }
        };
        var getDirection = function () {
            if (!physicalFlow) {
                return null;
            }
            return (physicalFlow === null || physicalFlow === void 0 ? void 0 : physicalFlow.value) > 0 ? DirectionIdTypeEnum.ENTRY : DirectionIdTypeEnum.EXIT;
        };
        return __assign(__assign({}, IPPoint), { direction: getDirection(), usageRate: getUsageRateBookedFirm(nominationEntry, nominationExit), statusBookedFirm: getUsageRateBookedFirm(bookedFirmEntry, bookedFirmExit) });
    };
    FlowsMapHelperService.getFillingCalculations = function (numValue, denomValue) {
        var calcResult;
        if (!isNil(numValue) && !isNil(denomValue) && (denomValue === null || denomValue === void 0 ? void 0 : denomValue.value) !== 0 && (numValue === null || numValue === void 0 ? void 0 : numValue.value) !== 0) {
            calcResult = round((numValue.value / denomValue.value) * 100, 0);
        }
        else if (!isNil(numValue) && !isNil(denomValue) && (denomValue === null || denomValue === void 0 ? void 0 : denomValue.value) === 0 && (numValue === null || numValue === void 0 ? void 0 : numValue.value) === 0) {
            calcResult = 0;
        }
        else {
            calcResult = null;
        }
        return calcResult && calcResult > 100 ? 100 : calcResult;
    };
    FlowsMapHelperService.ɵprov = i0.ɵɵdefineInjectable({ factory: function FlowsMapHelperService_Factory() { return new FlowsMapHelperService(); }, token: FlowsMapHelperService, providedIn: "root" });
    return FlowsMapHelperService;
}());
export { FlowsMapHelperService };
