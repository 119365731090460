import { ModalService } from 'appy-gas-core/dist';
var MaxSearchesComponent = /** @class */ /*@__PURE__*/ (function () {
    function MaxSearchesComponent(modalService) {
        this.modalService = modalService;
    }
    MaxSearchesComponent.prototype.hideModal = function () {
        this.modalService.hide();
    };
    MaxSearchesComponent.prototype.switchToExclusive = function () {
        this.modalService.hide();
        this.proceedToNextStep();
    };
    return MaxSearchesComponent;
}());
export { MaxSearchesComponent };
