import { ErrorHandler } from '@angular/core';
import { ApiUsersService, EnvVariables, SpinnerService } from 'appy-gas-core';
import { CommonEntitiesService } from './common-entities/common-entities.service';
import { CookiesPopoverService } from './cookies-popover';
import { AuthenticationGuard, AuthenticationService, CurrentUserResolverService, EmptyPropGuard } from './http-auth';
import { httpInterceptorProviders } from './http-auth/http-interceptors';
import { RequestCache, RequestCacheWithMap } from './http-auth/request-cache.service';
import { RoleNavigationHandlerService } from './role-navigation-handler/role-navigation-handler.service';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './rollbar';
import { RouteChangeHandlerService, RouterStateService } from './router';
import { CommonEntitiesFacade } from '../store/common-entities';
var ɵ0 = rollbarFactory;
var PROVIDERS = [
    AuthenticationService,
    CurrentUserResolverService,
    AuthenticationGuard,
    SpinnerService,
    CookiesPopoverService,
    RouteChangeHandlerService,
    RouterStateService,
    RollbarErrorHandler,
    EmptyPropGuard,
    ApiUsersService,
    CommonEntitiesService,
    CommonEntitiesFacade,
    RoleNavigationHandlerService,
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, deps: [EnvVariables], useFactory: ɵ0 },
    { provide: RequestCache, useClass: RequestCacheWithMap },
    httpInterceptorProviders
];
var CoreModule = /** @class */ /*@__PURE__*/ (function () {
    function CoreModule(parentModule) {
        // Import guard
        if (parentModule) {
            throw new Error(parentModule + " has already been loaded. Import Core module in the AppModule only.");
        }
    }
    return CoreModule;
}());
export { CoreModule };
export { ɵ0 };
