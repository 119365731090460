import { ModalService } from 'appy-gas-core/dist';
var NavigateWithSaveComponent = /** @class */ /*@__PURE__*/ (function () {
    function NavigateWithSaveComponent(modalService) {
        this.modalService = modalService;
    }
    NavigateWithSaveComponent.prototype.exitWithoutSaving = function () {
        this.modalService.methods.navigateWithoutSaving();
    };
    NavigateWithSaveComponent.prototype.saveChanges = function () {
        this.modalService.methods.navigateWithSaving();
    };
    return NavigateWithSaveComponent;
}());
export { NavigateWithSaveComponent };
