import { Role } from 'appy-gas-core';

export const generalizedMappedUserRoles = new Map<number, string>([
  [Role.Appygas, 'Appygas'],
  [Role.Trial, 'Trial'],
  [Role.Premium, 'Premium'],
  [Role.PremiumPlus, 'Premium+'],
  [Role.Classic, 'Classic'],
  [Role.Exclusive, 'Exclusive'],
  [Role.PreClassic, 'Classic Only'],
  [Role.TrialClassic, 'Trial Classic'],
  [Role.TrialExclusive, 'Trial Exclusive'],
  [Role.PromoExclusive, 'Promo Exclusive'],
  [Role.Expired, 'Expired'],
  [Role.PromoClassic, 'Promo Classic'],
  [Role.PromoTrialExclusive, 'Promo Exclusive']
]);
