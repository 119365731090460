/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "../user-transition-modals.scss.shim.ngstyle";
import * as i1 from "./switch-to-exclusive-info.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../../../../../../node_modules/appy-gas-core/dist/components/button/button.component.ngfactory";
import * as i4 from "appy-gas-core/dist/components/button/button.component";
import * as i5 from "./switch-to-exclusive-info.component";
import * as i6 from "appy-gas-core/dist/components/modal/modal.service";
var styles_SwitchToExclusiveInfoComponent = [i0.styles, i1.styles];
var RenderType_SwitchToExclusiveInfoComponent = /*@__PURE__*/ i2.ɵcrt({ encapsulation: 0, styles: styles_SwitchToExclusiveInfoComponent, data: {} });
export { RenderType_SwitchToExclusiveInfoComponent as RenderType_SwitchToExclusiveInfoComponent };
export function View_SwitchToExclusiveInfoComponent_0(_l) {
    return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 11, "div", [["class", "transition-modal"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "div", [["class", "transition-modal__header"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["This feature is available for Exclusive users only"])), (_l()(), i2.ɵeld(3, 0, null, null, 8, "div", [["class", "transition-modal__main-block"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 5, "div", [["class", "transition-modal__left-sidebar"]], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 1, "div", [["class", "transition-modal__description"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" Please contact your company administrator to change your plan. "])), (_l()(), i2.ɵeld(7, 0, null, null, 2, "button", [["appyGasButton", ""], ["buttonType", "secondary-blue"]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.hideModal() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i2.ɵdid(8, 638976, null, 0, i4.ButtonComponent, [i2.ElementRef, i2.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i2.ɵted(-1, 0, [" OK "])), (_l()(), i2.ɵeld(10, 0, null, null, 1, "div", [["class", "transition-modal__right-sidebar"]], null, null, null, null, null)), (_l()(), i2.ɵeld(11, 0, null, null, 0, "img", [["alt", ""], ["class", "transition-modal__main-img"], ["src", "/assets/images/portfolio/exclusive-only-info.svg"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "secondary-blue"; _ck(_v, 8, 0, currVal_0); }, null);
}
export function View_SwitchToExclusiveInfoComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "appy-gas-switch-to-exclusive-info", [], null, null, null, View_SwitchToExclusiveInfoComponent_0, RenderType_SwitchToExclusiveInfoComponent)), i2.ɵdid(1, 49152, null, 0, i5.SwitchToExclusiveInfoComponent, [i6.ModalService], null, null)], null, null); }
var SwitchToExclusiveInfoComponentNgFactory = /*@__PURE__*/ i2.ɵccf("appy-gas-switch-to-exclusive-info", i5.SwitchToExclusiveInfoComponent, View_SwitchToExclusiveInfoComponent_Host_0, {}, {}, []);
export { SwitchToExclusiveInfoComponentNgFactory as SwitchToExclusiveInfoComponentNgFactory };
