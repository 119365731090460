import { MarketAreaMap } from 'appy-gas-core';

import austriaIcon from '../../../assets/images/flags-icons/austria.svg';
import belgiumIcon from '../../../assets/images/flags-icons/belgium.svg';
import czechIcon from '../../../assets/images/flags-icons/czech.svg';
import franceIcon from '../../../assets/images/flags-icons/france.svg';
import germanyIcon from '../../../assets/images/flags-icons/germany.svg';
import italyIcon from '../../../assets/images/flags-icons/italy.svg';
import netherlandsIcon from '../../../assets/images/flags-icons/netherlands.svg';
import norwayIcon from '../../../assets/images/flags-icons/norway.svg';
import slovakiaIcon from '../../../assets/images/flags-icons/slovakia.svg';
import spainIcon from '../../../assets/images/flags-icons/spain.svg';
import switzIcon from '../../../assets/images/flags-icons/switz.svg';
import ukraineIcon from '../../../assets/images/flags-icons/ukraine.svg';
import unitedKingdomIcon from '../../../assets/images/flags-icons/united-kingdom.svg';
import polandIcon from '../../../assets/images/flags-icons/poland.svg';

export const MarketAreaFlagConfig = {
  [MarketAreaMap.AVTP]: austriaIcon,
  [MarketAreaMap.BELUX_H]: belgiumIcon,
  [MarketAreaMap.BELUX_L]: belgiumIcon,
  [MarketAreaMap.CZECH]: czechIcon,
  [MarketAreaMap.THE_H]: germanyIcon,
  [MarketAreaMap.THE_L]: germanyIcon,
  [MarketAreaMap.INT]: unitedKingdomIcon,
  [MarketAreaMap.NBP]: unitedKingdomIcon,
  [MarketAreaMap.PSV]: italyIcon,
  [MarketAreaMap.PVB]: spainIcon,
  [MarketAreaMap.PEG]: franceIcon,
  [MarketAreaMap.TTF]: netherlandsIcon,
  [MarketAreaMap.SWITZ]: switzIcon,
  [MarketAreaMap.SLOVAKIA]: slovakiaIcon,
  [MarketAreaMap.NORWAY]: norwayIcon,
  [MarketAreaMap.UKRAINE]: ukraineIcon,
  [MarketAreaMap.POLAND_H]: polandIcon,
  [MarketAreaMap.POLAND_L]: polandIcon,
};
