import { ModalService } from 'appy-gas-core';
var TrialExclusiveAccessExpiredComponent = /** @class */ /*@__PURE__*/ (function () {
    function TrialExclusiveAccessExpiredComponent(modalService) {
        this.modalService = modalService;
    }
    TrialExclusiveAccessExpiredComponent.prototype.hideModal = function () {
        this.modalService.hide();
    };
    return TrialExclusiveAccessExpiredComponent;
}());
export { TrialExclusiveAccessExpiredComponent };
