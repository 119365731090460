import { __decorate } from "tslib";
import { State } from '@ngxs/store';
import { AuctionProductPeriod, CurrencyType, PeriodType, QualityType } from 'appy-gas-core';
export var defaultFormState = {
    marketAreas: [],
    quantity: null,
    isAvailable: false,
    period: PeriodType.DA,
    auctionProduct: AuctionProductPeriod.DAILY,
    quality: QualityType.FIRM,
    currency: CurrencyType.Euro
};
var RouteCalculatorState = /** @class */ /*@__PURE__*/ (function () {
    function RouteCalculatorState() {
    }
    RouteCalculatorState = __decorate([
        State({
            name: 'routeCalculator',
            defaults: {
                form: {
                    model: defaultFormState,
                    dirty: false,
                    status: '',
                    errors: {}
                },
                filtersForm: {
                    model: {},
                    dirty: false,
                    status: '',
                    errors: {}
                }
            }
        })
    ], RouteCalculatorState);
    return RouteCalculatorState;
}());
export { RouteCalculatorState };
