import { SwitchToExclusiveDaysComponent } from './switch-to-exclusive-days.component';

export const switchToExclusiveDaysConfig = {
  title: '',
  component: {
    name: SwitchToExclusiveDaysComponent,
    options: {}
  },
  data: {
    width: 670
  }
};
