var _a;
import { __assign, __decorate, __metadata } from "tslib";
import { Action, State } from '@ngxs/store';
import mapValues from 'lodash/mapValues';
import { FlowsMapTabType, VirtualPointType } from 'appy-gas-core';
import { FlowsMapWidgetTypesEnum } from '../../flows-map/interfaces';
import { UserPointTypesEnum } from '../../shared/enums';
import { ChangeFlowsMapPointTypes, ChangePointWidgetPointTypes, GetPhysicalPoints, GetPhysicalPointsFailed, GetPhysicalPointsSuccess, GetVirtualPoints, GetVirtualPointsFailed, GetVirtualPointsSuccess, SetActiveFilterTab, SetSelectedChartPoints, SetSelectedDate, SetSelectedTypesFilter, UpdateFlowsMapWidgetsVisibilityState, UpdateSelectedPointsFilter } from './flows-map.actions';
var ɵ0 = undefined, ɵ1 = undefined;
var FlowsMapState = /** @class */ /*@__PURE__*/ (function () {
    function FlowsMapState() {
    }
    FlowsMapState.prototype.getVirtualPoints = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({ virtualPoints: __assign(__assign({}, state.virtualPoints), { loading: true }) });
    };
    FlowsMapState.prototype.getVirtualPointsSuccess = function (_a, _b) {
        var patchState = _a.patchState;
        var virtualPoints = _b.virtualPoints;
        patchState({ virtualPoints: { data: virtualPoints, loaded: true, loading: false } });
    };
    FlowsMapState.prototype.getVirtualPointsFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({ virtualPoints: __assign(__assign({}, state.virtualPoints), { loaded: false, loading: false }) });
    };
    FlowsMapState.prototype.updateSelectedPointsFilter = function (_a, _b) {
        var patchState = _a.patchState;
        var pointsWidgetFilter = _b.pointsWidgetFilter;
        patchState({ pointsWidgetFilter: pointsWidgetFilter });
    };
    FlowsMapState.prototype.getPhysicalPoints = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({ physicalPoints: __assign(__assign({}, state.physicalPoints), { loading: true }) });
    };
    FlowsMapState.prototype.getPhysicalPointsSuccess = function (_a, _b) {
        var patchState = _a.patchState;
        var physicalPoints = _b.physicalPoints;
        patchState({ physicalPoints: { data: physicalPoints, loaded: true, loading: false } });
    };
    FlowsMapState.prototype.getPhysicalPointsFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({ physicalPoints: __assign(__assign({}, state.physicalPoints), { loaded: false, loading: false }) });
    };
    FlowsMapState.prototype.setSelectedDate = function (_a, _b) {
        var patchState = _a.patchState;
        var selectedDate = _b.selectedDate;
        patchState({ selectedDate: selectedDate });
    };
    FlowsMapState.prototype.setActiveFilterTab = function (_a, _b) {
        var patchState = _a.patchState;
        var activeFilterTab = _b.activeFilterTab;
        patchState({ activeFilterTab: activeFilterTab });
    };
    FlowsMapState.prototype.setSelectedChartPoints = function (_a, _b) {
        var patchState = _a.patchState;
        var selectedChartPoints = _b.selectedChartPoints;
        patchState({ selectedChartPoints: selectedChartPoints });
    };
    FlowsMapState.prototype.setSelectedTypesFilter = function (_a, _b) {
        var patchState = _a.patchState;
        var selectedTypesFilter = _b.selectedTypesFilter;
        patchState({ selectedTypesFilter: selectedTypesFilter });
    };
    FlowsMapState.prototype.updateFlowsMapWidgetsVisibilityState = function (ctx, _a) {
        var selectedWidget = _a.selectedWidget;
        var state = ctx.getState();
        ctx.patchState({
            widgetsVisibilityState: mapValues(state.widgetsVisibilityState, function (widget) {
                var hidden = widget.id === selectedWidget.id ? selectedWidget.hidden : true;
                return __assign(__assign({}, widget), { hidden: hidden });
            })
        });
    };
    FlowsMapState.prototype.getActiveMATabType = function (_a, _b) {
        var patchState = _a.patchState;
        var pointsWidgetTabTypeSwitcher = _b.pointsWidgetTabTypeSwitcher;
        patchState({
            pointsWidgetTabTypeSwitcher: pointsWidgetTabTypeSwitcher
        });
    };
    FlowsMapState.prototype.changePointTypes = function (_a, _b) {
        var patchState = _a.patchState;
        var pointsSwitcher = _b.pointsSwitcher;
        patchState({
            pointsSwitcher: pointsSwitcher
        });
    };
    __decorate([
        Action(GetVirtualPoints),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], FlowsMapState.prototype, "getVirtualPoints", null);
    __decorate([
        Action(GetVirtualPointsSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetVirtualPointsSuccess]),
        __metadata("design:returntype", void 0)
    ], FlowsMapState.prototype, "getVirtualPointsSuccess", null);
    __decorate([
        Action(GetVirtualPointsFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], FlowsMapState.prototype, "getVirtualPointsFailed", null);
    __decorate([
        Action(UpdateSelectedPointsFilter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, UpdateSelectedPointsFilter]),
        __metadata("design:returntype", void 0)
    ], FlowsMapState.prototype, "updateSelectedPointsFilter", null);
    __decorate([
        Action(GetPhysicalPoints),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], FlowsMapState.prototype, "getPhysicalPoints", null);
    __decorate([
        Action(GetPhysicalPointsSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetPhysicalPointsSuccess]),
        __metadata("design:returntype", void 0)
    ], FlowsMapState.prototype, "getPhysicalPointsSuccess", null);
    __decorate([
        Action(GetPhysicalPointsFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], FlowsMapState.prototype, "getPhysicalPointsFailed", null);
    __decorate([
        Action(SetSelectedDate),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetSelectedDate]),
        __metadata("design:returntype", void 0)
    ], FlowsMapState.prototype, "setSelectedDate", null);
    __decorate([
        Action(SetActiveFilterTab),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetActiveFilterTab]),
        __metadata("design:returntype", void 0)
    ], FlowsMapState.prototype, "setActiveFilterTab", null);
    __decorate([
        Action(SetSelectedChartPoints),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetSelectedChartPoints]),
        __metadata("design:returntype", void 0)
    ], FlowsMapState.prototype, "setSelectedChartPoints", null);
    __decorate([
        Action(SetSelectedTypesFilter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetSelectedTypesFilter]),
        __metadata("design:returntype", void 0)
    ], FlowsMapState.prototype, "setSelectedTypesFilter", null);
    __decorate([
        Action(UpdateFlowsMapWidgetsVisibilityState),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, UpdateFlowsMapWidgetsVisibilityState]),
        __metadata("design:returntype", void 0)
    ], FlowsMapState.prototype, "updateFlowsMapWidgetsVisibilityState", null);
    __decorate([
        Action(ChangePointWidgetPointTypes),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, ChangePointWidgetPointTypes]),
        __metadata("design:returntype", void 0)
    ], FlowsMapState.prototype, "getActiveMATabType", null);
    __decorate([
        Action(ChangeFlowsMapPointTypes),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, ChangeFlowsMapPointTypes]),
        __metadata("design:returntype", void 0)
    ], FlowsMapState.prototype, "changePointTypes", null);
    FlowsMapState = __decorate([
        State({
            name: 'flowsMap',
            defaults: {
                pointsWidgetTabTypeSwitcher: VirtualPointType.IP,
                pointsSwitcher: UserPointTypesEnum.ALL_POINTS,
                pointsWidgetFilter: undefined,
                virtualPoints: {
                    data: ɵ0,
                    loading: false,
                    loaded: false
                },
                physicalPoints: {
                    data: ɵ1,
                    loading: false,
                    loaded: false
                },
                selectedDate: new Date().getTime(),
                activeFilterTab: FlowsMapTabType.COMMERCIAL,
                selectedChartPoints: undefined,
                selectedTypesFilter: [VirtualPointType.VP, VirtualPointType.VS, VirtualPointType.LNG, VirtualPointType.VIP],
                widgetsVisibilityState: (_a = {},
                    _a[FlowsMapWidgetTypesEnum.DATE_SELECTOR] = { id: FlowsMapWidgetTypesEnum.DATE_SELECTOR, hidden: true },
                    _a[FlowsMapWidgetTypesEnum.COMMERCIAL_CHART] = { id: FlowsMapWidgetTypesEnum.COMMERCIAL_CHART, hidden: true },
                    _a[FlowsMapWidgetTypesEnum.MY_POINTS] = { id: FlowsMapWidgetTypesEnum.MY_POINTS, hidden: true },
                    _a[FlowsMapWidgetTypesEnum.LEGEND] = { id: FlowsMapWidgetTypesEnum.LEGEND, hidden: false },
                    _a)
            }
        })
    ], FlowsMapState);
    return FlowsMapState;
}());
export { FlowsMapState };
export { ɵ0, ɵ1 };
