import { developmentVariables } from './development';
import { productionVariables } from './production';
import { stagingVariables } from './staging';
// tslint:disable-next-line:typedef
export function environmentFactory() {
    var variables;
    switch (process.env.NODE_ENV) {
        case 'production':
        case 'prod':
            variables = productionVariables;
            break;
        case 'stage':
            variables = stagingVariables;
            break;
        case 'development':
        case 'dev':
            variables = developmentVariables;
            break;
        default:
            variables = developmentVariables;
    }
    return variables;
}
var EnvironmentsModule = /** @class */ /*@__PURE__*/ (function () {
    function EnvironmentsModule() {
    }
    return EnvironmentsModule;
}());
export { EnvironmentsModule };
