import { Location } from '@angular/common';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
var RouteChangeHandlerService = /** @class */ /*@__PURE__*/ (function () {
    function RouteChangeHandlerService(router, location) {
        this.router = router;
        this.location = location;
        this.yScrollStack = [];
    }
    RouteChangeHandlerService.prototype.scrollToTopOnRouteChange = function () {
        var _this = this;
        this.location.subscribe(function (ev) {
            _this.lastPoppedUrl = ev.url;
        });
        this.router.events.subscribe(function (ev) {
            if (ev instanceof NavigationStart) {
                if (ev.url !== _this.lastPoppedUrl) {
                    _this.yScrollStack.push(window.scrollY);
                }
            }
            else if (ev instanceof NavigationEnd) {
                if (ev.url === _this.lastPoppedUrl) {
                    _this.lastPoppedUrl = undefined;
                    window.scrollTo(0, _this.yScrollStack.pop());
                }
                else {
                    window.scrollTo(0, 0);
                }
            }
        });
    };
    return RouteChangeHandlerService;
}());
export { RouteChangeHandlerService };
