import { YouAreExclusiveUserComponent } from './you-are-exclusive-user.component';

export const youAreExclusiveModalConfig = {
  title: '',
  component: {
    name: YouAreExclusiveUserComponent,
    options: {}
  },
  data: {
    width: 462,
    closable: false
  }
};
