/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "../user-transition-modals.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/appy-gas-core/dist/components/button/button.component.ngfactory";
import * as i4 from "appy-gas-core/dist/components/button/button.component";
import * as i5 from "./info-modal.component";
import * as i6 from "appy-gas-core/dist/components/modal/modal.service";
var styles_InfoModalComponent = [i0.styles];
var RenderType_InfoModalComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_InfoModalComponent, data: {} });
export { RenderType_InfoModalComponent as RenderType_InfoModalComponent };
export function View_InfoModalComponent_0(_l) {
    return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "transition-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "transition-modal__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You are now an Exclusive user"])), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [["class", "transition-modal__wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "transition-modal__description-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "transition-modal__description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" Your Exclusive access will end in ", " days (", ") "])), i1.ɵppd(9, 2), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You will receive a confirmation email to keep track of your access. "])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["class", "transition-modal__description-img"], ["src", "/assets/images/portfolio/letter-blue.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["appyGasButton", ""], ["buttonType", "secondary-blue"], ["class", "transition-modal__btn--cancel-btn"]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.hideModal() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i1.ɵdid(15, 638976, null, 0, i4.ButtonComponent, [i1.ElementRef, i1.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i1.ɵted(-1, 0, [" OK "]))], function (_ck, _v) { var currVal_2 = "secondary-blue"; _ck(_v, 15, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.accessDays; var currVal_1 = i1.ɵunv(_v, 8, 1, _ck(_v, 9, 0, i1.ɵnov(_v, 0), _co.dateAccessEnd, "MMM d, y")); _ck(_v, 8, 0, currVal_0, currVal_1); });
}
export function View_InfoModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "appy-gas-info-modal", [], null, null, null, View_InfoModalComponent_0, RenderType_InfoModalComponent)), i1.ɵdid(1, 49152, null, 0, i5.InfoModalComponent, [i6.ModalService], null, null)], null, null); }
var InfoModalComponentNgFactory = /*@__PURE__*/ i1.ɵccf("appy-gas-info-modal", i5.InfoModalComponent, View_InfoModalComponent_Host_0, {}, {}, []);
export { InfoModalComponentNgFactory as InfoModalComponentNgFactory };
