import { ModalService } from 'appy-gas-core/dist';
var SwitchToExclusiveOnlyComponent = /** @class */ /*@__PURE__*/ (function () {
    function SwitchToExclusiveOnlyComponent(modalService) {
        this.modalService = modalService;
    }
    SwitchToExclusiveOnlyComponent.prototype.hideModal = function () {
        this.modalService.hide();
    };
    SwitchToExclusiveOnlyComponent.prototype.switchToExclusive = function () {
        this.modalService.hide();
        this.proceedToNextStep();
    };
    return SwitchToExclusiveOnlyComponent;
}());
export { SwitchToExclusiveOnlyComponent };
