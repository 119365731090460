/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "./restricted-market-area-classic.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/appy-gas-core/dist/components/button/button.component.ngfactory";
import * as i3 from "appy-gas-core/dist/components/button/button.component";
import * as i4 from "./restricted-market-area-classic.component";
import * as i5 from "appy-gas-core/dist/components/modal/modal.service";
var styles_RestrictedMarketAreaClassicComponent = [i0.styles];
var RenderType_RestrictedMarketAreaClassicComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_RestrictedMarketAreaClassicComponent, data: {} });
export { RenderType_RestrictedMarketAreaClassicComponent as RenderType_RestrictedMarketAreaClassicComponent };
export function View_RestrictedMarketAreaClassicComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "restricted-market-area"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "restricted-market-area__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oops! Looks like you are too early"])), (_l()(), i1.ɵeld(3, 0, null, null, 13, "div", [["class", "restricted-market-area__wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "restricted-market-area__description-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "restricted-market-area__description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["This feature will become available for classic users starting from 1st of ", " ", "."])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Can't wait? Simply switch now to Exclusive!"])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["class", "restricted-market-area__description-img"], ["src", "/assets/images/portfolio/exclusive-only-info.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["appyGasButton", ""], ["buttonType", "secondary-blue"], ["class", "restricted-market-area__btn--okay"]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.hideModal() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(12, 638976, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i1.ɵted(-1, 0, [" OK "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["appyGasButton", ""], ["buttonType", "blue"], ["class", "transition-modal__btn--submit"]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.switchToExclusive() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(15, 638976, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i1.ɵted(-1, 0, ["SWITCH TO EXCLUSIVE"]))], function (_ck, _v) { var currVal_2 = "secondary-blue"; _ck(_v, 12, 0, currVal_2); var currVal_3 = "blue"; _ck(_v, 15, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activatedDateMonth; var currVal_1 = _co.activatedDateYear; _ck(_v, 7, 0, currVal_0, currVal_1); });
}
export function View_RestrictedMarketAreaClassicComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "appy-gas-restricted-market-area-classic", [], null, null, null, View_RestrictedMarketAreaClassicComponent_0, RenderType_RestrictedMarketAreaClassicComponent)), i1.ɵdid(1, 49152, null, 0, i4.RestrictedMarketAreaClassicComponent, [i5.ModalService], null, null)], null, null); }
var RestrictedMarketAreaClassicComponentNgFactory = /*@__PURE__*/ i1.ɵccf("appy-gas-restricted-market-area-classic", i4.RestrictedMarketAreaClassicComponent, View_RestrictedMarketAreaClassicComponent_Host_0, {}, {}, []);
export { RestrictedMarketAreaClassicComponentNgFactory as RestrictedMarketAreaClassicComponentNgFactory };
