import { CookiesPopoverService } from './cookies-popover.service';
import closeIcon from 'appy-gas-core/dist/assets/svg/icons/close.svg';
import cookiesIcon from 'appy-gas-core/dist/assets/svg/icons/cookies.svg';
var popoverDelay = 15000;
var animationDuration = 500;
var CookiesPopoverComponent = /** @class */ /*@__PURE__*/ (function () {
    function CookiesPopoverComponent(cookiesPopoverService) {
        this.cookiesPopoverService = cookiesPopoverService;
        this.visible = false;
        this.state = 'invisible';
        this.icons = {
            close: closeIcon,
            cookies: cookiesIcon
        };
    }
    CookiesPopoverComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.cookiesPopoverService.isCookiesAccepted()) {
            setTimeout(function () { return _this.togglePopover(); }, popoverDelay);
        }
    };
    CookiesPopoverComponent.prototype.acceptCookies = function () {
        var _this = this;
        this.cookiesPopoverService.acceptCookies();
        this.state = 'invisible';
        setTimeout(function () {
            _this.visible = false;
        }, animationDuration);
    };
    CookiesPopoverComponent.prototype.togglePopover = function () {
        var _this = this;
        this.visible = true;
        setTimeout(function () {
            _this.state = 'visible';
        }, animationDuration);
    };
    return CookiesPopoverComponent;
}());
export { CookiesPopoverComponent };
