import { ModalService } from 'appy-gas-core';
var ClassicUserExclusiveExpiredComponent = /** @class */ /*@__PURE__*/ (function () {
    function ClassicUserExclusiveExpiredComponent(modalService) {
        this.modalService = modalService;
    }
    ClassicUserExclusiveExpiredComponent.prototype.hideModal = function () {
        this.modalService.hide();
    };
    return ClassicUserExclusiveExpiredComponent;
}());
export { ClassicUserExclusiveExpiredComponent };
