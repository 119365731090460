import { ModalService } from 'appy-gas-core';
var SwitchToExclusiveDaysComponent = /** @class */ /*@__PURE__*/ (function () {
    function SwitchToExclusiveDaysComponent(modalService) {
        this.modalService = modalService;
    }
    SwitchToExclusiveDaysComponent.prototype.hideModal = function () {
        this.modalService.hide();
    };
    SwitchToExclusiveDaysComponent.prototype.switchToExclusive = function () {
        this.proceedToNextStep();
        this.modalService.hide();
    };
    return SwitchToExclusiveDaysComponent;
}());
export { SwitchToExclusiveDaysComponent };
