import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { getMetadataHeaders } from 'appy-gas-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TermsService = /** @class */ /*@__PURE__*/ (function () {
    function TermsService(http) {
        this.http = http;
    }
    TermsService.prototype.acceptTermsOfUse = function () {
        return (this.http
            .post("/termsofservice/accept", { headers: getMetadataHeaders({ auth: true }) })
            .pipe(tap(function (x) { return console.log(x); })));
    };
    TermsService.ɵprov = i0.ɵɵdefineInjectable({ factory: function TermsService_Factory() { return new TermsService(i0.ɵɵinject(i1.HttpClient)); }, token: TermsService, providedIn: "root" });
    return TermsService;
}());
export { TermsService };
