import { Router } from '@angular/router';
import { ModalService } from 'appy-gas-core';
import { TermsService } from './terms.service';
var TermsOfServiceUpdateComponent = /** @class */ /*@__PURE__*/ (function () {
    function TermsOfServiceUpdateComponent(modalService, router, termsService) {
        this.modalService = modalService;
        this.router = router;
        this.termsService = termsService;
    }
    TermsOfServiceUpdateComponent.prototype.acceptTerms = function () {
        this.modalService.hide();
        this.termsService.acceptTermsOfUse()
            .subscribe(function () {
            window.location.reload();
        });
    };
    return TermsOfServiceUpdateComponent;
}());
export { TermsOfServiceUpdateComponent };
