/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "./you-are-exclusive-user.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/appy-gas-core/dist/components/button/button.component.ngfactory";
import * as i3 from "appy-gas-core/dist/components/button/button.component";
import * as i4 from "./you-are-exclusive-user.component";
import * as i5 from "appy-gas-core/dist/components/modal/modal.service";
var styles_YouAreExclusiveUserComponent = [i0.styles];
var RenderType_YouAreExclusiveUserComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_YouAreExclusiveUserComponent, data: {} });
export { RenderType_YouAreExclusiveUserComponent as RenderType_YouAreExclusiveUserComponent };
export function View_YouAreExclusiveUserComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "switch-declined"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "switch-declined__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You are now and Exclusive user"])), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "switch-declined__wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "switch-declined__description-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "switch-declined__description"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Make sure you make the best out of this experience! "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["class", "switch-declined__description-img"], ["src", "/assets/images/portfolio/appies-blue.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["appyGasButton", ""], ["buttonType", "secondary-blue"], ["class", "switch-declined__btn--cancel-btn"]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.hideModal() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(9, 638976, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i1.ɵted(-1, 0, [" OK "]))], function (_ck, _v) { var currVal_0 = "secondary-blue"; _ck(_v, 9, 0, currVal_0); }, null);
}
export function View_YouAreExclusiveUserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "appy-gas-you-are-exclusive-user", [], null, null, null, View_YouAreExclusiveUserComponent_0, RenderType_YouAreExclusiveUserComponent)), i1.ɵdid(1, 49152, null, 0, i4.YouAreExclusiveUserComponent, [i5.ModalService], null, null)], null, null); }
var YouAreExclusiveUserComponentNgFactory = /*@__PURE__*/ i1.ɵccf("appy-gas-you-are-exclusive-user", i4.YouAreExclusiveUserComponent, View_YouAreExclusiveUserComponent_Host_0, {}, {}, []);
export { YouAreExclusiveUserComponentNgFactory as YouAreExclusiveUserComponentNgFactory };
