import { Router } from '@angular/router';
import { ModalService } from 'appy-gas-core';
var ExclusiveAccessExpiredComponent = /** @class */ /*@__PURE__*/ (function () {
    function ExclusiveAccessExpiredComponent(modalService, router) {
        this.modalService = modalService;
        this.router = router;
    }
    ExclusiveAccessExpiredComponent.prototype.hideModal = function () {
        this.modalService.hide();
    };
    ExclusiveAccessExpiredComponent.prototype.goToContactUsPage = function () {
        var _this = this;
        this.router.navigateByUrl('static/contact-us/upgrade-exclusive').then(function () { return _this.hideModal(); });
    };
    return ExclusiveAccessExpiredComponent;
}());
export { ExclusiveAccessExpiredComponent };
