/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "../user-transition-modals.scss.shim.ngstyle";
import * as i1 from "./switch-to-exclusive-only.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../../../../../../node_modules/appy-gas-core/dist/components/button/button.component.ngfactory";
import * as i4 from "appy-gas-core/dist/components/button/button.component";
import * as i5 from "./switch-to-exclusive-only.component";
import * as i6 from "appy-gas-core/dist/components/modal/modal.service";
var styles_SwitchToExclusiveOnlyComponent = [i0.styles, i1.styles];
var RenderType_SwitchToExclusiveOnlyComponent = /*@__PURE__*/ i2.ɵcrt({ encapsulation: 0, styles: styles_SwitchToExclusiveOnlyComponent, data: {} });
export { RenderType_SwitchToExclusiveOnlyComponent as RenderType_SwitchToExclusiveOnlyComponent };
export function View_SwitchToExclusiveOnlyComponent_0(_l) {
    return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 15, "div", [["class", "transition-modal"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "div", [["class", "transition-modal__header"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["This Feature is available for Exclusive users only"])), (_l()(), i2.ɵeld(3, 0, null, null, 12, "div", [["class", "transition-modal__main-block"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 9, "div", [["class", "transition-modal__left-sidebar"]], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 1, "div", [["class", "transition-modal__description"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" This feature is not included in your plan. To use this feature Please upgrade to Exclusive with a simple click. "])), (_l()(), i2.ɵeld(7, 0, null, null, 2, "button", [["appyGasButton", ""], ["buttonType", "secondary-blue"], ["class", "transition-modal__btn--cancel"]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.hideModal() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i2.ɵdid(8, 638976, null, 0, i4.ButtonComponent, [i2.ElementRef, i2.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i2.ɵted(-1, 0, [" CANCEL "])), (_l()(), i2.ɵeld(10, 0, null, null, 3, "button", [["appyGasButton", ""], ["buttonType", "blue"], ["class", "transition-modal__btn--submit"]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.switchToExclusive() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i2.ɵdid(11, 638976, null, 0, i4.ButtonComponent, [i2.ElementRef, i2.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i2.ɵeld(12, 0, null, 0, 0, "img", [["src", "/assets/images/portfolio/refresh.svg"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, 0, [" SWITCH TO EXCLUSIVE "])), (_l()(), i2.ɵeld(14, 0, null, null, 1, "div", [["class", "transition-modal__right-sidebar"]], null, null, null, null, null)), (_l()(), i2.ɵeld(15, 0, null, null, 0, "img", [["alt", ""], ["class", "transition-modal__main-img"], ["src", "/assets/images/portfolio/exclusive-only-info.svg"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "secondary-blue"; _ck(_v, 8, 0, currVal_0); var currVal_1 = "blue"; _ck(_v, 11, 0, currVal_1); }, null);
}
export function View_SwitchToExclusiveOnlyComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "appy-gas-switch-to-exclusive-only", [], null, null, null, View_SwitchToExclusiveOnlyComponent_0, RenderType_SwitchToExclusiveOnlyComponent)), i2.ɵdid(1, 49152, null, 0, i5.SwitchToExclusiveOnlyComponent, [i6.ModalService], null, null)], null, null); }
var SwitchToExclusiveOnlyComponentNgFactory = /*@__PURE__*/ i2.ɵccf("appy-gas-switch-to-exclusive-only", i5.SwitchToExclusiveOnlyComponent, View_SwitchToExclusiveOnlyComponent_Host_0, {}, {}, []);
export { SwitchToExclusiveOnlyComponentNgFactory as SwitchToExclusiveOnlyComponentNgFactory };
