import { ModalService } from 'appy-gas-core/dist';
var SwitchToExclusiveInfoComponent = /** @class */ /*@__PURE__*/ (function () {
    function SwitchToExclusiveInfoComponent(modalService) {
        this.modalService = modalService;
    }
    SwitchToExclusiveInfoComponent.prototype.hideModal = function () {
        this.modalService.hide();
    };
    return SwitchToExclusiveInfoComponent;
}());
export { SwitchToExclusiveInfoComponent };
