// The route state serializer will on route changes serialize the activated route into an object which reflect our state model
var CustomRouterStateSerializer = /** @class */ /*@__PURE__*/ (function () {
    function CustomRouterStateSerializer() {
    }
    CustomRouterStateSerializer.prototype.serialize = function (routerState) {
        var url = routerState.url, queryParams = routerState.root.queryParams;
        var route = routerState.root;
        while (route.firstChild) {
            route = route.firstChild;
        }
        // TODO remove user info from router data and then uncomment it
        // const { params, data } = route;
        var params = route.params;
        // TODO remove user info from router data and then uncomment it
        // if (route.params.title) {
        //   data.title = route.params.title;
        // }
        // return { url, params, queryParams };
        return { url: url, params: params, queryParams: queryParams };
    };
    return CustomRouterStateSerializer;
}());
export { CustomRouterStateSerializer };
