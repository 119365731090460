/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "./technical-maintenance.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./technical-maintenance.component";
var styles_TechnicalMaintenanceComponent = [i0.styles];
var RenderType_TechnicalMaintenanceComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_TechnicalMaintenanceComponent, data: {} });
export { RenderType_TechnicalMaintenanceComponent as RenderType_TechnicalMaintenanceComponent };
export function View_TechnicalMaintenanceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "technical-maintenance"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["class", "technical-maintenance__image"], ["src", "/assets/images/maintenance/maintenance-workers-svg.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "h1", [["class", "technical-maintenance__description"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We are currently rolling out the changes for the new German market "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" The app will be back very soon! "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We apologise for any inconvenience "])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["alt", ""], ["class", "technical-maintenance__logo"], ["src", "/assets/images/logo.svg"]], null, null, null, null, null))], null, null); }
export function View_TechnicalMaintenanceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "appy-gas-page-technical-maintenance", [], null, null, null, View_TechnicalMaintenanceComponent_0, RenderType_TechnicalMaintenanceComponent)), i1.ɵdid(1, 49152, null, 0, i2.TechnicalMaintenanceComponent, [], null, null)], null, null); }
var TechnicalMaintenanceComponentNgFactory = /*@__PURE__*/ i1.ɵccf("appy-gas-page-technical-maintenance", i2.TechnicalMaintenanceComponent, View_TechnicalMaintenanceComponent_Host_0, {}, {}, []);
export { TechnicalMaintenanceComponentNgFactory as TechnicalMaintenanceComponentNgFactory };
