import { __decorate, __metadata } from "tslib";
import { Selector } from '@ngxs/store';
import { DataExportState } from './data-export.state';
var DataExportSelectors = /** @class */ /*@__PURE__*/ (function () {
    function DataExportSelectors() {
    }
    DataExportSelectors.exportData = function (state) {
        return state.exportData.data;
    };
    __decorate([
        Selector([DataExportState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], DataExportSelectors, "exportData", null);
    return DataExportSelectors;
}());
export { DataExportSelectors };
