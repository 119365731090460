import { __decorate, __metadata } from "tslib";
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetExportData, GetExportDataFailed, GetExportDataSuccess } from './data-export.actions';
import { DataExportSelectors } from './data-export.selectors';
var DataExportFacade = /** @class */ /*@__PURE__*/ (function () {
    function DataExportFacade(dataExportService, store) {
        this.dataExportService = dataExportService;
        this.store = store;
    }
    DataExportFacade.prototype.getExportData = function (options) {
        var _this = this;
        this.store.dispatch(new GetExportData());
        return this.dataExportService.loadDataInJson(options).pipe(catchError(function (err) {
            _this.store.dispatch(new GetExportDataFailed());
            return throwError(err);
        }), map(function (data) { return new GetExportDataSuccess(data); }));
    };
    __decorate([
        Select(DataExportSelectors.exportData),
        __metadata("design:type", Observable)
    ], DataExportFacade.prototype, "exportData$", void 0);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Observable)
    ], DataExportFacade.prototype, "getExportData", null);
    return DataExportFacade;
}());
export { DataExportFacade };
