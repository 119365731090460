import { __decorate, __metadata } from "tslib";
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import head from 'lodash/head';
import isPlainObject from 'lodash/isPlainObject';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProfileHelperService } from '../../shared/services/profile-helper.service';
import { UserSelectors } from '../user';
import * as actions from './profile.actions';
import { ProfileSelectors } from './profile.selectors';
import { ProfileState } from './profile.state';
import * as i0 from "@angular/core";
import * as i1 from "appy-gas-core/dist/common/api-users/api-users.service";
import * as i2 from "../../profile/profile.service";
import * as i3 from "@ngxs/store";
var ProfileFacade = /** @class */ /*@__PURE__*/ (function () {
    function ProfileFacade(apiUsersService, profileService, store) {
        this.apiUsersService = apiUsersService;
        this.profileService = profileService;
        this.store = store;
    }
    ProfileFacade.prototype.getApiUsersList = function (userId) {
        var _this = this;
        this.store.dispatch(new actions.GetApiUsersList());
        return this.apiUsersService.getApiUsersList(userId).pipe(catchError(function (error) { return _this.store.dispatch(new actions.GetApiUsersListFail(error)); }), map(function (apiUsersList) { return new actions.GetApiUsersListSuccess(apiUsersList); }));
    };
    ProfileFacade.prototype.getPortfolioInformation = function () {
        var _this = this;
        this.store.dispatch(new actions.GetPortfolioInformation());
        return this.profileService.getPortfolioInformation().pipe(catchError(function (error) { return _this.store.dispatch(new actions.GetPortfolioInformationFail(error)); }), 
        // TODO remove when super points with two operators removed
        map(function (portfolioInformation) {
            return ProfileHelperService.addSuperPointsExceptions(portfolioInformation);
        }), map(function (portfolioInformation) {
            return new actions.GetPortfolioInformationSuccess(portfolioInformation);
        }));
    };
    ProfileFacade.prototype.getUserPortfolioPoints = function (userId) {
        var _this = this;
        this.store.dispatch(new actions.GetUserPortfolioPoints());
        return this.profileService.getUserPortfolioPoints(userId).pipe(catchError(function (error) { return _this.store.dispatch(new actions.GetUserPortfolioPointsFail(error)); }), map(function (points) { return ProfileHelperService.addSuperPointExceptionIds(points); }), map(function (points) { return new actions.GetUserPortfolioPointsSuccess(points); }));
    };
    ProfileFacade.prototype.saveUserPortfolioPoints = function (pointIds) {
        return new actions.SaveUserPortfolioPoints(pointIds);
    };
    ProfileFacade.prototype.getUserProfile = function () {
        return this.store.select(UserSelectors.getUser).pipe(map(function (user) { return new actions.GetUserProfile(user); }));
    };
    ProfileFacade.prototype.saveApiUser = function (userId, apiUsersFormValue) {
        var _this = this;
        this.store.dispatch(new actions.SaveApiUser());
        return this.apiUsersService.saveApiUser(userId, apiUsersFormValue).pipe(catchError(function (error) { return _this.store.dispatch(new actions.SaveApiUserFail(error)); }), map(function (apiUser) { return new actions.SaveApiUserSuccess(apiUser); }));
    };
    ProfileFacade.prototype.getWalletInformation = function () {
        var _this = this;
        this.store.dispatch(new actions.GetWalletInformation());
        return this.profileService.getWalletInformation().pipe(catchError(function (error) {
            _this.store.dispatch(new actions.GetWalletInformationFail(error));
            return throwError(error);
        }), map(function (walletInformation) {
            if (isPlainObject(walletInformation)) {
                var _a = walletInformation, contractBalance = _a.contractBalance, transactionHistory = _a.transactionHistory;
                return new actions.GetWalletInformationSuccess(contractBalance, transactionHistory);
            }
            else {
                var exceptionData = head(walletInformation);
                var key = exceptionData.key;
                return new actions.SetWalletBalanceStatus(key);
            }
        }));
    };
    ProfileFacade.prototype.getUserBadges = function (userId) {
        var _this = this;
        this.store.dispatch(new actions.GetUserBadges());
        return this.profileService.getUserBadges(userId).pipe(catchError(function (error) {
            _this.store.dispatch(new actions.GetUserBadgesFailed(error));
            return throwError(error);
        }), map(function (badges) { return new actions.GetUserBadgesSuccess(badges); }));
    };
    ProfileFacade.prototype.getAllBadges = function () {
        var _this = this;
        this.store.dispatch(new actions.GetAllBadges());
        return this.profileService.getAllBadges().pipe(catchError(function (error) {
            _this.store.dispatch(new actions.GetAllBadgesFailed(error));
            return throwError(error);
        }), map(function (badges) { return new actions.GetAllBadgesSuccess(badges); }));
    };
    ProfileFacade.ɵprov = i0.ɵɵdefineInjectable({ factory: function ProfileFacade_Factory() { return new ProfileFacade(i0.ɵɵinject(i1.ApiUsersService), i0.ɵɵinject(i2.ProfileService), i0.ɵɵinject(i3.Store)); }, token: ProfileFacade, providedIn: "root" });
    __decorate([
        Select(ProfileState),
        __metadata("design:type", Observable)
    ], ProfileFacade.prototype, "profileData$", void 0);
    __decorate([
        Select(ProfileSelectors.portfolioInformation),
        __metadata("design:type", Observable)
    ], ProfileFacade.prototype, "portfolioInformation$", void 0);
    __decorate([
        Select(ProfileSelectors.userProfile),
        __metadata("design:type", Observable)
    ], ProfileFacade.prototype, "userProfile$", void 0);
    __decorate([
        Select(ProfileSelectors.savedPointsIds),
        __metadata("design:type", Observable)
    ], ProfileFacade.prototype, "savedPointsIds$", void 0);
    __decorate([
        Select(ProfileSelectors.fullPortfolioInformation),
        __metadata("design:type", Observable)
    ], ProfileFacade.prototype, "fullPortfolioInformation$", void 0);
    __decorate([
        Select(ProfileSelectors.appiesCount),
        __metadata("design:type", Observable)
    ], ProfileFacade.prototype, "appiesCount$", void 0);
    __decorate([
        Select(ProfileSelectors.appiesTransactionHistory),
        __metadata("design:type", Observable)
    ], ProfileFacade.prototype, "appiesTransactionHistory$", void 0);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Observable)
    ], ProfileFacade.prototype, "getApiUsersList", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], ProfileFacade.prototype, "getPortfolioInformation", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Observable)
    ], ProfileFacade.prototype, "getUserPortfolioPoints", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", actions.SaveUserPortfolioPoints)
    ], ProfileFacade.prototype, "saveUserPortfolioPoints", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], ProfileFacade.prototype, "getUserProfile", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, Object]),
        __metadata("design:returntype", Observable)
    ], ProfileFacade.prototype, "saveApiUser", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], ProfileFacade.prototype, "getWalletInformation", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Observable)
    ], ProfileFacade.prototype, "getUserBadges", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Observable)
    ], ProfileFacade.prototype, "getAllBadges", null);
    return ProfileFacade;
}());
export { ProfileFacade };
