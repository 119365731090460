/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "../user-transition-modals.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/appy-gas-core/dist/components/button/button.component.ngfactory";
import * as i3 from "appy-gas-core/dist/components/button/button.component";
import * as i4 from "./trial-exclusive-access-expired.component";
import * as i5 from "appy-gas-core/dist/components/modal/modal.service";
var styles_TrialExclusiveAccessExpiredComponent = [i0.styles];
var RenderType_TrialExclusiveAccessExpiredComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_TrialExclusiveAccessExpiredComponent, data: {} });
export { RenderType_TrialExclusiveAccessExpiredComponent as RenderType_TrialExclusiveAccessExpiredComponent };
export function View_TrialExclusiveAccessExpiredComponent_0(_l) {
    return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "transition-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "transition-modal__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your Exclusive access has expired"])), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "transition-modal__wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "transition-modal__description-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "transition-modal__description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You have been switched back to Classic. We hope you enjoyed discovering the benefits of Exclusive during your free Trial! "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["class", "transition-modal__description-img"], ["src", "/assets/images/portfolio/person-blue.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["appyGasButton", ""], ["buttonType", "secondary-blue"], ["class", "transition-modal__btn--cancel-btn"]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.hideModal() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(10, 638976, null, 0, i3.ButtonComponent, [i1.ElementRef, i1.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i1.ɵted(-1, 0, [" OK "]))], function (_ck, _v) { var currVal_0 = "secondary-blue"; _ck(_v, 10, 0, currVal_0); }, null);
}
export function View_TrialExclusiveAccessExpiredComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "appy-gas-trial-exclusive-access-expired", [], null, null, null, View_TrialExclusiveAccessExpiredComponent_0, RenderType_TrialExclusiveAccessExpiredComponent)), i1.ɵdid(1, 49152, null, 0, i4.TrialExclusiveAccessExpiredComponent, [i5.ModalService], null, null)], null, null); }
var TrialExclusiveAccessExpiredComponentNgFactory = /*@__PURE__*/ i1.ɵccf("appy-gas-trial-exclusive-access-expired", i4.TrialExclusiveAccessExpiredComponent, View_TrialExclusiveAccessExpiredComponent_Host_0, {}, {}, []);
export { TrialExclusiveAccessExpiredComponentNgFactory as TrialExclusiveAccessExpiredComponentNgFactory };
