import { Injector } from '@angular/core';
import { RollbarService } from './rollbar-service';
var RollbarErrorHandler = /** @class */ /*@__PURE__*/ (function () {
    function RollbarErrorHandler(injector) {
        this.injector = injector;
    }
    RollbarErrorHandler.prototype.handleError = function (err) {
        if (IS_PRODUCTION) {
            var rollbar = this.injector.get(RollbarService);
            rollbar.error(err.originalError || err);
        }
        throw err;
    };
    return RollbarErrorHandler;
}());
export { RollbarErrorHandler };
