import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { trialExpiredModalConfig } from '../../../auth';
import { trialExclusiveAccessExpiredConfig } from '../../../shared/components/user-transition-modals';
import { termsOfServiceUpdateConfig } from '../../../shared/components/user-transition-modals/terms-of-service-update';
import { EnvironmentVariables, ErrorTypeListEnum, GrantType, InterceptorConfig, ModalService, Role, TokenType } from 'appy-gas-core';
import { ProfileFacade } from '../../../store/profile';
import { AuthenticationService } from '../authentication.service';
import { AuthInterceptor } from './auth-interceptor';
var UserStatusInterceptor = /** @class */ /*@__PURE__*/ (function () {
    function UserStatusInterceptor(envVariables, authService, profileFacade, toastService, modalService, router, injector) {
        this.envVariables = envVariables;
        this.authService = authService;
        this.profileFacade = profileFacade;
        this.toastService = toastService;
        this.modalService = modalService;
        this.router = router;
        this.injector = injector;
        this.config = new InterceptorConfig({ serverUrl: envVariables.serverUrl });
    }
    UserStatusInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        return next.handle(req).pipe(map(function (event) {
            if (event instanceof HttpResponse) {
                _this.handleRoleChange(event);
                // Intercept if trial was expired and show modal
                if (event.headers.has(_this.config.trialExpiredHeader) && event.headers.get(_this.config.trialExpiredHeader)) {
                    _this.handleTrialExpired();
                }
                return event.clone();
            }
        }), catchError(function (error) {
            _this.handleRoleChange(error);
            var termsOfServiceUpdated = (error === null || error === void 0 ? void 0 : error.status) === ErrorTypeListEnum.TERMS_OF_USE_UPDATED;
            if (termsOfServiceUpdated) {
                _this.showTermsOfServiceUpdatedModal();
                return;
            }
            return throwError(error);
        }));
    };
    UserStatusInterceptor.prototype.handleRoleChange = function (event) {
        var _this = this;
        var _a, _b;
        var isLoginPage = this.router.url.startsWith('/auth/login');
        var isRoleChanged = (_a = event === null || event === void 0 ? void 0 : event.headers) === null || _a === void 0 ? void 0 : _a.has(this.config.roleChangedHeader);
        if (isLoginPage) {
            this.currentUserRole = -1;
        }
        if (isRoleChanged && !isLoginPage) {
            var prevUserRole = Number(event.headers.get(this.config.roleChangedHeader).split('-')[0]);
            var newUserRole = Number(event.headers.get(this.config.roleChangedHeader).split('-')[1]);
            if (this.currentUserRole !== newUserRole) {
                this.currentUserRole = newUserRole;
                var refreshToken = AuthInterceptor.getToken(TokenType.REFRESH);
                this.authService
                    .refreshToken({
                    refresh_token: refreshToken,
                    grant_type: GrantType.REFRESH_TOKEN
                })
                    .subscribe(function () {
                    setTimeout(function () { return window.location.reload(); }, 2000);
                    _this.profileFacade.getWalletInformation();
                }, function () {
                    _this.authService.logout();
                    _this.router.navigate(['/auth/login'], { queryParams: { licenseChanged: true } });
                });
                if (prevUserRole === Role.TrialExclusive && newUserRole === Role.TrialClassic) {
                    this.showExclusiveExpiredModal();
                    return;
                }
                this.toastService.warning('Your type of license has been changed.', '', { timeOut: 10000 });
            }
        }
        if (((_b = event === null || event === void 0 ? void 0 : event.url) === null || _b === void 0 ? void 0 : _b.includes('/accounts/userinfo')) && isRoleChanged) {
            this.toastService.warning('Your type of license has been changed.', '', { timeOut: 10000 });
        }
    };
    UserStatusInterceptor.prototype.handleTrialExpired = function () {
        this.modalService.show(this.injector, trialExpiredModalConfig);
    };
    UserStatusInterceptor.prototype.showExclusiveExpiredModal = function () {
        this.modalService.show(this.injector, trialExclusiveAccessExpiredConfig);
    };
    UserStatusInterceptor.prototype.showTermsOfServiceUpdatedModal = function () {
        this.modalService.show(this.injector, termsOfServiceUpdateConfig);
    };
    return UserStatusInterceptor;
}());
export { UserStatusInterceptor };
