import { __decorate, __metadata } from "tslib";
import { Selector } from '@ngxs/store';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import defaultsDeep from 'lodash/defaultsDeep';
import head from 'lodash/head';
import map from 'lodash/map';
import sum from 'lodash/sum';
import { MaintenanceConfig, MarketAreaNameConfig, SuperPointsConfig } from 'appy-gas-core';
import { NorwayConfig } from '../../availability-pro/availability-pro-map/availability-pro-market-areas-map/norway-icon';
import { mergedGermanyMADate, norwayDataSourceId, virtualStorageIds } from '../../shared';
import { virtualPointIdsAfterGermanyMerge, virtualPointIdsBeforeGermanyMerge } from '../../shared/constants/app-exceptions';
import { MAEventsTabDirection, OperatorTypesEnum, StorageDirectionEnum, UserPointTypesEnum } from '../../shared/enums';
import { AvailabilityHelperService } from '../../shared/services/availability-helper.service';
import { FilterHelperService } from '../../shared/services/filter-helper.service';
import { CommonEntitiesState } from '../common-entities';
import { RouterSelectors } from '../router';
import { AvailabilityState } from './availability.state';
var POINTS_CONFIG = defaultsDeep({}, SuperPointsConfig, MaintenanceConfig, NorwayConfig);
var AvailabilitySelectors = /** @class */ /*@__PURE__*/ (function () {
    function AvailabilitySelectors() {
    }
    AvailabilitySelectors.gasDayRange = function (state) {
        return state.gasDayRange;
    };
    AvailabilitySelectors.allMaintenancePoints = function (state) {
        return state.allMaintenancePoints;
    };
    AvailabilitySelectors.widgetsVisibilityState = function (state) {
        return state.widgetsVisibilityState;
    };
    AvailabilitySelectors.activeMATabType = function (state) {
        return state.activeMATabType;
    };
    AvailabilitySelectors.portfolioMaintenancePoints = function (state) {
        return state.portfolioMaintenancePoints.data;
    };
    AvailabilitySelectors.selectedPointType = function (state) {
        return state.pointsSwitcher;
    };
    AvailabilitySelectors.maintenanceFilters = function (state) {
        return state.maintenanceFilters;
    };
    AvailabilitySelectors.selectedMapPointId = function (state) {
        return state.selectedMapPointId;
    };
    AvailabilitySelectors.getNavigatedFromExternalPageStatus = function (state) {
        return state.isNavigatedFromExternalPage;
    };
    AvailabilitySelectors.getNavigatedFromGeneralInfoPageStatus = function (state) {
        return state.isNavigatedFromGeneralInfoPage;
    };
    AvailabilitySelectors.isNorwayPointSelectedStatus = function (state) {
        return state.isNorwayPointSelected;
    };
    AvailabilitySelectors.getSelectedRouteEvents = function (state) {
        return state.selectedRouteEvents.data;
    };
    AvailabilitySelectors.getSelectedPointEvents = function (state) {
        return state.selectedPointEvents.data;
    };
    AvailabilitySelectors.shouldUpdateMaintenanceData = function (getSelectedRouteEvents, getSelectedPointEvents, getNavigatedFromGeneralInfoPageStatus) {
        return !getNavigatedFromGeneralInfoPageStatus || (!getSelectedPointEvents && !getSelectedRouteEvents);
    };
    AvailabilitySelectors.getAllEventPoints = function (state) {
        return state.allEventPoints.data;
    };
    AvailabilitySelectors.filteredMaintenancePoints = function (state, selectedPointType, isMergedGermanyMAState) {
        var maintenanceFilters = state.maintenanceFilters, allMaintenancePoints = state.allMaintenancePoints, portfolioMaintenancePoints = state.portfolioMaintenancePoints;
        var pointsBySelectedPointType = selectedPointType === UserPointTypesEnum.MY_PORTFOLIO ? portfolioMaintenancePoints.data : allMaintenancePoints;
        var filteredPointsByMergedGermanyMAState = isMergedGermanyMAState
            ? pointsBySelectedPointType.filter(function (point) { return !virtualPointIdsBeforeGermanyMerge.includes(point.appyGasId); })
            : pointsBySelectedPointType.filter(function (point) { return !virtualPointIdsAfterGermanyMerge.includes(point.appyGasId); });
        return map(filteredPointsByMergedGermanyMAState, function (maintenancePoint) {
            return AvailabilityHelperService.filterMaintenancePoints(maintenancePoint, maintenanceFilters);
        });
    };
    AvailabilitySelectors.allEventsCount = function (maintenanceMapPoint) {
        return sum(map(maintenanceMapPoint, function (point) { return point.maintenanceGeneralFiltered.length; }));
    };
    AvailabilitySelectors.maintenanceNorwayRelatedPoints = function (state, selectedPointType) {
        var maintenanceFilters = state.maintenanceFilters, allMaintenancePoints = state.allMaintenancePoints, portfolioMaintenancePoints = state.portfolioMaintenancePoints;
        var pointsBySelectedPointType = selectedPointType === UserPointTypesEnum.MY_PORTFOLIO ? portfolioMaintenancePoints.data : allMaintenancePoints;
        var pointsGasscoEventsIncluded = pointsBySelectedPointType.filter(function (maintenancePoint) {
            var maintenanceGassco = maintenancePoint.maintenanceGeneral.filter(function (event) { return event.dataSourceId === norwayDataSourceId; });
            return maintenanceGassco.length ? maintenancePoint : false;
        });
        return pointsGasscoEventsIncluded.map(function (points) {
            return AvailabilityHelperService.filterMaintenancePoints(points, maintenanceFilters, true);
        });
    };
    AvailabilitySelectors.selectedRoutePoints = function (state) {
        return state.selectedRoutePoints.filter(function (routePoint) { return POINTS_CONFIG[routePoint.appyGasId].name === routePoint.name; });
    };
    AvailabilitySelectors.generalInfo = function (state) {
        return state.generalInfo;
    };
    AvailabilitySelectors.relatedPointsLoading = function (generalInfo, queryParams) {
        var pointId = queryParams.pointId;
        return generalInfo.relatedPoints[pointId].loading;
    };
    AvailabilitySelectors.relatedPoints = function (generalInfo, queryParams) {
        var pointId = queryParams.pointId;
        return generalInfo.relatedPoints[pointId].data;
    };
    AvailabilitySelectors.relatedPointsInfo = function (relatedPoints, commonEntitiesState) {
        var operators = commonEntitiesState.operators, eePoints = commonEntitiesState.eePoints;
        return AvailabilityHelperService.transformRelatedPoints(relatedPoints, eePoints, operators);
    };
    AvailabilitySelectors.impactChartData = function (generalInfo, queryParams) {
        var pointId = queryParams.pointId;
        var eventDetails = generalInfo.eventDetails[pointId].data;
        var pointData = generalInfo.impactChartData[pointId].data;
        var availableData = pointData.filter(function (pointItem) { return pointItem.value !== 0; });
        var filteredPoints = pointData.filter(function (pointItem) {
            return !AvailabilityHelperService.filterRemainingTacByAvailableOperators(pointItem, eventDetails.expectedInterruption);
        });
        if (availableData.length && eventDetails) {
            return AvailabilityHelperService.transformImpactChartData(filteredPoints, eventDetails);
        }
    };
    AvailabilitySelectors.impactChartDataLoading = function (generalInfo, queryParams) {
        var pointId = queryParams.pointId;
        return generalInfo.impactChartData[pointId].loading;
    };
    AvailabilitySelectors.eventDetails = function (generalInfo, queryParams) {
        var pointId = queryParams.pointId;
        return generalInfo.eventDetails[pointId].data;
    };
    AvailabilitySelectors.eventDetailsLoading = function (generalInfo, queryParams) {
        var pointId = queryParams.pointId;
        return generalInfo.eventDetails[pointId].loading;
    };
    AvailabilitySelectors.eventDetailsUI = function (eventDetails) {
        return AvailabilityHelperService.transformEventDetails(eventDetails);
    };
    AvailabilitySelectors.otherEventsLoading = function (generalInfo, queryParams) {
        var pointId = queryParams.pointId;
        return generalInfo.otherEvents[pointId].loading;
    };
    AvailabilitySelectors.otherEventsUI = function (generalInfo, queryParams, commonEntitiesState) {
        var eePoints = commonEntitiesState.eePoints;
        var pointId = queryParams.pointId;
        return AvailabilityHelperService.getCalendarEventWithAppropriateClasses(generalInfo.otherEvents[pointId].data, eePoints);
    };
    AvailabilitySelectors.pointInfo = function (eventDetails, commonEntitiesState) {
        var _a;
        var eePointId = eventDetails.eePointId, operatorId = eventDetails.operatorId, operatorTypeId = eventDetails.operatorTypeId, capacityType = eventDetails.capacityType, updatedByAppygas = eventDetails.updatedByAppygas, publishedByTSO = eventDetails.publishedByTSO, directionId = eventDetails.directionId;
        var operators = commonEntitiesState.operators, eePoints = commonEntitiesState.eePoints;
        var eePoint = eePoints.find(function (point) { return point.eePointId === eePointId; });
        return {
            name: eePoint === null || eePoint === void 0 ? void 0 : eePoint.name,
            operator: (_a = operators.find(function (_a) {
                var id = _a.id;
                return id === operatorId;
            })) === null || _a === void 0 ? void 0 : _a.name,
            operatorTypeId: operatorTypeId,
            capacityType: capacityType,
            publishedByTSO: publishedByTSO ? AvailabilityHelperService.getZonedUTCTime(publishedByTSO) : null,
            updatedByAppygas: updatedByAppygas ? AvailabilityHelperService.getZonedTime(updatedByAppygas) : null,
            directionId: directionId,
            pointTypeId: eePoint === null || eePoint === void 0 ? void 0 : eePoint.eePointTypeId,
            pointId: eePointId,
            tooltipData: AvailabilityHelperService.getEventTooltip(eventDetails.eePointId)
        };
    };
    AvailabilitySelectors.maintenanceRoutePoints = function (selectedRoutePoints, isMergedGermanyMAState) {
        var mappedMaintenanceRoutePoints = map(POINTS_CONFIG, function (maintenancePoint, appyGasId) {
            return {
                appyGasId: appyGasId,
                name: maintenancePoint.name,
                discPosition: maintenancePoint.discPosition,
                isActiveRoute: selectedRoutePoints.some(function (routePoint) { return routePoint.appyGasId === appyGasId; })
            };
        });
        return isMergedGermanyMAState
            ? mappedMaintenanceRoutePoints.filter(function (point) { return !virtualPointIdsBeforeGermanyMerge.includes(point.appyGasId); })
            : mappedMaintenanceRoutePoints.filter(function (point) { return !virtualPointIdsAfterGermanyMerge.includes(point.appyGasId); });
    };
    AvailabilitySelectors.eventsTab = function (selectedPointEvents) {
        var tabs = head(selectedPointEvents).marketAreasIds;
        var appyGasId = head(selectedPointEvents).virtualPointId;
        var mainDirection = MarketAreaNameConfig[tabs[0]] + " \u279E " + MarketAreaNameConfig[tabs[1]];
        var reverseDirection = MarketAreaNameConfig[tabs[1]] + " \u279E " + MarketAreaNameConfig[tabs[0]];
        return [
            {
                label: virtualStorageIds.includes(appyGasId) ? StorageDirectionEnum.INJECTION : mainDirection,
                type: MAEventsTabDirection.ENTRY_DIRECTION
            },
            {
                label: virtualStorageIds.includes(appyGasId) ? StorageDirectionEnum.WITHDRAWAL : reverseDirection,
                type: MAEventsTabDirection.EXIT_DIRECTION
            }
        ];
    };
    AvailabilitySelectors.mappedSelectedPointEvents = function (availabilityState, commonEntitiesState, selectedPointEvents) {
        var operators = commonEntitiesState.operators, eePoints = commonEntitiesState.eePoints;
        var maintenanceFilters = availabilityState.maintenanceFilters, isNorwayPointSelected = availabilityState.isNorwayPointSelected;
        return AvailabilityHelperService.getMaintenanceUIEvents(operators, selectedPointEvents, eePoints, maintenanceFilters, isNorwayPointSelected);
    };
    AvailabilitySelectors.allEventPoints = function (allEventPoints) {
        return allEventPoints.filter(function (eventPoint) { return eventPoint.events.length; });
    };
    AvailabilitySelectors.mappedAllPointEvents = function (allEventPoints, commonEntitiesState) {
        var operators = commonEntitiesState.operators, eePoints = commonEntitiesState.eePoints;
        return AvailabilityHelperService.getMappedAllPointEvents(allEventPoints, operators, eePoints);
    };
    AvailabilitySelectors.mappedSelectedRouteEvents = function (selectedRouteEvents, commonEntitiesState) {
        var operators = commonEntitiesState.operators, eePoints = commonEntitiesState.eePoints;
        return AvailabilityHelperService.getMaintenanceUIEvents(operators, selectedRouteEvents, eePoints);
    };
    AvailabilitySelectors.selectedPointEvents = function (mappedEvents, activeMATabType) {
        return mappedEvents.filter(function (event) {
            if (virtualStorageIds.includes(event.virtualPointId)) {
                return ((event.flowDirection === activeMATabType && event.operatorTypeId === OperatorTypesEnum.TSO) ||
                    (event.flowDirection !== activeMATabType && event.operatorTypeId === OperatorTypesEnum.SSO));
            }
            else {
                return event.flowDirection === activeMATabType;
            }
        });
    };
    AvailabilitySelectors.selectedPointEventsFilter = function (selectedPointEvents) {
        return FilterHelperService.getEventPointsFilter(selectedPointEvents);
    };
    AvailabilitySelectors.filteredSelectedPointEvents = function (selectedPointEvents, selectedPointEventsAppliedFilter) {
        return FilterHelperService.getFilteredSelectedEventPoints(selectedPointEvents, selectedPointEventsAppliedFilter);
    };
    AvailabilitySelectors.eventsWidgetFilter = function (state) {
        return state.eventsWidgetFilter;
    };
    AvailabilitySelectors.selectedOneMADirectionEvents = function (mappedEvents) {
        return mappedEvents.some(function (event) { return event.isOneMADirection; }) ? mappedEvents : [];
    };
    AvailabilitySelectors.isMergedGermanyMAState = function (gasDayRange) {
        return isAfter(gasDayRange.timeFrom, mergedGermanyMADate) || isSameDay(gasDayRange.timeFrom, mergedGermanyMADate);
    };
    __decorate([
        Selector([AvailabilityState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], AvailabilitySelectors, "gasDayRange", null);
    __decorate([
        Selector([AvailabilityState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "allMaintenancePoints", null);
    __decorate([
        Selector([AvailabilityState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], AvailabilitySelectors, "widgetsVisibilityState", null);
    __decorate([
        Selector([AvailabilityState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", String)
    ], AvailabilitySelectors, "activeMATabType", null);
    __decorate([
        Selector([AvailabilityState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "portfolioMaintenancePoints", null);
    __decorate([
        Selector([AvailabilityState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Number)
    ], AvailabilitySelectors, "selectedPointType", null);
    __decorate([
        Selector([AvailabilityState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], AvailabilitySelectors, "maintenanceFilters", null);
    __decorate([
        Selector([AvailabilityState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", String)
    ], AvailabilitySelectors, "selectedMapPointId", null);
    __decorate([
        Selector([AvailabilityState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], AvailabilitySelectors, "getNavigatedFromExternalPageStatus", null);
    __decorate([
        Selector([AvailabilityState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], AvailabilitySelectors, "getNavigatedFromGeneralInfoPageStatus", null);
    __decorate([
        Selector([AvailabilityState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], AvailabilitySelectors, "isNorwayPointSelectedStatus", null);
    __decorate([
        Selector([AvailabilityState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "getSelectedRouteEvents", null);
    __decorate([
        Selector([AvailabilityState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "getSelectedPointEvents", null);
    __decorate([
        Selector([
            AvailabilitySelectors.getSelectedRouteEvents,
            AvailabilitySelectors.getSelectedPointEvents,
            AvailabilitySelectors.getNavigatedFromGeneralInfoPageStatus
        ]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array, Array, Boolean]),
        __metadata("design:returntype", Boolean)
    ], AvailabilitySelectors, "shouldUpdateMaintenanceData", null);
    __decorate([
        Selector([AvailabilityState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "getAllEventPoints", null);
    __decorate([
        Selector([AvailabilityState, AvailabilitySelectors.selectedPointType, AvailabilitySelectors.isMergedGermanyMAState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Number, Boolean]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "filteredMaintenancePoints", null);
    __decorate([
        Selector([AvailabilitySelectors.filteredMaintenancePoints]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", Number)
    ], AvailabilitySelectors, "allEventsCount", null);
    __decorate([
        Selector([AvailabilityState, AvailabilitySelectors.selectedPointType]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Number]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "maintenanceNorwayRelatedPoints", null);
    __decorate([
        Selector([AvailabilityState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "selectedRoutePoints", null);
    __decorate([
        Selector([AvailabilityState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], AvailabilitySelectors, "generalInfo", null);
    __decorate([
        Selector([AvailabilitySelectors.generalInfo, RouterSelectors.queryParams]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", Boolean)
    ], AvailabilitySelectors, "relatedPointsLoading", null);
    __decorate([
        Selector([AvailabilitySelectors.generalInfo, RouterSelectors.queryParams]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "relatedPoints", null);
    __decorate([
        Selector([AvailabilitySelectors.relatedPoints, CommonEntitiesState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array, Object]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "relatedPointsInfo", null);
    __decorate([
        Selector([AvailabilitySelectors.generalInfo, RouterSelectors.queryParams]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", Object)
    ], AvailabilitySelectors, "impactChartData", null);
    __decorate([
        Selector([AvailabilitySelectors.generalInfo, RouterSelectors.queryParams]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", Boolean)
    ], AvailabilitySelectors, "impactChartDataLoading", null);
    __decorate([
        Selector([AvailabilitySelectors.generalInfo, RouterSelectors.queryParams]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", Object)
    ], AvailabilitySelectors, "eventDetails", null);
    __decorate([
        Selector([AvailabilitySelectors.generalInfo, RouterSelectors.queryParams]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", Boolean)
    ], AvailabilitySelectors, "eventDetailsLoading", null);
    __decorate([
        Selector([AvailabilitySelectors.eventDetails]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], AvailabilitySelectors, "eventDetailsUI", null);
    __decorate([
        Selector([AvailabilitySelectors.generalInfo, RouterSelectors.queryParams]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", Boolean)
    ], AvailabilitySelectors, "otherEventsLoading", null);
    __decorate([
        Selector([AvailabilitySelectors.generalInfo, RouterSelectors.queryParams, CommonEntitiesState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object, Object]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "otherEventsUI", null);
    __decorate([
        Selector([AvailabilitySelectors.eventDetails, CommonEntitiesState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", Object)
    ], AvailabilitySelectors, "pointInfo", null);
    __decorate([
        Selector([AvailabilitySelectors.selectedRoutePoints, AvailabilitySelectors.isMergedGermanyMAState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array, Boolean]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "maintenanceRoutePoints", null);
    __decorate([
        Selector([AvailabilitySelectors.getSelectedPointEvents]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "eventsTab", null);
    __decorate([
        Selector([AvailabilityState, CommonEntitiesState, AvailabilitySelectors.getSelectedPointEvents]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object, Array]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "mappedSelectedPointEvents", null);
    __decorate([
        Selector([AvailabilitySelectors.getAllEventPoints]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "allEventPoints", null);
    __decorate([
        Selector([AvailabilitySelectors.allEventPoints, CommonEntitiesState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array, Object]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "mappedAllPointEvents", null);
    __decorate([
        Selector([AvailabilitySelectors.getSelectedRouteEvents, CommonEntitiesState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array, Object]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "mappedSelectedRouteEvents", null);
    __decorate([
        Selector([AvailabilitySelectors.mappedSelectedPointEvents, AvailabilitySelectors.activeMATabType]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array, String]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "selectedPointEvents", null);
    __decorate([
        Selector([AvailabilitySelectors.selectedPointEvents]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", Object)
    ], AvailabilitySelectors, "selectedPointEventsFilter", null);
    __decorate([
        Selector([AvailabilitySelectors.selectedPointEvents, AvailabilitySelectors.eventsWidgetFilter]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array, Object]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "filteredSelectedPointEvents", null);
    __decorate([
        Selector([AvailabilityState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], AvailabilitySelectors, "eventsWidgetFilter", null);
    __decorate([
        Selector([AvailabilitySelectors.mappedSelectedPointEvents]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", Array)
    ], AvailabilitySelectors, "selectedOneMADirectionEvents", null);
    __decorate([
        Selector([AvailabilitySelectors.gasDayRange]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], AvailabilitySelectors, "isMergedGermanyMAState", null);
    return AvailabilitySelectors;
}());
export { AvailabilitySelectors };
