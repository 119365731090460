import { ModalConfig } from 'appy-gas-core';

import { LoginComponent } from './login.component';

const loginModalConfig: ModalConfig = {
  title: 'Sign In',
  component: {
    name: LoginComponent,
    options: {
      isPage: false,
      redirectUrl: ''
    }
  },
  data: {
    width: 670
  }
};

export default loginModalConfig;
