export var RouterPagesConfig = /*@__PURE__*/ (function (RouterPagesConfig) {
    RouterPagesConfig["PROFILE"] = "Profile";
    RouterPagesConfig["DASHBOARD"] = "Dashboard";
    RouterPagesConfig["FLOWS_MAP"] = "Flows Map";
    RouterPagesConfig["DATA_EXPORT"] = "Data Export";
    RouterPagesConfig["ROUTE_CALCULATOR"] = "Route Calculator";
    RouterPagesConfig["MARKET_AREA_VIEW"] = "Market Area View";
    RouterPagesConfig["AVAILABILITY"] = "Availability";
    RouterPagesConfig["AVAILABILITY_PRO"] = "Availability Pro";
    return RouterPagesConfig;
})({});
