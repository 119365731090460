/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "./auth.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./auth.component";
var styles_AuthComponent = [i0.styles];
var RenderType_AuthComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_AuthComponent, data: {} });
export { RenderType_AuthComponent as RenderType_AuthComponent };
export function View_AuthComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "auth-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "auth-footer__row auth-footer__copyright"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["\u00A9 appygas ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "auth-footer__row auth-footer__contacts"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", "auth-footer__contacts-email"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentYear; _ck(_v, 4, 0, currVal_0); var currVal_1 = ("mailto:" + _co.links.email); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.links.email; _ck(_v, 7, 0, currVal_2); }); }
export function View_AuthComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "appy-gas-auth", [], null, null, null, View_AuthComponent_0, RenderType_AuthComponent)), i1.ɵdid(1, 49152, null, 0, i3.AuthComponent, [], null, null)], null, null); }
var AuthComponentNgFactory = /*@__PURE__*/ i1.ɵccf("appy-gas-auth", i3.AuthComponent, View_AuthComponent_Host_0, {}, {}, []);
export { AuthComponentNgFactory as AuthComponentNgFactory };
