var GetApiUsersList = /** @class */ /*@__PURE__*/ (function () {
    function GetApiUsersList() {
    }
    GetApiUsersList.type = '[Profile] Get Api Users List';
    return GetApiUsersList;
}());
export { GetApiUsersList };
var GetApiUsersListSuccess = /** @class */ /*@__PURE__*/ (function () {
    function GetApiUsersListSuccess(apiUsersList) {
        this.apiUsersList = apiUsersList;
    }
    GetApiUsersListSuccess.type = '[Profile] Get Api Users List Success';
    return GetApiUsersListSuccess;
}());
export { GetApiUsersListSuccess };
var GetApiUsersListFail = /** @class */ /*@__PURE__*/ (function () {
    function GetApiUsersListFail(error) {
        this.error = error;
    }
    GetApiUsersListFail.type = '[Profile] Get Api Users List Fail';
    return GetApiUsersListFail;
}());
export { GetApiUsersListFail };
var GetPortfolioInformation = /** @class */ /*@__PURE__*/ (function () {
    function GetPortfolioInformation() {
    }
    GetPortfolioInformation.type = '[Profile] Get Portfolio Information';
    return GetPortfolioInformation;
}());
export { GetPortfolioInformation };
var GetPortfolioInformationSuccess = /** @class */ /*@__PURE__*/ (function () {
    function GetPortfolioInformationSuccess(portfolioInformation) {
        this.portfolioInformation = portfolioInformation;
    }
    GetPortfolioInformationSuccess.type = '[Profile] Get Portfolio Information Success';
    return GetPortfolioInformationSuccess;
}());
export { GetPortfolioInformationSuccess };
var GetPortfolioInformationFail = /** @class */ /*@__PURE__*/ (function () {
    function GetPortfolioInformationFail(error) {
        this.error = error;
    }
    GetPortfolioInformationFail.type = '[Profile] Get Portfolio Information Fail';
    return GetPortfolioInformationFail;
}());
export { GetPortfolioInformationFail };
var GetUserPortfolioPoints = /** @class */ /*@__PURE__*/ (function () {
    function GetUserPortfolioPoints() {
    }
    GetUserPortfolioPoints.type = '[Profile] Get User Portfolio Points';
    return GetUserPortfolioPoints;
}());
export { GetUserPortfolioPoints };
var GetUserPortfolioPointsSuccess = /** @class */ /*@__PURE__*/ (function () {
    function GetUserPortfolioPointsSuccess(savedPointsIds) {
        this.savedPointsIds = savedPointsIds;
    }
    GetUserPortfolioPointsSuccess.type = '[Profile] Get User Portfolio Points Success';
    return GetUserPortfolioPointsSuccess;
}());
export { GetUserPortfolioPointsSuccess };
var GetUserPortfolioPointsFail = /** @class */ /*@__PURE__*/ (function () {
    function GetUserPortfolioPointsFail(error) {
        this.error = error;
    }
    GetUserPortfolioPointsFail.type = '[Profile] Get User Portfolio Points Fail';
    return GetUserPortfolioPointsFail;
}());
export { GetUserPortfolioPointsFail };
var SaveUserPortfolioPoints = /** @class */ /*@__PURE__*/ (function () {
    function SaveUserPortfolioPoints(savedPointsIds) {
        this.savedPointsIds = savedPointsIds;
    }
    SaveUserPortfolioPoints.type = '[Profile] Save User Portfolio Points';
    return SaveUserPortfolioPoints;
}());
export { SaveUserPortfolioPoints };
var GetUserProfile = /** @class */ /*@__PURE__*/ (function () {
    function GetUserProfile(user) {
        this.user = user;
    }
    GetUserProfile.type = '[Profile] Get User profile';
    return GetUserProfile;
}());
export { GetUserProfile };
var UpdateUserProfileNewsLetter = /** @class */ /*@__PURE__*/ (function () {
    function UpdateUserProfileNewsLetter(newsLetter) {
        this.newsLetter = newsLetter;
    }
    UpdateUserProfileNewsLetter.type = '[Profile] Update User Profile News Letter';
    return UpdateUserProfileNewsLetter;
}());
export { UpdateUserProfileNewsLetter };
var SaveApiUser = /** @class */ /*@__PURE__*/ (function () {
    function SaveApiUser() {
    }
    SaveApiUser.type = '[Profile] Save Api User';
    return SaveApiUser;
}());
export { SaveApiUser };
var SaveApiUserSuccess = /** @class */ /*@__PURE__*/ (function () {
    function SaveApiUserSuccess(apiUser) {
        this.apiUser = apiUser;
    }
    SaveApiUserSuccess.type = '[Profile] Save Api User Success';
    return SaveApiUserSuccess;
}());
export { SaveApiUserSuccess };
var SaveApiUserFail = /** @class */ /*@__PURE__*/ (function () {
    function SaveApiUserFail(error) {
        this.error = error;
    }
    SaveApiUserFail.type = '[Profile] Save Api User Fail';
    return SaveApiUserFail;
}());
export { SaveApiUserFail };
var GetWalletInformation = /** @class */ /*@__PURE__*/ (function () {
    function GetWalletInformation() {
    }
    GetWalletInformation.type = '[Profile] Get Wallet Information';
    return GetWalletInformation;
}());
export { GetWalletInformation };
var GetWalletInformationSuccess = /** @class */ /*@__PURE__*/ (function () {
    function GetWalletInformationSuccess(appiesCount, appiesTransactionHistory) {
        this.appiesCount = appiesCount;
        this.appiesTransactionHistory = appiesTransactionHistory;
    }
    GetWalletInformationSuccess.type = '[Profile] Get Wallet Information Success';
    return GetWalletInformationSuccess;
}());
export { GetWalletInformationSuccess };
var GetWalletInformationFail = /** @class */ /*@__PURE__*/ (function () {
    function GetWalletInformationFail(error) {
        this.error = error;
    }
    GetWalletInformationFail.type = '[Profile] Get Wallet Information Fail';
    return GetWalletInformationFail;
}());
export { GetWalletInformationFail };
var SetWalletBalanceStatus = /** @class */ /*@__PURE__*/ (function () {
    function SetWalletBalanceStatus(balanceStatus) {
        this.balanceStatus = balanceStatus;
    }
    SetWalletBalanceStatus.type = '[User state] Set Wallet Balance Status';
    return SetWalletBalanceStatus;
}());
export { SetWalletBalanceStatus };
var GetUserBadges = /** @class */ /*@__PURE__*/ (function () {
    function GetUserBadges() {
    }
    GetUserBadges.type = '[User state] Get User Badges';
    return GetUserBadges;
}());
export { GetUserBadges };
var GetUserBadgesSuccess = /** @class */ /*@__PURE__*/ (function () {
    function GetUserBadgesSuccess(userBadges) {
        this.userBadges = userBadges;
    }
    GetUserBadgesSuccess.type = '[User state] Get User Badges Success';
    return GetUserBadgesSuccess;
}());
export { GetUserBadgesSuccess };
var GetUserBadgesFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetUserBadgesFailed(error) {
        this.error = error;
    }
    GetUserBadgesFailed.type = '[User state] Get User Badges Failed';
    return GetUserBadgesFailed;
}());
export { GetUserBadgesFailed };
var GetAllBadges = /** @class */ /*@__PURE__*/ (function () {
    function GetAllBadges() {
    }
    GetAllBadges.type = '[User state] Get All Badges';
    return GetAllBadges;
}());
export { GetAllBadges };
var GetAllBadgesSuccess = /** @class */ /*@__PURE__*/ (function () {
    function GetAllBadgesSuccess(allBadges) {
        this.allBadges = allBadges;
    }
    GetAllBadgesSuccess.type = '[User state] Get All Badges Success';
    return GetAllBadgesSuccess;
}());
export { GetAllBadgesSuccess };
var GetAllBadgesFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetAllBadgesFailed(error) {
        this.error = error;
    }
    GetAllBadgesFailed.type = '[User state] Get All Badges Failed';
    return GetAllBadgesFailed;
}());
export { GetAllBadgesFailed };
