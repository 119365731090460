import { ExclusiveAccessExpiredComponent } from './exclusive-access-expired.component';

export const exclusiveAccessExpiredConfig = {
  title: '',
  component: {
    name: ExclusiveAccessExpiredComponent,
    options: {}
  },
  data: {
    width: 650
  }
};
