export function pushAlertInit(): void {
  try {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.pushalert.co/integrate_96d587683e420f6dcf3e74c3a6472345.js';

    document.getElementsByTagName('head')[0].appendChild(script);
  } catch (error) {
    console.error(`Push alerts Error: ${error}`);
  }
}
