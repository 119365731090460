import { RestrictedMarketAreaPremiumComponent } from './restricted-market-area-premium.component';

export const restrictedMarketAreaPremiumConfig = {
  title: '',
  component: {
    name: RestrictedMarketAreaPremiumComponent,
    options: {}
  },
  data: {
    width: 625
  }
};
