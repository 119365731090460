import { __assign, __spreadArrays } from "tslib";
import { addDays, isAfter, isBefore, isSameMonth } from "date-fns";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import uniqWith from "lodash/uniqWith";
import { MarketAreaMap, MarketAreaNameConfig } from "appy-gas-core";
import { OrderClassesEnum, WidgetColsInRowEnum, WidgetRowsEnum } from "./enums";
var DashboardHelperService = /** @class */ /*@__PURE__*/ (function () {
    function DashboardHelperService() {
    }
    DashboardHelperService.moveRow = function (currentIndex, moveUp, block) {
        var movedBlock = __spreadArrays(block);
        var oldIndex = currentIndex;
        var newIndex = moveUp ? oldIndex - 1 : oldIndex + 1;
        var movedElement = movedBlock[oldIndex];
        movedBlock[oldIndex] = movedBlock[newIndex];
        movedBlock[newIndex] = movedElement;
        return movedBlock;
    };
    DashboardHelperService.getColumnClass = function (blockPosition, block) {
        var index = block.findIndex(function (position) { return position === blockPosition; });
        return DashboardHelperService.getWidgetColClass(index);
    };
    DashboardHelperService.getWidgetRowClass = function (index) {
        switch (index) {
            case WidgetRowsEnum.FIRST:
                return OrderClassesEnum.FIRST;
            case WidgetRowsEnum.SECOND:
                return OrderClassesEnum.THIRD;
            case WidgetRowsEnum.THIRD:
                return OrderClassesEnum.FIFTH;
            case WidgetRowsEnum.FOURTH:
                return OrderClassesEnum.SEVENTH;
            case WidgetRowsEnum.FIFTH:
                return OrderClassesEnum.NINTH;
            default:
                return OrderClassesEnum.FIRST;
        }
    };
    DashboardHelperService.getWidgetColClass = function (index) {
        switch (index) {
            case WidgetColsInRowEnum.FIRST:
                return OrderClassesEnum.FIRST;
            case WidgetColsInRowEnum.SECOND:
                return OrderClassesEnum.SECOND;
            case WidgetColsInRowEnum.THIRD:
                return OrderClassesEnum.THIRD;
            default:
                return OrderClassesEnum.FIRST;
        }
    };
    DashboardHelperService.getMaintenanceFilters = function (maintenancePlan) {
        var ip = uniqWith(maintenancePlan.map(function (item) {
            return {
                name: item.pointName + " (" + item.operatorName + ")",
                pointName: "" + item.pointName,
                value: item.pointId,
                tsoId: item.operatorId,
            };
        }), isEqual);
        var tso = uniqWith(maintenancePlan.map(function (item) {
            return {
                name: item.operatorName,
                value: item.operatorId,
            };
        }), isEqual);
        return { ip: sortBy(ip, ["pointName"]), tso: sortBy(tso, ["name"]) };
    };
    DashboardHelperService.filterHubPriceLegends = function (traceName, date) {
        var gaspoolAndNcgMergeDate = addDays(new Date("2021-09-30"), 1);
        var trsAndPegNordExpirationDate = addDays(new Date("2018-10-31"), 1);
        switch (traceName) {
            case MarketAreaNameConfig[MarketAreaMap.THE_H]:
                return isAfter(date, gaspoolAndNcgMergeDate);
            case MarketAreaNameConfig[MarketAreaMap.NCG_H]:
            case MarketAreaNameConfig[MarketAreaMap.GASPOOL_H]:
                return isSameMonth(gaspoolAndNcgMergeDate, date) || isBefore(date, gaspoolAndNcgMergeDate);
            case MarketAreaNameConfig[MarketAreaMap.TRS]:
                return isSameMonth(trsAndPegNordExpirationDate, date) || isBefore(date, trsAndPegNordExpirationDate);
            case MarketAreaNameConfig[MarketAreaMap.PEG_NORD]:
                return isSameMonth(trsAndPegNordExpirationDate, date) || isBefore(date, trsAndPegNordExpirationDate);
            case MarketAreaNameConfig[MarketAreaMap.PEG]:
                return isAfter(date, trsAndPegNordExpirationDate);
            default:
                return true;
        }
    };
    DashboardHelperService.filterHubPriceDataByMergeGermanyDate = function (hubPricesData) {
        var hubPriceMergedDate = addDays(new Date("2021-09-30"), 1);
        return hubPricesData.map(function (hubPrices) {
            switch (hubPrices.name) {
                case MarketAreaNameConfig[MarketAreaMap.THE_H]:
                    return __assign(__assign({}, hubPrices), { values: hubPrices.values.filter(function (value) { return isAfter(value.date, hubPriceMergedDate); }) });
                case MarketAreaNameConfig[MarketAreaMap.NCG_H]:
                case MarketAreaNameConfig[MarketAreaMap.GASPOOL_H]:
                    return __assign(__assign({}, hubPrices), { values: hubPrices.values.filter(function (value) { return isBefore(value.date, hubPriceMergedDate); }) });
                default:
                    return hubPrices;
            }
        });
    };
    return DashboardHelperService;
}());
export { DashboardHelperService };
