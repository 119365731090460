var NorwayIconComponent = /** @class */ /*@__PURE__*/ (function () {
    function NorwayIconComponent() {
        this.defaultIconColor = '#F9C00C';
    }
    Object.defineProperty(NorwayIconComponent.prototype, "quantityLength", {
        get: function () {
            return this.quantity.toString().length;
        },
        enumerable: false,
        configurable: true
    });
    return NorwayIconComponent;
}());
export { NorwayIconComponent };
