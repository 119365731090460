export var UpdateUserRoleResponseTypes = /*@__PURE__*/ (function (UpdateUserRoleResponseTypes) {
    UpdateUserRoleResponseTypes["NOT_ENOUGH_MONEY"] = "100";
    UpdateUserRoleResponseTypes["BALANCE_NOT_FOUND"] = "101";
    UpdateUserRoleResponseTypes["CONTRACT_DOESNT_ACTIVE"] = "102";
    UpdateUserRoleResponseTypes["CANNOT_FIND_CONTRACT"] = "103";
    UpdateUserRoleResponseTypes["CANNOT_FIND_CONTRACT_FOR_THIS_USER"] = "104";
    UpdateUserRoleResponseTypes["USER_ALREADY_HAS_EXCLUSIVE_ROLE"] = "105";
    UpdateUserRoleResponseTypes["ONLY_FOR_CLASSIC_USERS"] = "106";
    UpdateUserRoleResponseTypes["ONLY_FOR_TRIAL_CLASSIC_USER"] = "200";
    UpdateUserRoleResponseTypes["TRIAL_CLASSIC_RANGE_EXPIRED"] = "201";
    UpdateUserRoleResponseTypes["MAXIMUM_AMOUNT_OF_SEARCHES_PER_DAY"] = "301";
    return UpdateUserRoleResponseTypes;
})({});
