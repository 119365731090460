import * as Rollbar from 'rollbar';
import { rollbarConfig } from './rollbar-config';

import { EnvironmentVariables } from 'appy-gas-core';

export function rollbarFactory(envVariables: EnvironmentVariables): Rollbar {
  rollbarConfig.payload.environment = envVariables.environment;

  return new Rollbar(rollbarConfig);
}
