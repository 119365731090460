import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ErrorTypeListEnum, Logger, ModalService } from 'appy-gas-core';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';
var log = new Logger('HttpWebService');
var TOAST_MESSAGES = {
    NEW_LOGIN: 'New login was identified, this session is no longer valid'
};
var ErrorInterceptor = /** @class */ /*@__PURE__*/ (function () {
    function ErrorInterceptor(authService, modalService, toastService, router) {
        this.authService = authService;
        this.modalService = modalService;
        this.toastService = toastService;
        this.router = router;
    }
    ErrorInterceptor.defaultErrorHandler = function (error) {
        log.error('Request error', error);
        return throwError(error);
    };
    ErrorInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        return next.handle(req).pipe(tap(function (event) {
            // Intercept if user uses few devices
            if (event instanceof HttpResponse && event.status === ErrorTypeListEnum.NO_CONTENT) {
                log.warn(TOAST_MESSAGES.NEW_LOGIN, event.statusText);
                _this.handle204DoubleUserSession();
            }
        }), catchError(function (error) {
            switch (error.status) {
                case ErrorTypeListEnum.BAD_REQUEST:
                    return _this.handleInvalidGrantError(error);
                case ErrorTypeListEnum.FORBIDDEN:
                    return ErrorInterceptor.defaultErrorHandler(error);
                default:
                    return ErrorInterceptor.defaultErrorHandler(error);
            }
        }));
    };
    ErrorInterceptor.prototype.handle204DoubleUserSession = function () {
        // Users DataLayer for GA
        if (this.authService && this.authService.user && this.authService.user.email) {
            // window['dataLayer'] = window['dataLayer'] || [];
            window['dataLayer'].push({ email: this.authService.user.email });
        }
        this.authService.logout();
        this.router.navigate(['/auth/login'], { queryParams: { doubleUserSession: true } });
        // 'User with the same credentials logged in on another machine!'
        this.toastService.warning(TOAST_MESSAGES.NEW_LOGIN, '', {
            timeOut: 20000,
            messageClass: 'not-valid-session'
        });
        // throw new Error('User with the same credentials logged in on another machine! Double users session');
    };
    ErrorInterceptor.prototype.handleInvalidGrantError = function (error) {
        if ((error === null || error === void 0 ? void 0 : error.status) === 400 && error.error && error.error.error === 'invalid_grant') {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            this.logout();
            return throwError(error.error);
        }
        log.error('Request error', error);
        return throwError(error);
    };
    ErrorInterceptor.prototype.logout = function () {
        this.router.navigate(['/auth/login']);
    };
    return ErrorInterceptor;
}());
export { ErrorInterceptor };
