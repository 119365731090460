import { Router } from '@angular/router';
import { ModalService } from 'appy-gas-core';
var UserDeactivatedComponent = /** @class */ /*@__PURE__*/ (function () {
    function UserDeactivatedComponent(modalService, router) {
        this.modalService = modalService;
        this.router = router;
    }
    UserDeactivatedComponent.prototype.closeModal = function () {
        this.modalService.hide();
    };
    UserDeactivatedComponent.prototype.contact = function () {
        this.closeModal();
        this.router.navigate(['/static/contact-us']);
    };
    UserDeactivatedComponent.prototype.goToHome = function () {
        this.closeModal();
        this.router.navigate(['/dashboard']);
    };
    return UserDeactivatedComponent;
}());
export { UserDeactivatedComponent };
