/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "../user-transition-modals.scss.shim.ngstyle";
import * as i1 from "./switch-to-exclusive-days.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../../../../../../node_modules/appy-gas-core/dist/components/button/button.component.ngfactory";
import * as i4 from "appy-gas-core/dist/components/button/button.component";
import * as i5 from "./switch-to-exclusive-days.component";
import * as i6 from "appy-gas-core/dist/components/modal/modal.service";
var styles_SwitchToExclusiveDaysComponent = [i0.styles, i1.styles];
var RenderType_SwitchToExclusiveDaysComponent = /*@__PURE__*/ i2.ɵcrt({ encapsulation: 0, styles: styles_SwitchToExclusiveDaysComponent, data: {} });
export { RenderType_SwitchToExclusiveDaysComponent as RenderType_SwitchToExclusiveDaysComponent };
export function View_SwitchToExclusiveDaysComponent_0(_l) {
    return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 18, "div", [["class", "transition-modal"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "div", [["class", "transition-modal__header"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Try Exclusive access now"])), (_l()(), i2.ɵeld(3, 0, null, null, 15, "div", [["class", "transition-modal__main-block"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 12, "div", [["class", "transition-modal__left-sidebar"]], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 1, "div", [["class", "transition-modal__appie-count"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" As part of your free trial, you get 10 free appies to explore Exclusive access. Switch now to experience the full functionality of my.appygas. "])), (_l()(), i2.ɵeld(7, 0, null, null, 3, "div", [["class", "transition-modal__benefits"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" Check out the full benefits "])), (_l()(), i2.ɵeld(9, 0, null, null, 1, "a", [["class", "transition-modal__link"], ["href", "https://45463c0e-fd7a-4b3f-a85d-87510fe54978.filesusr.com/ugd/49c0ad_0acbabe2df734ccbbccf4660ce82d696.pdf"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" here "])), (_l()(), i2.ɵeld(11, 0, null, null, 2, "button", [["appyGasButton", ""], ["buttonType", "secondary-blue"], ["class", "transition-modal__btn--cancel"]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.hideModal() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i2.ɵdid(12, 638976, null, 0, i4.ButtonComponent, [i2.ElementRef, i2.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i2.ɵted(-1, 0, [" CANCEL "])), (_l()(), i2.ɵeld(14, 0, null, null, 2, "button", [["appyGasButton", ""], ["buttonType", "blue"], ["class", "transition-modal__btn--submit"]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            var _co = _v.component;
            if (("click" === en)) {
                var pd_0 = (_co.switchToExclusive() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i2.ɵdid(15, 638976, null, 0, i4.ButtonComponent, [i2.ElementRef, i2.Renderer2], { buttonType: [0, "buttonType"] }, null), (_l()(), i2.ɵted(-1, 0, [" SWITCH TO EXCLUSIVE "])), (_l()(), i2.ɵeld(17, 0, null, null, 1, "div", [["class", "transition-modal__right-sidebar"]], null, null, null, null, null)), (_l()(), i2.ɵeld(18, 0, null, null, 0, "img", [["class", "transition-modal__main-img"], ["src", "/assets/images/portfolio/appies-blue.png"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "secondary-blue"; _ck(_v, 12, 0, currVal_0); var currVal_1 = "blue"; _ck(_v, 15, 0, currVal_1); }, null);
}
export function View_SwitchToExclusiveDaysComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "appy-gas-switch-to-exclusive-days", [], null, null, null, View_SwitchToExclusiveDaysComponent_0, RenderType_SwitchToExclusiveDaysComponent)), i2.ɵdid(1, 49152, null, 0, i5.SwitchToExclusiveDaysComponent, [i6.ModalService], null, null)], null, null); }
var SwitchToExclusiveDaysComponentNgFactory = /*@__PURE__*/ i2.ɵccf("appy-gas-switch-to-exclusive-days", i5.SwitchToExclusiveDaysComponent, View_SwitchToExclusiveDaysComponent_Host_0, {}, {}, []);
export { SwitchToExclusiveDaysComponentNgFactory as SwitchToExclusiveDaysComponentNgFactory };
