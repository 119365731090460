import { __assign, __decorate, __metadata } from "tslib";
import { Selector } from '@ngxs/store';
import isAfter from 'date-fns/isAfter';
import { dateToGasDayRange } from 'appy-gas-core';
import { MarketAreaViewHelperService } from '../../shared/services/market-area-view-helper.service';
import { CommonEntitiesSelectors } from '../common-entities';
import { MarketAreaViewState } from './market-area-view.state';
var MarketAreaViewSelectors = /** @class */ /*@__PURE__*/ (function () {
    function MarketAreaViewSelectors() {
    }
    MarketAreaViewSelectors.leftMarketAreaData = function (state, eePoints) {
        return __assign(__assign({}, state.leftMarketAreaView), { eePoints: eePoints });
    };
    MarketAreaViewSelectors.rightMarketAreaData = function (state, eePoints) {
        return __assign(__assign({}, state.rightMarketAreaView), { eePoints: eePoints });
    };
    MarketAreaViewSelectors.isMergedGermanyLeftMAState = function (leftMarketAreaData) {
        return isAfter(leftMarketAreaData.date, dateToGasDayRange(new Date('2021-09-30')).timeFrom);
    };
    MarketAreaViewSelectors.isMergedGermanyRightMAState = function (rightMarketAreaData) {
        return isAfter(rightMarketAreaData.date, dateToGasDayRange(new Date('2021-09-30')).timeFrom);
    };
    MarketAreaViewSelectors.leftMarketAreaDataFilteredVIPPoints = function (leftMarketAreaData, isMergedGermanyLeftMAState) {
        leftMarketAreaData.marketArea.virtualPoints = MarketAreaViewHelperService.filterVirtualPoints(isMergedGermanyLeftMAState, leftMarketAreaData.marketArea.virtualPoints);
        return leftMarketAreaData;
    };
    MarketAreaViewSelectors.rightMarketAreaDataFilteredVIPPoints = function (rightMarketAreaData, isMergedGermanyRightMAState) {
        rightMarketAreaData.marketArea.virtualPoints = MarketAreaViewHelperService.filterVirtualPoints(isMergedGermanyRightMAState, rightMarketAreaData.marketArea.virtualPoints);
        return rightMarketAreaData;
    };
    __decorate([
        Selector([MarketAreaViewState, CommonEntitiesSelectors.eePoints]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Array]),
        __metadata("design:returntype", Object)
    ], MarketAreaViewSelectors, "leftMarketAreaData", null);
    __decorate([
        Selector([MarketAreaViewState, CommonEntitiesSelectors.eePoints]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Array]),
        __metadata("design:returntype", Object)
    ], MarketAreaViewSelectors, "rightMarketAreaData", null);
    __decorate([
        Selector([MarketAreaViewSelectors.leftMarketAreaData]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], MarketAreaViewSelectors, "isMergedGermanyLeftMAState", null);
    __decorate([
        Selector([MarketAreaViewSelectors.rightMarketAreaData]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], MarketAreaViewSelectors, "isMergedGermanyRightMAState", null);
    __decorate([
        Selector([MarketAreaViewSelectors.leftMarketAreaData, MarketAreaViewSelectors.isMergedGermanyLeftMAState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Boolean]),
        __metadata("design:returntype", Object)
    ], MarketAreaViewSelectors, "leftMarketAreaDataFilteredVIPPoints", null);
    __decorate([
        Selector([MarketAreaViewSelectors.rightMarketAreaData, MarketAreaViewSelectors.isMergedGermanyRightMAState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Boolean]),
        __metadata("design:returntype", Object)
    ], MarketAreaViewSelectors, "rightMarketAreaDataFilteredVIPPoints", null);
    return MarketAreaViewSelectors;
}());
export { MarketAreaViewSelectors };
