import { CookiesPopoverService } from '../cookies-popover.service';
var APPYGAS_COOKIES_BROWSER_KEY = 'APPYGAS_COOKIES_BROWSER';
var APPYGAS_COOKIES_BROWSER_VALUE = 1;
var APPYGAS_COOKIES_BROWSER_LIFE = 30 * 24 * 60 * 60 * 1000; // month
var BrowserPopoverComponent = /** @class */ /*@__PURE__*/ (function () {
    function BrowserPopoverComponent(cookiesPopoverService) {
        this.cookiesPopoverService = cookiesPopoverService;
        this.visible = false;
    }
    BrowserPopoverComponent.prototype.ngOnInit = function () {
        var isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1 && navigator.vendor.toLowerCase().indexOf('google') > -1;
        if (!isChrome) {
            this.visible = !this.cookiesPopoverService.getCookies(APPYGAS_COOKIES_BROWSER_KEY);
        }
    };
    BrowserPopoverComponent.prototype.closeWording = function () {
        this.cookiesPopoverService.setCookies(APPYGAS_COOKIES_BROWSER_KEY, APPYGAS_COOKIES_BROWSER_VALUE, APPYGAS_COOKIES_BROWSER_LIFE);
        this.visible = !this.cookiesPopoverService.getCookies(APPYGAS_COOKIES_BROWSER_KEY);
    };
    return BrowserPopoverComponent;
}());
export { BrowserPopoverComponent };
