import statisticIcon from 'appy-gas-core/dist/assets/svg/icons/statistic.svg';
var StatisticButtonComponent = /** @class */ /*@__PURE__*/ (function () {
    function StatisticButtonComponent() {
        this.icons = {
            statistic: statisticIcon
        };
    }
    return StatisticButtonComponent;
}());
export { StatisticButtonComponent };
