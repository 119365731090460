var GetVirtualPoints = /** @class */ /*@__PURE__*/ (function () {
    function GetVirtualPoints() {
    }
    GetVirtualPoints.type = '[Flows Map Module] Get Virtual Points';
    return GetVirtualPoints;
}());
export { GetVirtualPoints };
var GetVirtualPointsSuccess = /** @class */ /*@__PURE__*/ (function () {
    function GetVirtualPointsSuccess(virtualPoints) {
        this.virtualPoints = virtualPoints;
    }
    GetVirtualPointsSuccess.type = '[Flows Map Module] Get Virtual Points Success';
    return GetVirtualPointsSuccess;
}());
export { GetVirtualPointsSuccess };
var GetVirtualPointsFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetVirtualPointsFailed() {
    }
    GetVirtualPointsFailed.type = '[Flows Map Module] Get Virtual Points Failed';
    return GetVirtualPointsFailed;
}());
export { GetVirtualPointsFailed };
var GetPhysicalPoints = /** @class */ /*@__PURE__*/ (function () {
    function GetPhysicalPoints() {
    }
    GetPhysicalPoints.type = '[Flows Map Module] Get Physical Points';
    return GetPhysicalPoints;
}());
export { GetPhysicalPoints };
var GetPhysicalPointsSuccess = /** @class */ /*@__PURE__*/ (function () {
    function GetPhysicalPointsSuccess(physicalPoints) {
        this.physicalPoints = physicalPoints;
    }
    GetPhysicalPointsSuccess.type = '[Flows Map Module] Get Physical Points Success';
    return GetPhysicalPointsSuccess;
}());
export { GetPhysicalPointsSuccess };
var GetPhysicalPointsFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetPhysicalPointsFailed() {
    }
    GetPhysicalPointsFailed.type = '[Flows Map Module] Get Physical Points Failed';
    return GetPhysicalPointsFailed;
}());
export { GetPhysicalPointsFailed };
var SetSelectedDate = /** @class */ /*@__PURE__*/ (function () {
    function SetSelectedDate(selectedDate) {
        this.selectedDate = selectedDate;
    }
    SetSelectedDate.type = '[Flows Map Module] Set Selected Date';
    return SetSelectedDate;
}());
export { SetSelectedDate };
var SetActiveFilterTab = /** @class */ /*@__PURE__*/ (function () {
    function SetActiveFilterTab(activeFilterTab) {
        this.activeFilterTab = activeFilterTab;
    }
    SetActiveFilterTab.type = '[Flows Map Module] Set Active Filter Tab';
    return SetActiveFilterTab;
}());
export { SetActiveFilterTab };
var SetSelectedChartPoints = /** @class */ /*@__PURE__*/ (function () {
    function SetSelectedChartPoints(selectedChartPoints) {
        this.selectedChartPoints = selectedChartPoints;
    }
    SetSelectedChartPoints.type = '[Flows Map Module] Set Selected Chart Points';
    return SetSelectedChartPoints;
}());
export { SetSelectedChartPoints };
var SetSelectedTypesFilter = /** @class */ /*@__PURE__*/ (function () {
    function SetSelectedTypesFilter(selectedTypesFilter) {
        this.selectedTypesFilter = selectedTypesFilter;
    }
    SetSelectedTypesFilter.type = '[Flows Map Module] Set Selected Types Filter';
    return SetSelectedTypesFilter;
}());
export { SetSelectedTypesFilter };
var UpdateFlowsMapWidgetsVisibilityState = /** @class */ /*@__PURE__*/ (function () {
    function UpdateFlowsMapWidgetsVisibilityState(selectedWidget) {
        this.selectedWidget = selectedWidget;
    }
    UpdateFlowsMapWidgetsVisibilityState.type = '[Flows Map Module] Update Flows Map Widgets Visibility State';
    return UpdateFlowsMapWidgetsVisibilityState;
}());
export { UpdateFlowsMapWidgetsVisibilityState };
var ChangePointWidgetPointTypes = /** @class */ /*@__PURE__*/ (function () {
    function ChangePointWidgetPointTypes(pointsWidgetTabTypeSwitcher) {
        this.pointsWidgetTabTypeSwitcher = pointsWidgetTabTypeSwitcher;
    }
    ChangePointWidgetPointTypes.type = '[Flows Map Module] Change Point Widget Point Types ';
    return ChangePointWidgetPointTypes;
}());
export { ChangePointWidgetPointTypes };
var ChangeFlowsMapPointTypes = /** @class */ /*@__PURE__*/ (function () {
    function ChangeFlowsMapPointTypes(pointsSwitcher) {
        this.pointsSwitcher = pointsSwitcher;
    }
    ChangeFlowsMapPointTypes.type = '[Flows Map Module] Change Flows Map Point Types';
    return ChangeFlowsMapPointTypes;
}());
export { ChangeFlowsMapPointTypes };
var UpdateSelectedPointsFilter = /** @class */ /*@__PURE__*/ (function () {
    function UpdateSelectedPointsFilter(pointsWidgetFilter) {
        this.pointsWidgetFilter = pointsWidgetFilter;
    }
    UpdateSelectedPointsFilter.type = '[Flows Map Module] Update Selected Points Filter';
    return UpdateSelectedPointsFilter;
}());
export { UpdateSelectedPointsFilter };
