import { __assign, __decorate, __metadata } from "tslib";
import { Action, State } from '@ngxs/store';
import { GetExportData, GetExportDataFailed, GetExportDataSuccess } from './data-export.actions';
var ɵ0 = undefined;
var DataExportState = /** @class */ /*@__PURE__*/ (function () {
    function DataExportState() {
    }
    DataExportState.prototype.getExportData = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({ exportData: __assign(__assign({}, state.exportData), { loading: true }) });
    };
    DataExportState.prototype.getExportDataSuccess = function (_a, _b) {
        var patchState = _a.patchState;
        var exportData = _b.exportData;
        patchState({ exportData: { data: exportData, loaded: true, loading: false } });
    };
    DataExportState.prototype.getExportDataFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({ exportData: __assign(__assign({}, state.exportData), { loaded: false, loading: false }) });
    };
    __decorate([
        Action(GetExportData),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DataExportState.prototype, "getExportData", null);
    __decorate([
        Action(GetExportDataSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetExportDataSuccess]),
        __metadata("design:returntype", void 0)
    ], DataExportState.prototype, "getExportDataSuccess", null);
    __decorate([
        Action(GetExportDataFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DataExportState.prototype, "getExportDataFailed", null);
    DataExportState = __decorate([
        State({
            name: 'dataExport',
            defaults: {
                dataExportForm: undefined,
                exportData: {
                    data: ɵ0,
                    loading: false,
                    loaded: false
                }
            }
        })
    ], DataExportState);
    return DataExportState;
}());
export { DataExportState };
export { ɵ0 };
