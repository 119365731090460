import { ModalService } from 'appy-gas-core';
import { LocalStorageKeysEnum } from '../../../enums/local-storage-keys.enum';
var InfoModalComponent = /** @class */ /*@__PURE__*/ (function () {
    function InfoModalComponent(modalService) {
        this.modalService = modalService;
    }
    InfoModalComponent.prototype.hideModal = function () {
        localStorage.removeItem(LocalStorageKeysEnum.USER_ROLE_INFO_MODAL);
        this.modalService.hide();
    };
    return InfoModalComponent;
}());
export { InfoModalComponent };
