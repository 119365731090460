import { __decorate, __metadata } from "tslib";
import { Selector } from '@ngxs/store';
import { CommonEntitiesState } from './common-entities.state';
var CommonEntitiesSelectors = /** @class */ /*@__PURE__*/ (function () {
    function CommonEntitiesSelectors() {
    }
    CommonEntitiesSelectors.operators = function (state) {
        return state.operators;
    };
    CommonEntitiesSelectors.eePoints = function (state) {
        return state.eePoints;
    };
    __decorate([
        Selector([CommonEntitiesState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], CommonEntitiesSelectors, "operators", null);
    __decorate([
        Selector([CommonEntitiesState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], CommonEntitiesSelectors, "eePoints", null);
    return CommonEntitiesSelectors;
}());
export { CommonEntitiesSelectors };
