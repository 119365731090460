import { __decorate, __metadata } from "tslib";
import { RouterState } from '@ngxs/router-plugin';
import { Selector } from '@ngxs/store';
var RouterSelectors = /** @class */ /*@__PURE__*/ (function () {
    function RouterSelectors() {
    }
    RouterSelectors.params = function (_a) {
        var state = _a.state;
        return state.params;
    };
    RouterSelectors.queryParams = function (_a) {
        var state = _a.state;
        return state.queryParams;
    };
    RouterSelectors.url = function (_a) {
        var state = _a.state;
        return state.url;
    };
    __decorate([
        Selector([RouterState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], RouterSelectors, "params", null);
    __decorate([
        Selector([RouterState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], RouterSelectors, "queryParams", null);
    __decorate([
        Selector([RouterState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", String)
    ], RouterSelectors, "url", null);
    return RouterSelectors;
}());
export { RouterSelectors };
