export const APP_LINKS = Object.freeze({
  appStore: 'https://itunes.apple.com/app/appygas/id1337767310',
  playMarket: 'https://play.google.com/store/apps/details?id=eu.appygas',
  email: 'support@appygas.com',
  facebook: 'https://www.facebook.com/Appygas-146131749435791/',
  linkedIn: 'https://www.linkedin.com/company/appygas/',
  twitter: 'https://twitter.com/appygas',
  teamEmail: 'team@appygas.com',
  phoneNumber: '+434 878 878 812',
  company: '',
  street: 'Eichhornstraße 3, 10785 Berlin',
  country: 'Germany'
});
