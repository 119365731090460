import { __assign, __decorate, __metadata } from "tslib";
import { Selector } from '@ngxs/store';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import sortBy from 'lodash/sortBy';
import { dateToGasDayRange, VirtualPointType } from 'appy-gas-core';
import { FlowsMapWidgetTypesEnum } from '../../flows-map/interfaces';
import { mergedGermanyMADate } from '../../shared';
import { virtualPointIdsAfterGermanyMerge, virtualPointIdsBeforeGermanyMerge } from '../../shared/constants/app-exceptions';
import { UserPointTypesEnum } from '../../shared/enums';
import { FilterHelperService } from '../../shared/services/filter-helper.service';
import { FlowsMapHelperService } from '../../shared/services/flows-map-helper.service';
import { CommonEntitiesState } from '../common-entities';
import { FlowsMapState } from './flows-map.state';
var FlowsMapSelectors = /** @class */ /*@__PURE__*/ (function () {
    function FlowsMapSelectors() {
    }
    FlowsMapSelectors.virtualPoints = function (state) {
        return state.virtualPoints.data;
    };
    FlowsMapSelectors.allPhysicalPoints = function (state) {
        return state.physicalPoints.data;
    };
    FlowsMapSelectors.selectedDate = function (state) {
        return state.selectedDate;
    };
    FlowsMapSelectors.activeFilterTab = function (state) {
        return state.activeFilterTab;
    };
    FlowsMapSelectors.selectedChartPoints = function (state) {
        return state.selectedChartPoints;
    };
    FlowsMapSelectors.selectedTypesFilter = function (state) {
        return state.selectedTypesFilter;
    };
    FlowsMapSelectors.widgetsVisibilityState = function (state) {
        return state.widgetsVisibilityState;
    };
    FlowsMapSelectors.filteredVirtualPoints = function (virtualPoints, selectedTypesFilter, isMergedGermanyMAState) {
        var filteredPointsByMergedGermanyMAState = isMergedGermanyMAState
            ? virtualPoints.filter(function (point) { return !virtualPointIdsBeforeGermanyMerge.includes(point.appyGasId); })
            : virtualPoints.filter(function (point) { return !virtualPointIdsAfterGermanyMerge.includes(point.appyGasId); });
        return filteredPointsByMergedGermanyMAState.map(function (point) {
            point.active = selectedTypesFilter.includes(point.eePointTypeId);
            return point;
        });
    };
    FlowsMapSelectors.selectedPointType = function (state) {
        return state.pointsSwitcher;
    };
    FlowsMapSelectors.selectedPointsWidgetTabType = function (state) {
        return state.pointsWidgetTabTypeSwitcher;
    };
    FlowsMapSelectors.allPhysicalMappedPointNames = function (allPhysicalPoints, commonEntitiesState) {
        var eePoints = commonEntitiesState.eePoints;
        return allPhysicalPoints.map(function (point) {
            var _a;
            return (__assign(__assign({}, point), { pointName: (_a = eePoints.find(function (eePoint) { return point.eePointId === eePoint.eePointId; })) === null || _a === void 0 ? void 0 : _a.name }));
        });
    };
    FlowsMapSelectors.physicalPointsBySelectedPointType = function (allPhysicalPoints, selectedPointType) {
        return selectedPointType === UserPointTypesEnum.MY_PORTFOLIO
            ? allPhysicalPoints.filter(function (physicalPoint) { return physicalPoint.isInPortfolio; })
            : allPhysicalPoints;
    };
    FlowsMapSelectors.aggregatedWidgetPhysicalPoints = function (physicalPoints) {
        return {
            storagePoints: physicalPoints
                .filter(function (physicalPoint) { return physicalPoint.pointType === VirtualPointType.STO; })
                .map(function (storagePoint) { return FlowsMapHelperService.getStorageAggregatedFields(storagePoint); }),
            LNGPoints: physicalPoints
                .filter(function (physicalPoint) { return physicalPoint.pointType === VirtualPointType.LNG; })
                .map(function (LNGPoint) { return FlowsMapHelperService.getLNGAggregatedFields(LNGPoint); }),
            IPPoints: physicalPoints
                .filter(function (physicalPoint) { return physicalPoint.pointType === VirtualPointType.IP; })
                .map(function (IPPoint) { return FlowsMapHelperService.getIPAggregatedFields(IPPoint); })
        };
    };
    FlowsMapSelectors.activeWidgetPhysicalPoints = function (aggregatedPoints, selectedPointWidgetType) {
        switch (selectedPointWidgetType) {
            case VirtualPointType.STO:
                return sortBy(aggregatedPoints.storagePoints, 'pointName');
            case VirtualPointType.LNG:
                return sortBy(aggregatedPoints.LNGPoints, 'pointName');
            case VirtualPointType.IP:
                return sortBy(aggregatedPoints.IPPoints, 'pointName');
            default:
                return sortBy(aggregatedPoints.storagePoints, 'pointName');
        }
    };
    FlowsMapSelectors.selectedPointsFilter = function (activeWidgetPhysicalPoints) {
        return FilterHelperService.getMyPointsWidgetFilter(activeWidgetPhysicalPoints);
    };
    FlowsMapSelectors.filteredActivePoints = function (selectedPoints, selectedPointsAppliedFilter) {
        return FilterHelperService.getFilteredSelectedMyPointsWidget(selectedPoints, selectedPointsAppliedFilter);
    };
    FlowsMapSelectors.pointsWidgetFilter = function (state) {
        return state.pointsWidgetFilter;
    };
    FlowsMapSelectors.physicalPointsLoading = function (state) {
        return state.physicalPoints.loading;
    };
    FlowsMapSelectors.getSelectedTabPhysicalPointDataByGasDay = function (state, selectedPointsWidgetTabType, selectedDate) {
        var myPointWidgetState = state.widgetsVisibilityState[FlowsMapWidgetTypesEnum.MY_POINTS];
        var gasDayStartDate = dateToGasDayRange(new Date(selectedDate)).timeFrom;
        if (!myPointWidgetState.hidden) {
            return { selectedPointsWidgetTabType: selectedPointsWidgetTabType, gasDayStartDate: gasDayStartDate };
        }
    };
    FlowsMapSelectors.isMergedGermanyMAState = function (selectedDate) {
        return isAfter(selectedDate, mergedGermanyMADate) || isSameDay(selectedDate, mergedGermanyMADate);
    };
    __decorate([
        Selector([FlowsMapState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], FlowsMapSelectors, "virtualPoints", null);
    __decorate([
        Selector([FlowsMapState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], FlowsMapSelectors, "allPhysicalPoints", null);
    __decorate([
        Selector([FlowsMapState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Number)
    ], FlowsMapSelectors, "selectedDate", null);
    __decorate([
        Selector([FlowsMapState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Number)
    ], FlowsMapSelectors, "activeFilterTab", null);
    __decorate([
        Selector([FlowsMapState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], FlowsMapSelectors, "selectedChartPoints", null);
    __decorate([
        Selector([FlowsMapState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], FlowsMapSelectors, "selectedTypesFilter", null);
    __decorate([
        Selector([FlowsMapState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], FlowsMapSelectors, "widgetsVisibilityState", null);
    __decorate([
        Selector([
            FlowsMapSelectors.virtualPoints,
            FlowsMapSelectors.selectedTypesFilter,
            FlowsMapSelectors.isMergedGermanyMAState
        ]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array, Array, Boolean]),
        __metadata("design:returntype", Array)
    ], FlowsMapSelectors, "filteredVirtualPoints", null);
    __decorate([
        Selector([FlowsMapState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Number)
    ], FlowsMapSelectors, "selectedPointType", null);
    __decorate([
        Selector([FlowsMapState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Number)
    ], FlowsMapSelectors, "selectedPointsWidgetTabType", null);
    __decorate([
        Selector([FlowsMapSelectors.allPhysicalPoints, CommonEntitiesState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array, Object]),
        __metadata("design:returntype", Array)
    ], FlowsMapSelectors, "allPhysicalMappedPointNames", null);
    __decorate([
        Selector([FlowsMapSelectors.allPhysicalMappedPointNames, FlowsMapSelectors.selectedPointType]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array, Number]),
        __metadata("design:returntype", Array)
    ], FlowsMapSelectors, "physicalPointsBySelectedPointType", null);
    __decorate([
        Selector([FlowsMapSelectors.physicalPointsBySelectedPointType]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", Object)
    ], FlowsMapSelectors, "aggregatedWidgetPhysicalPoints", null);
    __decorate([
        Selector([FlowsMapSelectors.aggregatedWidgetPhysicalPoints, FlowsMapSelectors.selectedPointsWidgetTabType]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Number]),
        __metadata("design:returntype", Array)
    ], FlowsMapSelectors, "activeWidgetPhysicalPoints", null);
    __decorate([
        Selector([FlowsMapSelectors.activeWidgetPhysicalPoints]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", Object)
    ], FlowsMapSelectors, "selectedPointsFilter", null);
    __decorate([
        Selector([FlowsMapSelectors.activeWidgetPhysicalPoints, FlowsMapSelectors.pointsWidgetFilter]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array, Object]),
        __metadata("design:returntype", Array)
    ], FlowsMapSelectors, "filteredActivePoints", null);
    __decorate([
        Selector([FlowsMapState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], FlowsMapSelectors, "pointsWidgetFilter", null);
    __decorate([
        Selector([FlowsMapState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], FlowsMapSelectors, "physicalPointsLoading", null);
    __decorate([
        Selector([FlowsMapState, FlowsMapSelectors.selectedPointsWidgetTabType, FlowsMapSelectors.selectedDate]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Number, Number]),
        __metadata("design:returntype", Object)
    ], FlowsMapSelectors, "getSelectedTabPhysicalPointDataByGasDay", null);
    __decorate([
        Selector([FlowsMapSelectors.selectedDate]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Boolean)
    ], FlowsMapSelectors, "isMergedGermanyMAState", null);
    return FlowsMapSelectors;
}());
export { FlowsMapSelectors };
