import { __decorate, __metadata } from "tslib";
import { Selector } from "@ngxs/store";
import head from "lodash/head";
import { MaintenanceDataListType } from "appy-gas-core";
import { DashboardHelperService } from "../../dashboard/dashboard-helper.service";
import { DashboardState } from "./dashboard.state";
var DashboardSelectors = /** @class */ /*@__PURE__*/ (function () {
    function DashboardSelectors() {
    }
    DashboardSelectors.hubPrices = function (state) {
        return state.hubPrices.data;
    };
    DashboardSelectors.hubPricesIsLoading = function (state) {
        return state.hubPrices.loading;
    };
    DashboardSelectors.lngCountries = function (state) {
        return state.lngCountries.data;
    };
    DashboardSelectors.lngCountriesIsLoading = function (state) {
        return state.lngCountries.loading;
    };
    DashboardSelectors.pipelineCountries = function (state) {
        return state.pipelineCountries.data;
    };
    DashboardSelectors.pipelineCountriesIsLoading = function (state) {
        return state.pipelineCountries.loading;
    };
    DashboardSelectors.pipelineCountriesVsLng = function (state) {
        return state.pipelineCountriesVsLng.data;
    };
    DashboardSelectors.pipelineCountriesVsLngIsLoading = function (state) {
        return state.pipelineCountriesVsLng.loading;
    };
    DashboardSelectors.maintenancePlanAll = function (state) {
        return state.maintenancePlan.data;
    };
    DashboardSelectors.maintenancePlanMyPortfolio = function (state) {
        return state.maintenancePlan.data.filter(function (maintenancePlan) { return maintenancePlan.isInPortfolio; });
    };
    DashboardSelectors.maintenancePlanIsLoading = function (state) {
        return state.maintenancePlan.loading;
    };
    DashboardSelectors.getMaintenancePlanAllFilters = function (maintenancePlanAll) {
        return DashboardHelperService.getMaintenanceFilters(maintenancePlanAll);
    };
    DashboardSelectors.getMaintenanceUpdateAllFilters = function (maintenanceUpdateAll) {
        return DashboardHelperService.getMaintenanceFilters(maintenanceUpdateAll);
    };
    DashboardSelectors.getMaintenanceUpdateMyPortfolioFilters = function (maintenanceUpdateMyPortfolio) {
        return DashboardHelperService.getMaintenanceFilters(maintenanceUpdateMyPortfolio);
    };
    DashboardSelectors.getMaintenancePlanMyPortfolioFilters = function (maintenancePlanMyPortfolio) {
        return DashboardHelperService.getMaintenanceFilters(maintenancePlanMyPortfolio);
    };
    DashboardSelectors.maintenanceUpdateAll = function (state) {
        return state.maintenanceUpdate.data;
    };
    DashboardSelectors.maintenanceUpdateLoading = function (state) {
        return state.maintenanceUpdate.loading;
    };
    DashboardSelectors.maintenanceUpdateMyPortfolio = function (maintenanceUpdates) {
        return maintenanceUpdates.filter(function (maintenancePlan) {
            var _a, _b;
            var isFirstPrevDataItemInPortfolio = (_a = head(maintenancePlan[MaintenanceDataListType.PREVIOUS].data)) === null || _a === void 0 ? void 0 : _a.isInPortfolio;
            var isFirstCurrentDataItemInPortfolio = (_b = head(maintenancePlan[MaintenanceDataListType.CURRENT].data)) === null || _b === void 0 ? void 0 : _b.isInPortfolio;
            return isFirstPrevDataItemInPortfolio || isFirstCurrentDataItemInPortfolio;
        });
    };
    DashboardSelectors.lastAuctionStats = function (state) {
        return state.lastAuctionStats.data;
    };
    DashboardSelectors.lastAuctionStatsIsLoading = function (state) {
        return state.lastAuctionStats.loading;
    };
    DashboardSelectors.spreads = function (state) {
        return state.spreads.data;
    };
    DashboardSelectors.spreadsLoading = function (state) {
        return state.spreads.loading;
    };
    DashboardSelectors.allFilterSpreads = function (state) {
        return state.allFilterSpreads.data;
    };
    DashboardSelectors.allFilterSpreadsLoading = function (state) {
        return state.allFilterSpreads.loading;
    };
    DashboardSelectors.previewSpreads = function (state) {
        return state.previewSpreads.data;
    };
    DashboardSelectors.previewSpreadsLoading = function (state) {
        return state.previewSpreads.loading;
    };
    DashboardSelectors.spreadFilters = function (state) {
        return state.spreadFilters.data;
    };
    DashboardSelectors.spreadFiltersLoading = function (state) {
        return state.spreadFilters.loading;
    };
    DashboardSelectors.spreadMarketAreas = function (state) {
        return state.spreadMarketAreas.data;
    };
    DashboardSelectors.spreadMarketAreasLoading = function (state) {
        return state.spreadMarketAreas.loading;
    };
    DashboardSelectors.spreadFilterForEdit = function (state) {
        return state.spreadFilterForEdit.data;
    };
    DashboardSelectors.spreadFilterForEditLoading = function (state) {
        return state.spreadFilterForEdit.loading;
    };
    DashboardSelectors.blockPositions = function (state) {
        return state.blockPositions;
    };
    DashboardSelectors.filteredByMergeGermanyDateHubPrices = function (hubPrices) {
        hubPrices.data = DashboardHelperService.filterHubPriceDataByMergeGermanyDate(hubPrices.data);
        return hubPrices;
    };
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], DashboardSelectors, "hubPrices", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], DashboardSelectors, "hubPricesIsLoading", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], DashboardSelectors, "lngCountries", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], DashboardSelectors, "lngCountriesIsLoading", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], DashboardSelectors, "pipelineCountries", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], DashboardSelectors, "pipelineCountriesIsLoading", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], DashboardSelectors, "pipelineCountriesVsLng", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], DashboardSelectors, "pipelineCountriesVsLngIsLoading", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], DashboardSelectors, "maintenancePlanAll", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], DashboardSelectors, "maintenancePlanMyPortfolio", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], DashboardSelectors, "maintenancePlanIsLoading", null);
    __decorate([
        Selector([DashboardSelectors.maintenancePlanAll]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", Object)
    ], DashboardSelectors, "getMaintenancePlanAllFilters", null);
    __decorate([
        Selector([DashboardSelectors.maintenanceUpdateAll]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", Object)
    ], DashboardSelectors, "getMaintenanceUpdateAllFilters", null);
    __decorate([
        Selector([DashboardSelectors.maintenanceUpdateMyPortfolio]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", Object)
    ], DashboardSelectors, "getMaintenanceUpdateMyPortfolioFilters", null);
    __decorate([
        Selector([DashboardSelectors.maintenancePlanMyPortfolio]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", Object)
    ], DashboardSelectors, "getMaintenancePlanMyPortfolioFilters", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], DashboardSelectors, "maintenanceUpdateAll", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], DashboardSelectors, "maintenanceUpdateLoading", null);
    __decorate([
        Selector([DashboardSelectors.maintenanceUpdateAll]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", Array)
    ], DashboardSelectors, "maintenanceUpdateMyPortfolio", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], DashboardSelectors, "lastAuctionStats", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], DashboardSelectors, "lastAuctionStatsIsLoading", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], DashboardSelectors, "spreads", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], DashboardSelectors, "spreadsLoading", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], DashboardSelectors, "allFilterSpreads", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], DashboardSelectors, "allFilterSpreadsLoading", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], DashboardSelectors, "previewSpreads", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], DashboardSelectors, "previewSpreadsLoading", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], DashboardSelectors, "spreadFilters", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], DashboardSelectors, "spreadFiltersLoading", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], DashboardSelectors, "spreadMarketAreas", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], DashboardSelectors, "spreadMarketAreasLoading", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], DashboardSelectors, "spreadFilterForEdit", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], DashboardSelectors, "spreadFilterForEditLoading", null);
    __decorate([
        Selector([DashboardState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], DashboardSelectors, "blockPositions", null);
    __decorate([
        Selector([DashboardSelectors.hubPrices]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], DashboardSelectors, "filteredByMergeGermanyDateHubPrices", null);
    return DashboardSelectors;
}());
export { DashboardSelectors };
