import { InfoModalComponent } from './info-modal.component';

export const infoModalConfig = {
  title: 'Info Modal',
  component: {
    name: InfoModalComponent,
    options: {}
  },
  data: {
    width: 670,
    closable: false
  }
};
