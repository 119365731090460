import { MarketAreaMap, Role } from 'appy-gas-core';
import availabilityIcon from 'appy-gas-core/dist/assets/svg/icons/header/availability.svg';
import calculatorIcon from 'appy-gas-core/dist/assets/svg/icons/header/calculator.svg';
import homeIcon from 'appy-gas-core/dist/assets/svg/icons/header/dashboard.svg';
import downloadIcon from 'appy-gas-core/dist/assets/svg/icons/header/download.svg';
import flowsIcon from 'appy-gas-core/dist/assets/svg/icons/header/flows.svg';

import { UpdateUserRoleResponseTypes } from '../enums/update-user-role-response-types.enum';

export const headerNavigationLinks = [
  {
    routerLink: '/dashboard',
    icon: homeIcon,
    label: 'Dashboard'
  },
  {
    routerLink: '/flows-map',
    icon: flowsIcon,
    label: 'Flows Map'
  },
  {
    routerLink: '/route-calculator',
    icon: calculatorIcon,
    label: 'Route Calculator'
  },
  {
    routerLink: '/availability-pro',
    icon: availabilityIcon,
    label: 'Availability'
  },
  {
    routerLink: '/data-export',
    icon: downloadIcon,
    label: 'Download'
  }
];

export const allowedUserRolesForSearchDropDown = [
  Role.Free,
  Role.Trial,
  Role.Premium,
  Role.Appygas,
  Role.PremiumPlus,
  Role.Classic,
  Role.Exclusive,
  Role.PreClassic,
  Role.TrialClassic,
  Role.TrialExclusive,
  Role.PromoExclusive,
  Role.PromoClassic,
  Role.PromoTrialExclusive
];

export const maxSelectedVirtualPoints = 7;

export const norwayDataSourceId = 8;

export const norwayName = 'Norway';

export const norwayRelatedPoints = [
  'AG-VIP-0016',
  'AG-VIP-0018',
  'AG-VIP-0031',
  'AG-VIP-0021',
  'AG-VIP-0044',
  'AG-VIP-0057'
];

export const virtualStorageIds = [
  'AG-VS-0003',
  'AG-VS-0004',
  'AG-VS-0005',
  'AG-VS-0006',
  'AG-VS-0007',
  'AG-VS-0008',
  'AG-VS-0009',
  'AG-VS-0010',
  'AG-VS-0011',
  'AG-VS-0012',
  'AG-VS-0013',
  'AG-VS-0014',
  'AG-VS-0015',
  'AG-VS-0016',
  'AG-VS-0018'
];

export const defaultDashboardBlockPositions = {
  firstRow: [0, 1],
  mainBlocks: [0, 1, 2, 3],
  secondRow: [0, 1],
  thirdRow: [0, 1, 2]
};

export const duplicatedPortfolioPointMAExceptions = {
  41285: ' to Gaspool H',
  41148: ' to NCGH',
  41295: ' to Gaspool H',
  41139: ' to NCG H',
  41055: ' to TTF'
};

export const superPointIdsGRTOperator = [
  50024, 50027, 50030, 50033, 50036, 50039, 50042, 50045, 50048, 51002, 51005, 51008, 51011, 51023, 51026, 51029, 51032,
  51035, 51038, 51041, 51044, 51047, 51050, 51053, 51059, 51062
];

export const superPointIdsTeregaOperator = [51065];

// TODO remove this exception when super points with two operators removed (put them either to GRTGaz or Terega super Point Ids)
export const superPointIdsGRTTeregaOperator = [51056, 51068, 51071, 51074, 51077];

export const allPEGSuperPointIds = [
  ...superPointIdsGRTOperator,
  ...superPointIdsGRTTeregaOperator,
  ...superPointIdsTeregaOperator
];

export const operatorsIdsIncludedSuperPoints = [1, 25, 38];

export const superPointIdsNationalGridOperator = [51014, 51017];

export const superPointIdsBayernetsOperator = [51020];

export const allSuperPointIds = [
  ...superPointIdsGRTOperator,
  ...superPointIdsNationalGridOperator,
  ...superPointIdsBayernetsOperator
];

export const nordStreamOperatorId = 97;

export const TAPOperatorId = 133;

const russiaPointIds = [41251, 41387, 41432, 41435, 41565, 41572];
const albaniaPointIds = [41418];
const NCG_HPointIds = [
  41037, 41038, 41046, 41073, 41103, 41110, 41111, 41112, 41123, 41127, 41128, 41132, 41133, 41137, 41138, 41141, 41144,
  41147, 41150, 41157, 41158, 41164, 41165, 41168, 41174, 41180, 41186, 41189, 41192, 41195, 41201, 41204, 41207, 41210,
  41270, 41276, 41350, 41351, 41373, 41374, 41375, 41376, 41377, 41399, 21006, 21007, 21008, 21043, 21044, 21046, 21047,
  21067, 21068, 21088, 21089, 21091, 21092, 21103, 21104, 21139, 21140, 21151, 21152, 21157, 21158, 21167, 21168, 21169,
  21178, 21179, 21180, 21188, 21190, 21191, 21205, 21206, 21207, 21208, 21210, 21211, 21216, 21217, 21220, 21221, 21222,
  21225, 21232, 21234, 21236, 21237, 21238, 21240, 21241, 21377, 21379, 21380, 21381, 21382, 21384, 21385, 21414
];
const NCG_LPointIds = [
  41042, 41043, 41067, 41070, 41097, 41171, 41183, 41345, 41402, 21182, 21183, 21185, 21186, 21213, 21214
];
const gaspoolHPointIds = [
  41076, 41079, 41091, 41094, 41106, 41124, 41142, 41145, 41169, 41175, 41181, 41187, 41190, 41193, 41208, 41246, 41247,
  41248, 41249, 41250, 41256, 41267, 41271, 41273, 41277, 41279, 41282, 41287, 41289, 41291, 41294, 41298, 41299, 41329,
  41330, 41331, 41332, 41333, 41342, 41383, 41384, 41385, 41386, 41389, 41392, 41393, 41394, 41406, 21243, 21244, 21246,
  21247, 21250, 21251, 21252, 21254, 21255, 21257, 21258, 21260, 21261, 21263, 21264, 21266, 21267, 21270, 21272, 21273,
  21275, 21276, 21280, 21281, 21283, 21284, 21286, 21287, 21301, 21303, 21304, 21316, 21318, 21319, 21321, 21322, 21325,
  21326, 21327, 21330, 21331, 21332, 21335, 21336, 21337, 21369, 21370, 21372, 21373, 21374, 21375, 21418, 21432, 21433
];
const gaspoolLPointIds = [41082, 41088, 41172, 41184, 41196, 41346, 41409, 21307, 21308, 21309, 21311, 21312];

// tslint:disable-next-line:max-line-length
// TODO: remove 'NCG_HPointIds, NCG_LPointIds, gaspoolHPointIds, gaspoolLPointIds' from pointIdsNotIncludedInPortfolio before merge Germany release;
export const pointIdsNotIncludedInPortfolio = [
  ...albaniaPointIds,
  ...russiaPointIds,
  ...NCG_HPointIds,
  ...NCG_LPointIds,
  ...gaspoolHPointIds,
  ...gaspoolLPointIds
];

export const pointIdsExcludedImpactFirmOnEvents = [...superPointIdsNationalGridOperator, ...allPEGSuperPointIds];

export const noWalletForContractUsersKeys = [
  UpdateUserRoleResponseTypes.BALANCE_NOT_FOUND,
  UpdateUserRoleResponseTypes.CONTRACT_DOESNT_ACTIVE,
  UpdateUserRoleResponseTypes.CANNOT_FIND_CONTRACT,
  UpdateUserRoleResponseTypes.CANNOT_FIND_CONTRACT_FOR_THIS_USER
];

export const net4GasOperatorName = 'Net4Gas';
export const activatedUkraineMarketAreaDate = '2021-06-01';
// TODO: set activated Poland date according to https://jd.eleks.com/browse/ESGS-6075 (check 5 point) story
export const activatedPolandMarketAreaDate = '2012-12-19';

export const mergedGermanyMADate = new Date('2021-10-01');

export const mergedGermanyMAs = [MarketAreaMap.THE_H, MarketAreaMap.THE_L];

export const notMergedGermanyMAs = [
  MarketAreaMap.GASPOOL_H,
  MarketAreaMap.GASPOOL_L,
  MarketAreaMap.NCG_H,
  MarketAreaMap.NCG_L
];
