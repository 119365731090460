import { Router } from '@angular/router';
import { ModalService } from 'appy-gas-core';
var TrialExpiredComponent = /** @class */ /*@__PURE__*/ (function () {
    function TrialExpiredComponent(modalService, router) {
        this.modalService = modalService;
        this.router = router;
    }
    TrialExpiredComponent.prototype.closeModal = function () {
        this.modalService.hide();
    };
    TrialExpiredComponent.prototype.upgradePremium = function () {
        this.closeModal();
        this.router.navigate(['/static/contact-us/upgrade-exclusive']);
    };
    TrialExpiredComponent.prototype.continueAsFree = function () {
        this.closeModal();
    };
    TrialExpiredComponent.prototype.deactivate = function () {
        this.router.navigate(['/profile']);
    };
    return TrialExpiredComponent;
}());
export { TrialExpiredComponent };
