import { EventEmitter } from '@angular/core';
import calendarIcon from 'appy-gas-core/dist/assets/svg/icons/calendar.svg';
var DateRangeSliderComponent = /** @class */ /*@__PURE__*/ (function () {
    function DateRangeSliderComponent() {
        this.step = 86400000;
        this.valueChangeEmitter = new EventEmitter();
        this.calendarIcon = calendarIcon;
    }
    DateRangeSliderComponent.prototype.ngOnInit = function () {
        this.setSliderConfig();
    };
    DateRangeSliderComponent.prototype.handleRangeChange = function () {
        var _this = this;
        setTimeout(function () {
            var _a = _this, dateFrom = _a.dateFrom, dateTo = _a.dateTo;
            _this.valueChangeEmitter.emit({ dateFrom: dateFrom, dateTo: dateTo });
        }, 0);
    };
    DateRangeSliderComponent.prototype.changeRange = function (value) {
        this.dateFrom = this.dateFrom + value;
        this.dateTo = this.dateTo + value;
    };
    DateRangeSliderComponent.prototype.setSliderConfig = function () {
        this.dateFrom = this.minValue;
        this.dateTo = this.maxValue;
        this.options = {
            floor: this.rangeFloor,
            ceil: this.rangeCeil,
            step: this.step,
            draggableRangeOnly: true,
            animate: false,
            showTicks: true
        };
    };
    return DateRangeSliderComponent;
}());
export { DateRangeSliderComponent };
