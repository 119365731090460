import { ActivatedRoute, Router } from "@angular/router";
var SignupSuccessComponent = /** @class */ /*@__PURE__*/ (function () {
    function SignupSuccessComponent(router, route) {
        this.router = router;
        this.route = route;
        this.isPage = true;
        this.title = "Check your email!";
        this.description = "We\u2019ve sent a message to your email.\n  Open it and follow the instruction.<br><br>If you have a Promo Code do not forget to use it in the Profile section.";
    }
    SignupSuccessComponent.prototype.ngOnInit = function () {
        console.log("loading signup-success");
        var urlObject = new URL(document.location.href);
        var params = urlObject.searchParams;
        var email = params.get("email");
        console.log(email);
        if (!email) {
            // router.navigate(["/auth/login"]);
        }
        this.description = "We\u2019ve sent a message to <b>" + email + "</b>.\nOpen it and follow the instruction.<br><br>If you have a Promo Code do not forget to use it in the Profile section.";
    };
    return SignupSuccessComponent;
}());
export { SignupSuccessComponent };
