/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "../signup.component.scss.shim.ngstyle";
import * as i1 from "./signup-success.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "./signup-success.component";
var styles_SignupSuccessComponent = [i0.styles, i1.styles];
var RenderType_SignupSuccessComponent = /*@__PURE__*/ i2.ɵcrt({ encapsulation: 0, styles: styles_SignupSuccessComponent, data: {} });
export { RenderType_SignupSuccessComponent as RenderType_SignupSuccessComponent };
export function View_SignupSuccessComponent_0(_l) {
    return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 10, "div", [["class", "auth-box signup-box"]], null, null, null, null, null)), i2.ɵdid(1, 278528, null, 0, i3.NgClass, [i2.IterableDiffers, i2.KeyValueDiffers, i2.ElementRef, i2.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i2.ɵpod(2, { "is-page": 0 }), (_l()(), i2.ɵeld(3, 0, null, null, 2, "div", [["class", "auth-box__logo-wrapper"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 1, "img", [["alt", "AppyGas"], ["class", "auth-box__logo"], ["routerLink", "/"], ["src", "/assets/images/logo-white.svg"]], null, [[null, "click"]], function (_v, en, $event) {
            var ad = true;
            if (("click" === en)) {
                var pd_0 = (i2.ɵnov(_v, 5).onClick() !== false);
                ad = (pd_0 && ad);
            }
            return ad;
        }, null, null)), i2.ɵdid(5, 540672, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i2.Renderer2, i2.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i2.ɵeld(6, 0, null, null, 4, "div", [["class", "auth-box__form"]], null, null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 3, "div", [["class", "auth-box__form-header"]], null, null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 1, "h2", [["class", "auth-box__form-header-title"]], null, null, null, null, null)), (_l()(), i2.ɵted(9, null, ["", ""])), (_l()(), i2.ɵeld(10, 0, null, null, 0, "p", [["class", "auth-box__form-header-subtitle"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "auth-box signup-box"; var currVal_1 = _ck(_v, 2, 0, _co.isPage); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "/"; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.title; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.description; _ck(_v, 10, 0, currVal_4); });
}
export function View_SignupSuccessComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "appy-gas-signup-success", [], [[2, "is-page", null]], null, null, View_SignupSuccessComponent_0, RenderType_SignupSuccessComponent)), i2.ɵdid(1, 114688, null, 0, i5.SignupSuccessComponent, [i4.Router, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i2.ɵnov(_v, 1).isPage; _ck(_v, 0, 0, currVal_0); }); }
var SignupSuccessComponentNgFactory = /*@__PURE__*/ i2.ɵccf("appy-gas-signup-success", i5.SignupSuccessComponent, View_SignupSuccessComponent_Host_0, {}, {}, []);
export { SignupSuccessComponentNgFactory as SignupSuccessComponentNgFactory };
