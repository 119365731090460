import { __decorate, __metadata } from "tslib";
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { VirtualPointType } from 'appy-gas-core';
import { GetPhysicalPoints, GetPhysicalPointsSuccess, GetVirtualPoints, GetVirtualPointsFailed, GetVirtualPointsSuccess } from './flows-map.actions';
import { FlowsMapSelectors } from './flows-map.selectors';
var FlowsMapFacade = /** @class */ /*@__PURE__*/ (function () {
    function FlowsMapFacade(virtualPointService, marketAreasMapService, store) {
        this.virtualPointService = virtualPointService;
        this.marketAreasMapService = marketAreasMapService;
        this.store = store;
    }
    FlowsMapFacade.prototype.getVirtualPoints = function (dateRange, refreshCache) {
        var _this = this;
        this.store.dispatch(new GetVirtualPoints());
        return this.virtualPointService.loadVirtualPoints(dateRange, refreshCache).pipe(catchError(function (err) {
            _this.store.dispatch(new GetVirtualPointsFailed());
            return throwError(err);
        }), map(function (data) { return new GetVirtualPointsSuccess(data); }));
    };
    FlowsMapFacade.prototype.getPhysicalPoints = function (virtualPoint, timeFrom) {
        var _this = this;
        this.store.dispatch(new GetPhysicalPoints());
        var virtualPointKey = VirtualPointType[virtualPoint].toLowerCase();
        return this.marketAreasMapService.loadPhysicalPoints(virtualPointKey, timeFrom).pipe(catchError(function (err) {
            _this.store.dispatch(new GetVirtualPointsFailed());
            return throwError(err);
        }), map(function (data) { return new GetPhysicalPointsSuccess(data); }));
    };
    __decorate([
        Select(FlowsMapSelectors.selectedPointsWidgetTabType),
        __metadata("design:type", Observable)
    ], FlowsMapFacade.prototype, "selectedPointTabType$", void 0);
    __decorate([
        Select(FlowsMapSelectors.selectedPointsFilter),
        __metadata("design:type", Observable)
    ], FlowsMapFacade.prototype, "filterActivePoints$", void 0);
    __decorate([
        Select(FlowsMapSelectors.filteredActivePoints),
        __metadata("design:type", Observable)
    ], FlowsMapFacade.prototype, "filteredActivePoints$", void 0);
    __decorate([
        Select(FlowsMapSelectors.getSelectedTabPhysicalPointDataByGasDay),
        __metadata("design:type", Observable)
    ], FlowsMapFacade.prototype, "getSelectedTabPhysicalPointDataByGasDay$", void 0);
    __decorate([
        Select(FlowsMapSelectors.physicalPointsLoading),
        __metadata("design:type", Observable)
    ], FlowsMapFacade.prototype, "physicalPointsLoading$", void 0);
    __decorate([
        Select(FlowsMapSelectors.isMergedGermanyMAState),
        __metadata("design:type", Observable)
    ], FlowsMapFacade.prototype, "isMergedGermanyMAState$", void 0);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Boolean]),
        __metadata("design:returntype", Observable)
    ], FlowsMapFacade.prototype, "getVirtualPoints", null);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, Number]),
        __metadata("design:returntype", Observable)
    ], FlowsMapFacade.prototype, "getPhysicalPoints", null);
    return FlowsMapFacade;
}());
export { FlowsMapFacade };
