import each from 'lodash/each';
import { finalize } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { passwordConfirmationValidator, passwordValidator } from 'appy-gas-core';
import { AuthenticationService } from '../../core/http-auth/authentication.service';
var ResetPasswordComponent = /** @class */ /*@__PURE__*/ (function () {
    function ResetPasswordComponent(route, router, formBuilder, authenticationService) {
        this.route = route;
        this.router = router;
        this.formBuilder = formBuilder;
        this.authenticationService = authenticationService;
        this.error = null;
        this.isLoading = false;
        this.isPage = true;
        this.createForm();
    }
    ResetPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sub = this.route.queryParams.subscribe(function (params) {
            var reset_code = params.reset_code, email = params.email;
            _this.email = email;
            _this.resetPasswordForm.controls.email.setValue(email);
            _this.resetPasswordForm.controls.code.setValue(reset_code);
        });
    };
    ResetPasswordComponent.prototype.ngOnDestroy = function () {
        this.sub.unsubscribe();
    };
    ResetPasswordComponent.prototype.resetPassword = function () {
        var _this = this;
        if (!this.resetPasswordForm.valid) {
            each(this.resetPasswordForm.controls, function (control) { return control.markAsTouched(); });
            return;
        }
        this.isLoading = true;
        this.authenticationService
            .resetPassword(this.resetPasswordForm.value)
            .pipe(finalize(function () {
            _this.resetPasswordForm.markAsPristine();
            _this.isLoading = false;
        }))
            .subscribe(function () {
            _this.router.navigate(['/auth/login', { passwordSuccessfullyChanged: true }], { replaceUrl: true });
        }, function (error) { return (_this.error = error); });
    };
    ResetPasswordComponent.prototype.createForm = function () {
        this.resetPasswordForm = this.formBuilder.group({
            email: [''],
            code: [''],
            password: ['', [Validators.required, passwordValidator]],
            confirmPassword: ['', [Validators.required, passwordConfirmationValidator]]
        });
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
