import { __assign, __decorate, __metadata, __spreadArrays } from "tslib";
import { Selector } from '@ngxs/store';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { MarketAreaNameConfig, User } from 'appy-gas-core';
import { MarketAreaFlagConfig } from '../../shared/constants/market-area-flag.config';
import { ProfileHelperService } from '../../shared/services/profile-helper.service';
import { ProfileState } from './profile.state';
var ProfileSelectors = /** @class */ /*@__PURE__*/ (function () {
    function ProfileSelectors() {
    }
    ProfileSelectors.apiUsersList = function (_a) {
        var apiUsersList = _a.apiUsersList;
        return apiUsersList.data;
    };
    ProfileSelectors.portfolioInformation = function (_a) {
        var portfolioInformation = _a.portfolioInformation;
        return portfolioInformation.data;
    };
    ProfileSelectors.userProfile = function (_a) {
        var userProfile = _a.userProfile;
        return userProfile;
    };
    ProfileSelectors.userProfileNewsLetter = function (_a) {
        var userProfile = _a.userProfile;
        return userProfile.newsLetter;
    };
    ProfileSelectors.savedPointsIds = function (_a) {
        var savedPointsIds = _a.savedPointsIds;
        return savedPointsIds.data;
    };
    ProfileSelectors.appiesCount = function (_a) {
        var appiesCount = _a.appiesCount;
        return appiesCount.data;
    };
    ProfileSelectors.walletBalanceStatus = function (_a) {
        var balanceStatus = _a.balanceStatus;
        return balanceStatus;
    };
    ProfileSelectors.appiesTransactionHistory = function (_a) {
        var appiesTransactionHistory = _a.appiesTransactionHistory;
        return appiesTransactionHistory.data;
    };
    ProfileSelectors.userBadges = function (_a) {
        var userBadges = _a.userBadges;
        return userBadges.data;
    };
    ProfileSelectors.fullPortfolioInformation = function (savedPointsIds, portfolioInformation) {
        var filteredUselessProfileInfo = ProfileHelperService.filterUselessProfileInfo(portfolioInformation);
        return { portfolioInformation: filteredUselessProfileInfo, savedPointsIds: savedPointsIds };
    };
    ProfileSelectors.getSelectedPointsAllInformation = function (fullPortfolioInformation) {
        var portfolioInformation = fullPortfolioInformation.portfolioInformation, savedPointsIds = fullPortfolioInformation.savedPointsIds;
        return __spreadArrays(portfolioInformation.points, portfolioInformation.superPoints).filter(function (point) { return savedPointsIds.includes(point.id); })
            .map(function (point) {
            return (__assign(__assign({}, point), { operatorName: portfolioInformation.operators.find(function (_a) {
                    var operator = _a.operator;
                    return operator.id === point.operatorId;
                }).operator
                    .name }));
        });
    };
    ProfileSelectors.getSelectedPointsByMarketArea = function (allInfoSelectedPoints) {
        var groupedPoints = groupBy(allInfoSelectedPoints, 'marketAreaId');
        return map(groupedPoints, function (points, marketAreaId) {
            return {
                marketArea: MarketAreaNameConfig[marketAreaId],
                marketAreaIcon: MarketAreaFlagConfig[marketAreaId],
                points: points
            };
        });
    };
    ProfileSelectors.getSelectedPointsByOperator = function (allInfoSelectedPoints) {
        var groupedPoints = groupBy(allInfoSelectedPoints, 'operatorName');
        return map(groupedPoints, function (points, operator) {
            return {
                operator: operator,
                points: points
            };
        });
    };
    __decorate([
        Selector([ProfileState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], ProfileSelectors, "apiUsersList", null);
    __decorate([
        Selector([ProfileState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Object)
    ], ProfileSelectors, "portfolioInformation", null);
    __decorate([
        Selector([ProfileState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", User)
    ], ProfileSelectors, "userProfile", null);
    __decorate([
        Selector([ProfileState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Boolean)
    ], ProfileSelectors, "userProfileNewsLetter", null);
    __decorate([
        Selector([ProfileState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], ProfileSelectors, "savedPointsIds", null);
    __decorate([
        Selector([ProfileState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Number)
    ], ProfileSelectors, "appiesCount", null);
    __decorate([
        Selector([ProfileState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", String)
    ], ProfileSelectors, "walletBalanceStatus", null);
    __decorate([
        Selector([ProfileState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], ProfileSelectors, "appiesTransactionHistory", null);
    __decorate([
        Selector([ProfileState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], ProfileSelectors, "userBadges", null);
    __decorate([
        Selector([ProfileSelectors.savedPointsIds, ProfileSelectors.portfolioInformation]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array, Object]),
        __metadata("design:returntype", Object)
    ], ProfileSelectors, "fullPortfolioInformation", null);
    __decorate([
        Selector([ProfileSelectors.fullPortfolioInformation]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Array)
    ], ProfileSelectors, "getSelectedPointsAllInformation", null);
    __decorate([
        Selector([ProfileSelectors.getSelectedPointsAllInformation]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", Array)
    ], ProfileSelectors, "getSelectedPointsByMarketArea", null);
    __decorate([
        Selector([ProfileSelectors.getSelectedPointsAllInformation]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", Array)
    ], ProfileSelectors, "getSelectedPointsByOperator", null);
    return ProfileSelectors;
}());
export { ProfileSelectors };
