import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { EnvironmentVariables, ErrorTypeListEnum, GrantType, HeaderMetaDataOptions, InterceptorConfig, Logger, ModalService, SpinnerService, TokenType } from 'appy-gas-core';
import { APPYGAS_TOKENS } from '../auth-config';
import { AuthenticationService } from '../authentication.service';
var log = new Logger('HttpWebService');
var AuthInterceptor = /** @class */ /*@__PURE__*/ (function () {
    function AuthInterceptor(envVariables, authService, spinnerService, modalService, router) {
        this.envVariables = envVariables;
        this.authService = authService;
        this.spinnerService = spinnerService;
        this.modalService = modalService;
        this.router = router;
        this.refreshTokenInProgress = false;
        this.refreshTokenSubject = new BehaviorSubject(null);
        this.config = new InterceptorConfig({ serverUrl: envVariables.serverUrl });
    }
    AuthInterceptor.errorHandler = function (response) {
        log.error('Request error', response);
        return throwError(response);
    };
    AuthInterceptor.getToken = function (type) {
        return localStorage.getItem(APPYGAS_TOKENS[type]); // TODO: create a localStorage service;
    };
    AuthInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        var headerMetaData = JSON.parse(req.headers.get('X-META-DATA'));
        this.token = AuthInterceptor.getToken(TokenType.ACCESS);
        if (headerMetaData === null || headerMetaData === void 0 ? void 0 : headerMetaData.auth) {
            req = this.addAuthenticationToken(req);
        }
        else {
            req.headers.delete(this.config.headerName);
        }
        if (!req.headers.get('Content-Type')) {
            req = req.clone({
                setHeaders: { 'Content-Type': 'application/json' }
            });
        }
        return next.handle(req).pipe(catchError(function (error) {
            if ((error === null || error === void 0 ? void 0 : error.status) === ErrorTypeListEnum.UNAUTHORIZED) {
                var refreshToken = AuthInterceptor.getToken(TokenType.REFRESH);
                if (refreshToken) {
                    _this.spinnerService.show();
                    if (_this.refreshTokenInProgress) {
                        return _this.refreshTokenSubject.pipe(filter(function (token) { return token != null; }), take(1), switchMap(function () { return next.handle(_this.addAuthenticationToken(req)); }));
                    }
                    else {
                        _this.refreshTokenInProgress = true;
                        _this.refreshTokenSubject.next(null);
                        return _this.refreshAccessToken().pipe(switchMap(function (accessToken) {
                            if (accessToken) {
                                _this.token = accessToken;
                                _this.refreshTokenSubject.next(accessToken);
                                return next.handle(_this.addAuthenticationToken(req));
                            }
                            _this.refreshTokenInProgress = false;
                            _this.logout();
                        }), finalize(function () {
                            _this.refreshTokenInProgress = false;
                            _this.spinnerService.hide();
                        }), catchError(function (errorRefreshToken) {
                            if (errorRefreshToken.status === ErrorTypeListEnum.FORBIDDEN) {
                                return;
                            }
                            _this.logout();
                            return AuthInterceptor.errorHandler(errorRefreshToken);
                        }));
                    }
                }
                else {
                    _this.logout();
                }
            }
            else {
                return throwError(error);
            }
        }));
    };
    AuthInterceptor.prototype.addAuthenticationToken = function (request) {
        if (!this.token) {
            return request;
        }
        return request.clone({
            headers: request.headers.set(this.config.headerName, this.config.headerPrefix + " " + this.token)
        });
    };
    AuthInterceptor.prototype.logout = function () {
        this.router.navigate(['/auth/login']);
        this.authService.logout();
    };
    AuthInterceptor.prototype.refreshAccessToken = function () {
        var refreshToken = AuthInterceptor.getToken(TokenType.REFRESH);
        return this.authService.refreshToken({
            refresh_token: refreshToken,
            grant_type: GrantType.REFRESH_TOKEN
        });
    };
    return AuthInterceptor;
}());
export { AuthInterceptor };
