var SetUserInfo = /** @class */ /*@__PURE__*/ (function () {
    function SetUserInfo(user) {
        this.user = user;
    }
    SetUserInfo.type = '[User state] Set User Information';
    return SetUserInfo;
}());
export { SetUserInfo };
var SetUserRole = /** @class */ /*@__PURE__*/ (function () {
    function SetUserRole(role) {
        this.role = role;
    }
    SetUserRole.type = '[User state] Set User Role';
    return SetUserRole;
}());
export { SetUserRole };
