import { __decorate, __metadata } from "tslib";
import { createSelector, Selector } from '@ngxs/store';
import { Role, User } from 'appy-gas-core';
import { UserState } from './user.state';
var UserSelectors = /** @class */ /*@__PURE__*/ (function () {
    function UserSelectors() {
    }
    UserSelectors.getUser = function (state) {
        return state.user;
    };
    UserSelectors.getUserRole = function (state) {
        return state.role;
    };
    UserSelectors.isExclusiveUser = function (role) {
        return [Role.Exclusive, Role.TrialExclusive, Role.PromoExclusive, Role.PromoTrialExclusive, Role.Appygas].includes(role);
    };
    UserSelectors.isPremiumPlusUser = function (role) {
        return role === Role.PremiumPlus;
    };
    UserSelectors.isNonTrialExclusiveUser = function (role) {
        return [Role.Exclusive, Role.PromoExclusive, Role.Appygas].includes(role);
    };
    UserSelectors.isExclusivePremiumUser = function (role) {
        return [
            Role.Premium,
            Role.PremiumPlus,
            Role.Exclusive,
            Role.TrialExclusive,
            Role.PromoExclusive,
            Role.PromoTrialExclusive,
            Role.Appygas
        ].includes(role);
    };
    UserSelectors.isLimitedUser = function (role) {
        return [Role.Guest, Role.Free, Role.Expired, Role.Trial].includes(role);
    };
    UserSelectors.isContractUser = function (role) {
        return [Role.PreClassic, Role.Classic, Role.Exclusive, Role.PromoExclusive, Role.Appygas].includes(role);
    };
    UserSelectors.isClassicUser = function (role) {
        return [Role.PreClassic, Role.Classic, Role.TrialClassic].includes(role);
    };
    UserSelectors.isTrialExclusiveUsed = function (user) {
        return user.isTrialExclusiveUsed;
    };
    UserSelectors.isSwitchableUser = function (role, isTrialExclusiveUsed) {
        return role === Role.Classic || (role === Role.TrialClassic && !isTrialExclusiveUsed);
    };
    UserSelectors.isUserRoleIncluded = function (roles) {
        return createSelector([UserSelectors.getUserRole], function (userRole) {
            return roles.includes(userRole);
        });
    };
    __decorate([
        Selector([UserState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", User)
    ], UserSelectors, "getUser", null);
    __decorate([
        Selector([UserState]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Number)
    ], UserSelectors, "getUserRole", null);
    __decorate([
        Selector([UserSelectors.getUserRole]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Boolean)
    ], UserSelectors, "isExclusiveUser", null);
    __decorate([
        Selector([UserSelectors.getUserRole]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Boolean)
    ], UserSelectors, "isPremiumPlusUser", null);
    __decorate([
        Selector([UserSelectors.getUserRole]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Boolean)
    ], UserSelectors, "isNonTrialExclusiveUser", null);
    __decorate([
        Selector([UserSelectors.getUserRole]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Boolean)
    ], UserSelectors, "isExclusivePremiumUser", null);
    __decorate([
        Selector([UserSelectors.getUserRole]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Boolean)
    ], UserSelectors, "isLimitedUser", null);
    __decorate([
        Selector([UserSelectors.getUserRole]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Boolean)
    ], UserSelectors, "isContractUser", null);
    __decorate([
        Selector([UserSelectors.getUserRole]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Boolean)
    ], UserSelectors, "isClassicUser", null);
    __decorate([
        Selector([UserSelectors.getUser]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [User]),
        __metadata("design:returntype", Boolean)
    ], UserSelectors, "isTrialExclusiveUsed", null);
    __decorate([
        Selector([UserSelectors.getUserRole, UserSelectors.isTrialExclusiveUsed]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, Boolean]),
        __metadata("design:returntype", Boolean)
    ], UserSelectors, "isSwitchableUser", null);
    return UserSelectors;
}());
export { UserSelectors };
