import { __decorate, __metadata } from "tslib";
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { User } from 'appy-gas-core';
import { UserSelectors } from './user.selectors';
import { SetUserInfo } from './user.actions';
import * as i0 from "@angular/core";
var UserFacadeService = /** @class */ /*@__PURE__*/ (function () {
    function UserFacadeService() {
    }
    UserFacadeService.prototype.setUserInfo = function (user) {
        return new SetUserInfo(user);
    };
    UserFacadeService.ɵprov = i0.ɵɵdefineInjectable({ factory: function UserFacadeService_Factory() { return new UserFacadeService(); }, token: UserFacadeService, providedIn: "root" });
    __decorate([
        Select([UserSelectors.getUser]),
        __metadata("design:type", Observable)
    ], UserFacadeService.prototype, "user$", void 0);
    __decorate([
        Select([UserSelectors.getUserRole]),
        __metadata("design:type", Observable)
    ], UserFacadeService.prototype, "role$", void 0);
    __decorate([
        Select([UserSelectors.isExclusiveUser]),
        __metadata("design:type", Observable)
    ], UserFacadeService.prototype, "isExclusiveUser$", void 0);
    __decorate([
        Select([UserSelectors.isExclusivePremiumUser]),
        __metadata("design:type", Observable)
    ], UserFacadeService.prototype, "isExclusivePremiumUser$", void 0);
    __decorate([
        Select(UserSelectors.isClassicUser),
        __metadata("design:type", Observable)
    ], UserFacadeService.prototype, "isClassicUser$", void 0);
    __decorate([
        Dispatch(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [User]),
        __metadata("design:returntype", SetUserInfo)
    ], UserFacadeService.prototype, "setUserInfo", null);
    return UserFacadeService;
}());
export { UserFacadeService };
